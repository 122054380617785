import { makeAutoObservable } from 'mobx';
import { HashtagViewModel } from '../core/backend/models';

class HashtagStore {
  hashtags: HashtagViewModel[] | undefined = [];

  constructor() {
    makeAutoObservable(this);
  }
  saveToStore = (hashtags: HashtagViewModel[] | undefined) => {
    this.hashtags = hashtags;
  };
}

export default HashtagStore;
