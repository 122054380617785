import classNames from 'classnames';
import { useState } from 'react';
import { followUser, unfollowUser } from '../../core/api';
import { UserViewModel } from '../../core/backend/models';
import appToast from '../../core/toast';
import { DEFAULT_ERROR_MESSAGE } from '../../core/validators';
import { useStores } from '../../hooks';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import { useAuthModal } from '../Modal/AuthModal/AuthModal';
import './FollowButton.scss';
import { ReactComponent as AddIcon } from '../../icons/add-icon.svg';

interface IProps {
  className?: string;
  isFollowing: boolean;
  user: UserViewModel;
  type?: 'pill' | 'square';
  theme?: 'dark' | 'light';
  size?: 'sm' | 'md' | 'large';
}

const FollowButton = ({
  className = '',
  isFollowing,
  user,
  type = 'pill',
  theme = 'dark',
  size = 'md'
}: IProps) => {
  const { userStore, followUsers } = useStores();
  const { showAuthModal } = useAuthModal();
  const [isProcessing, setIsProcessing] = useState(false);

  const toggleFollow = async () => {
    if (!userStore.user) return showAuthModal();

    try {
      setIsProcessing(true);

      if (isFollowing) {
        await unfollowUser(user.id);
        followUsers.removeFollowing(user.id);

        appToast.showSuccess('Successfully unsubscribed');
        return;
      }

      const newFollowingUser = await followUser(user.id);
      followUsers.saveFollowing(newFollowingUser);

      appToast.showSuccess('You started to follow a user');
    } catch (e: any) {
      const error = e.response?.data?.description || DEFAULT_ERROR_MESSAGE;
      appToast.showError(error);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Button
      className={classNames(`FollowButton ${className}`, {
        'FollowButton--square': type === 'square',
        'FollowButton--light': theme == 'light' && !isFollowing,
        'FollowButton--light-following ': isFollowing && theme == 'light',
        'FollowButton--md': size == 'md',
        'FollowButton--large': size == 'large'
      })}
      size='auto'
      color={theme === 'light' ? 'light' : 'primary'}
      variant={isFollowing ? 'outline' : 'solid'}
      onClick={toggleFollow}
      disabled={isProcessing}
    >
      {isProcessing ? (
        <Loader
          fixed={false}
          showLogo={false}
          light={
            (theme == 'light' && isFollowing) ||
            (theme == 'dark' && !isFollowing)
          }
          width='22px'
        />
      ) : isFollowing ? (
        'Unfollow'
      ) : (
        <div className='FollowButton__content'>
          <AddIcon />
          <span>Follow</span>
        </div>
      )}
    </Button>
  );
};

export default FollowButton;
