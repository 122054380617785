import classNames from 'classnames';
import { useEffect } from 'react';
import getGlobal from '../../core/globals';
import { AppleCredentialResponse } from '../../core/types';
import './AppleAuthButton.scss';

interface Props {
  type?: 'sign-in' | 'continue' | 'sign-up';
  shape?: 'standard' | 'icon';
  color?: 'black' | 'white';
  radius?: boolean;
  logoSize?: 'small' | 'medium' | 'large';
  onClick?: React.MouseEventHandler;
  onSuccess: (response: AppleCredentialResponse) => void;
  onError: (error: string) => void;
}

const AppleAuthButton = ({
  type = 'sign-in',
  color = 'black',
  shape = 'standard',
  radius = true,
  logoSize = 'large',
  onClick,
  onSuccess,
  onError
}: Props) => {
  useEffect(() => {
    (window as any).AppleID?.auth.init({
      clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
      scope: 'name email',
      redirectURI: getGlobal('baseUrl'),
      state: 'origin:web',
      usePopup: true
    });

    document.addEventListener('AppleIDSignInOnSuccess', handleOnSuccess);
    document.addEventListener('AppleIDSignInOnFailure', handleOnFailure);

    return () => {
      document.removeEventListener('AppleIDSignInOnSuccess', handleOnSuccess);
      document.removeEventListener('AppleIDSignInOnFailure', handleOnFailure);
    };
  }, []);

  const handleOnSuccess = (event: any) => {
    onSuccess(event.detail);
  };

  const handleOnFailure = (event: any) => {
    if (event?.detail?.error) onError(event?.detail?.error);
  };

  return (
    <div
      className={classNames('AppleAuthButton', {
        'AppleAuthButton--icon': shape === 'icon'
      })}
    >
      <div
        id='appleid-signin'
        className='signin-button'
        data-mode={shape === 'standard' ? 'center-align' : 'logo-only'}
        data-color={color}
        data-border={radius}
        data-type={type}
        data-width='100%'
        data-height='32'
        data-logo-size={logoSize}
        onClick={onClick}
      ></div>
    </div>
  );
};

export default AppleAuthButton;
