import React, { useState } from 'react';
import './TextField.scss';

interface Props {
  value: string | number;
  minNumericValue?: number;
  autoComplete?: boolean;
  onChange: React.ChangeEventHandler;
  onBlur?: React.ChangeEventHandler;
  onKeyPress?: React.KeyboardEventHandler;
  className?: string;
  placeholder?: string;
  type?: 'text' | 'password' | 'number';
  name?: string;
  id?: string;
  error?: string;
  helperText?: string;
  touched?: boolean;
  as?: 'input' | 'textarea';
  rows?: number;
  label?: string;
  withCount?: boolean;
  endComponent?: React.ReactNode;
  disabled?: boolean;
  maxLength?: number;
}

const TextField = ({
  value,
  minNumericValue = 1,
  autoComplete = false,
  onChange,
  onKeyPress,
  className = '',
  placeholder,
  name,
  id,
  type = 'text',
  error,
  helperText,
  onBlur,
  touched,
  as = 'input',
  rows = 3,
  label,
  withCount = false,
  endComponent,
  disabled = false,
  maxLength = 524288
}: Props) => {
  const [altType, setAltType] = useState(type);

  const togglePasswordVisible = () => {
    'password' === type &&
      setAltType((prevState) => (prevState === 'text' ? 'password' : 'text'));
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.currentTarget;
    e.currentTarget.value =
      value.length > maxLength ? value.slice(0, maxLength) : value;

    onChange({ ...e });
  };

  return (
    <div className={`TextField ${className} ${type}`}>
      {!!label && <div className='TextField__label mb-1'>{label}</div>}
      {as === 'input' ? (
        <>
          <div className='TextField__inputWrapper'>
            <input
              type={altType}
              value={value ?? ''}
              min={minNumericValue}
              onChange={handleInputChange}
              onKeyPress={onKeyPress}
              autoComplete={autoComplete === true ? 'on' : 'new-password'}
              className='TextField__input'
              placeholder={placeholder}
              name={name}
              onBlur={onBlur}
              id={id}
              disabled={disabled}
            />
            {type === 'password' && (
              <button
                type='button'
                className='TextField__show-more'
                onClick={togglePasswordVisible}
              >
                {altType === 'password' ? 'Show' : 'Hide'}
              </button>
            )}
          </div>
          {endComponent}
        </>
      ) : (
        <div>
          <textarea
            rows={rows}
            value={value}
            onChange={handleInputChange}
            onKeyPress={onKeyPress}
            autoComplete={autoComplete ? 'on' : 'off'}
            className='TextField__input TextField__input--textarea'
            placeholder={placeholder}
            name={name}
            onBlur={onBlur}
            id={id}
            disabled={disabled}
            maxLength={maxLength}
          />
          {withCount && (
            <div className='TextField__count'>120 characters left</div>
          )}
        </div>
      )}
      {helperText && <div className='TextField__helperText'>{helperText}</div>}
      {((touched && error) || error) && (
        <div className='TextField__error'>{error}</div>
      )}
    </div>
  );
};

export default TextField;
