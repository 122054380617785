type ConfigType =
  | 'local'
  | 'dev-local'
  | 'development'
  | 'development2'
  | 'staging'
  | 'production';

interface iConfigItem {
  api: string;
  baseUrl: string;
  devHeader: { 'x-web-dev': string } | null;
  env: ConfigType;
}

type iConfig = {
  [buildKey in ConfigType]: Partial<iConfigItem>;
};

const BASE_CONFIG: iConfigItem = {
  api: '/api',
  baseUrl: '',
  devHeader:
    process.env.REACT_APP_ENV === 'dev-local' ? { 'x-web-dev': 'true' } : null,
  env: process.env.REACT_APP_ENV as ConfigType
};

const GLOBALS_CONFIG: iConfig = {
  local: {},
  'dev-local': {
    baseUrl: 'https://dev.hoopla.com'
  },
  development: {
    baseUrl: 'https://dev.hoopla.com'
  },
  development2: {
    baseUrl: 'https://dev2.hoopla.com',
    api: 'https://dev.hoopla.com/api'
  },
  staging: {
    baseUrl: 'https://staging.hoopla.com'
  },
  production: {
    baseUrl: 'https://hoopla.com',
    api: 'https://api.hoopla.com'
  }
};

const configName: ConfigType =
  (process.env.REACT_APP_ENV as ConfigType) || 'development';

export default function getGlobal(key: keyof iConfigItem): any {
  try {
    return { ...BASE_CONFIG, ...GLOBALS_CONFIG[configName] }[key];
  } catch (err) {
    return '';
  }
}
