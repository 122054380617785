import { makeAutoObservable } from 'mobx';
import { VendorViewModel } from '../core/backend/models';

class CreatorStore {
  creators: VendorViewModel[] | undefined = [];

  constructor() {
    makeAutoObservable(this);
  }
  saveToStore = (creators: VendorViewModel[] | undefined) => {
    this.creators = creators;
  };
}

export default CreatorStore;
