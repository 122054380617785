import ApiCalendar from 'react-google-calendar-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { GOOGLE_CAL_DISCOVERY_DOC, GOOGLE_CAL_SCOPES } from '../../core/consts';
import { useEffect, useState } from 'react';
import './GoogleCalendarButton.scss';
import { BookingViewModel } from '../../core/backend/models';
import { CalendarEvent } from '../../core/types';
import appToast from '../../core/toast';
import { DEFAULT_ERROR_MESSAGE } from '../../core/validators';
import { useStores } from '../../hooks';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import { ReactComponent as GoogleCalendarIcon } from '../../icons/google-calendar.svg';
import ExperienceModel from '../../core/models/ExperienceModel';

interface IProps {
  eventName: string;
  eventDescription: string;
  eventLocation: string;
  eventDate?: number;
  eventDuration?: number;
  className?: string;
}

const googleCalendar = new ApiCalendar({
  apiKey: process.env.REACT_APP_GOOGLE_CAL_API_KEY,
  clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  discoveryDocs: [GOOGLE_CAL_DISCOVERY_DOC],
  scope: GOOGLE_CAL_SCOPES
});

let googleTokenClient: any;

dayjs.extend(utc);
dayjs.extend(timezone);

const GoogleCalendarButton = ({
  eventName,
  eventDescription,
  eventLocation,
  eventDate,
  eventDuration,
  className = ''
}: IProps) => {
  const gapi = (window as any)?.gapi;
  const { userStore, bookingsStore, stripeStore } = useStores();
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    googleCalendar.onLoad(() => {
      googleTokenClient = googleCalendar.tokenClient;
    });
  }, []);

  const requestGoogleCalendarPermission = () => {
    return new Promise<any>((resolve, reject) => {
      googleTokenClient.callback = (resp: any) => {
        if (resp.error !== undefined) {
          reject(resp);
        }

        resolve(resp);
      };

      if (gapi && googleTokenClient) {
        if (gapi.client.getToken() === null) {
          googleTokenClient.requestAccessToken({
            prompt: 'consent'
          });
        } else {
          googleTokenClient.requestAccessToken({
            prompt: ''
          });
        }
      } else {
        console.error(
          'Something went wrong. Please close the modal and retry.'
        );
      }
    });
  };

  const handleAddEventToCalendar = async () => {
    try {
      const response = await requestGoogleCalendarPermission();
      if (!response?.error) {
        setIsProcessing(true);

        const event: CalendarEvent = {
          summary: eventName,
          description: eventDescription,
          location: eventLocation,
          start: {
            dateTime: dayjs(eventDate).toISOString(),
            timeZone: dayjs.tz.guess()
          },
          end: {
            dateTime: dayjs(eventDate)
              .add(eventDuration, 'seconds')
              .toISOString(),
            timeZone: dayjs.tz.guess()
          }
        };

        const eventRequest = gapi.client.calendar.events.insert({
          calendarId: 'primary',
          resource: event
        });

        eventRequest.execute(function (event: any) {
          appToast.showSuccess('Event added to your calendar!');
        });
      }
    } catch (e: any) {
      appToast.showError(e?.error?.message || DEFAULT_ERROR_MESSAGE);
    } finally {
      setIsProcessing(false);
    }
  };

  return gapi && googleCalendar.tokenClient ? (
    <div className={`GoogleCalendarButton ${className}`}>
      {isProcessing && <Loader />}
      <Button
        onClick={handleAddEventToCalendar}
        size='full-width'
        variant='outline'
        icon={<GoogleCalendarIcon width={28} />}
      >
        Add to Google Calendar
      </Button>
    </div>
  ) : null;
};

export default GoogleCalendarButton;
