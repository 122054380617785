import { useEffect, useRef, useState } from 'react';
import './HoverVideoPlayer.scss';
import { useDebounce } from '../../hooks';
import classNames from 'classnames';
import Loader from '../Loader/Loader';

interface IProps {
  className?: string;
  videoClassName?: string;
  videoSrc: string;
  mouseEnterDelay?: number;
  restartOnPaused?: boolean;
  displayLoader?: boolean;
  pausedOverlay?: React.ReactNode;
  onClick: React.MouseEventHandler;
}

type VideoState = 'hidden' | 'loading' | 'playing';

const HoverVideoPlayer = ({
  className = '',
  videoClassName = '',
  videoSrc,
  mouseEnterDelay = 700,
  pausedOverlay,
  restartOnPaused = true,
  displayLoader = true,
  onClick
}: IProps) => {
  const parentElementRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const timerRef = useRef<NodeJS.Timeout>(null);
  const [isVideoShown, setIsVideoShown] = useState(false);
  const [videoState, setVideoState] = useState<VideoState>('hidden');
  const debouncedIsVideoShown = useDebounce(isVideoShown, 300);

  const isAVideo = videoSrc?.endsWith('.mp4');

  useEffect(() => {
    return () => {
      setIsVideoShown(false);
      setVideoState('hidden');

      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    };
  }, []);

  const onMouseEnter = () => {
    if (!isAVideo) return;

    setVideoState('loading');

    setTimeout(() => {
      setIsVideoShown(true);
    }, mouseEnterDelay);
  };

  const onMouseLeave = () => {
    if (!isAVideo) return;

    setIsVideoShown(false);
    setVideoState('hidden');

    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }

    //Avoid annoying effect when transitioning from video to image
    setTimeout(() => {
      if (restartOnPaused && videoRef.current) {
        videoRef.current.currentTime = 0;
      }
    }, 100);
  };

  const handleOnPlay = () => {
    setVideoState('playing');
  };

  return (
    <div
      ref={parentElementRef}
      className={`HoverVideoPlayer ${className}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onTouchStart={onMouseEnter}
      onTouchEnd={onMouseLeave}
      onClick={onClick}
    >
      {displayLoader && videoState === 'loading' && isAVideo && (
        <Loader
          className='HoverVideoPlayer__loader'
          fixed={false}
          width='16px'
          showLogo={false}
        />
      )}
      {pausedOverlay && (
        <div
          className={classNames('HoverVideoPlayer__pausedOverlay', {
            fadeOut: debouncedIsVideoShown && videoState === 'playing',
            fadeIn: !isVideoShown
          })}
        >
          {pausedOverlay}
        </div>
      )}

      {isAVideo && debouncedIsVideoShown && (
        <video
          muted
          loop
          playsInline
          autoPlay
          ref={videoRef}
          className={videoClassName}
          preload='auto'
          width='100%'
          height='100%'
          onPlay={handleOnPlay}
        >
          <source src={videoSrc} type='video/mp4' />
        </video>
      )}
    </div>
  );
};

export default HoverVideoPlayer;
