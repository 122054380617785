import classNames from 'classnames';
import './Steps.scss';

interface Props {
  activeId: number;
  steps: number[] | number;
  className?: string;
}

const Steps = ({ activeId, steps, className = '' }: Props) => {
  const stepsArray = Array.isArray(steps)
    ? steps
    : new Array(steps).fill('').map((_, idx: number) => idx);

  return (
    <div className={`Steps d-flex align-items-end ${className}`}>
      {stepsArray.map((curId) => (
        <div className='Steps__item' key={curId}>
          <div
            className={classNames('Steps__figure', {
              'Steps__figure--passed': curId <= activeId
            })}
            key={curId}
          />
        </div>
      ))}
    </div>
  );
};

export default Steps;
