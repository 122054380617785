import { ReactComponent as LikeIcon } from '../../icons/like.svg';
import classNames from 'classnames';
import './LikeButton.scss';

interface IProps {
  count: number;
  liked: boolean;
  onClick: React.MouseEventHandler;
  className?: string;
}

const LikeButton = ({ count, liked, onClick, className = '' }: IProps) => {
  return (
    <button
      className={classNames(`LikeButton ${className}`, {
        'LikeButton--liked': liked
      })}
      onClick={onClick}
    >
      <LikeIcon /> {count}
    </button>
  );
};

export default LikeButton;
