import { IFilterConfiguration } from '../../types';
import database from './database';

class Filter {
  private phrase!: string;
  private originalText?: string;
  private config?: IFilterConfiguration;
  private wordlist?: string[];
  private censuredPhrase: string = '';

  constructor(inputStr: string = '', config?: IFilterConfiguration) {
    const configDefaults: IFilterConfiguration = {
      level: 1,
      saveOriginal: true,
      enabled: true,
      placeHolder: '*',
      replaceRegex: /[\wÀ-ž]/g,
      separatorRegex: /\w+|[^\w\s]|\s+/g,
      excludeWords: [],
      language: 'en-us'
    };

    this.phrase = !inputStr || inputStr.length < 1 ? '' : inputStr;
    this.config = { ...configDefaults, ...config };
    this.wordlist = this.config?.wordsList ?? database;
  }

  private scan() {
    if (this.phrase.length < 1) {
      this.censuredPhrase = this.phrase;
      return this;
    }

    const separatorRegex = this.config?.separatorRegex
      ? this.config?.separatorRegex
      : '';

    this.censuredPhrase = this.normalizeText(this.phrase)
      .match(separatorRegex)
      ?.map((value) => {
        return this.isProfane(value) ? this.censureWord(value) : value;
      })
      .reduce((current, next) => current + next, '');

    return this;
  }

  censureWord(word: any) {
    if (word === undefined) {
      console.error('Unexpected error: missing word');
      return;
    }
    return word.replace(this.config?.replaceRegex, this.config?.placeHolder);
  }

  private normalizeText(str: string) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  censor(str?: string) {
    this.originalText = this.config?.saveOriginal ? this.phrase : '';

    // if false return origianl sentence
    if (!this.config?.enabled) {
      return this.phrase;
    }

    if (str) this.phrase = str;

    this.scan();
    return this.censuredPhrase;
  }

  isProfane(value: string) {
    if (this.wordlist === undefined) {
      console.error('Unexpected error: wordlist is invalid.');
      return;
    }

    return this.wordlist.filter((word) => {
      const regex = new RegExp(`\\b${word.replace(/(\W)/g, '\\$1')}\\b`, 'gi');
      return (
        !this.config?.excludeWords?.includes(word.toLowerCase()) &&
        regex.test(value)
      );
    }).length > 0
      ? true
      : false;
  }

  loadOriginal() {
    if (this.config?.saveOriginal) {
      return this.originalText;
    }
    return '';
  }

  addWords(...words: string[]) {
    if (words.length === 0)
      console.error('Unexpected error: need at last one word');
    this.wordlist?.push(...words);
    return this;
  }

  removeWords(...words: string[]) {
    if (words.length === 0)
      console.error('Unexpected error: need at last one word to remove');
    const regex = new RegExp(words.join('|'), 'i');
    this.wordlist = this.wordlist?.filter((item) => {
      if (!regex.test(item)) return item;
    });
    return this;
  }
}

export default Filter;
