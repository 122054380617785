import { makeAutoObservable } from 'mobx';
import { CommentViewModelV21 } from '../core/backend/models';

class CommentStore {
  comments: CommentViewModelV21[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  saveToStore = (comments: CommentViewModelV21[] | undefined) => {
    this.comments = comments;
  };

  prependComments = (comments: CommentViewModelV21[] | undefined) => {
    this.comments = [...comments, ...this.comments];
  };

  addComment = (comment: CommentViewModelV21) => {
    this.saveToStore([...this.comments, comment]);
  };

  addReplyToComment = (reply: CommentViewModelV21) => {
    this.searchCommentAndReply(reply, this.comments);
  };

  removeAddLikeToComment = (comment: CommentViewModelV21) => {
    this.searchCommentAndLikeUnlike(comment, this.comments);
  };

  updateComment = (commentId: number, newComment: CommentViewModelV21) => {
    const idx = this.getIndexById(commentId);
    this.comments.splice(idx, 1, newComment);
  };

  getCommentById = (commentId: number) => {
    return this.comments.find((comment) => comment.id === commentId);
  };

  getIndexById = (commentId: number) => {
    return this.comments.findIndex((comment) => comment.id === commentId);
  };

  searchCommentAndReply = (
    reply: CommentViewModelV21,
    comments: CommentViewModelV21[]
  ) => {
    for (const comment of comments) {
      if (comment.id === reply.replyCommentId) {
        if (!comment.replyComments) comment.replyComments = [];
        comment.replyComments = [...comment.replyComments, reply];
        break;
      }

      if (comment.replyComments)
        this.searchCommentAndReply(comment, comment.replyComments);
    }
  };

  searchCommentAndLikeUnlike = (
    comment: CommentViewModelV21,
    comments: CommentViewModelV21[]
  ) => {
    for (const currentComment of comments) {
      if (currentComment.id === comment.id) {
        currentComment.isLikedByMe = !currentComment.isLikedByMe;
        currentComment.likesCount += currentComment.isLikedByMe ? -1 : 1;
        break;
      }

      if (comment.replyComments)
        this.searchCommentAndLikeUnlike(comment, comment.replyComments);
    }
  };
}

export default CommentStore;
