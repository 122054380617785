import { memo, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {
  AttendeeViewModel,
  UserTimeAvailabilityInput,
  UserViewModel
} from '../../../core/backend/models';
import CreatorCard from '../../Cards/CreatorCard/CreatorCard';
import { useStores } from '../../../hooks';
import { useAuthModal } from '../../Modal/AuthModal/AuthModal';
import ExperienceModel from '../../../core/models/ExperienceModel';
import { useMessenger } from '../../Messenger/Messenger';
import Button from '../../Button/Button';
import GoogleCalendarButton from '../../GoogleCalendarButton/GoogleCalendarButton';
import Attendees from '../../Attendees/Attendees';
import EventLocation from '../../EventLocation/EventLocation';
import AttendeesModal from '../../Modal/AttendeesModal/AttendeesModal';
import 'react-tabs/style/react-tabs.scss';
import './ExperienceDetails.scss';
import ReviewCard from '../../Cards/ReviewCard/ReviewCard';
import { Address, IVideoModel } from '../../../core/types';
import Loader from '../../Loader/Loader';
import { useVideoModal } from '../../Modal/VideoModal/useVideoModal';
import { ReactComponent as BackIcon } from '../../../icons/back.svg';
import { ReactComponent as ChatIcon } from '../../../icons/chat_bubble.svg';
import { ReactComponent as PriceIcon } from '../../../icons/price_tag.svg';
import { ReactComponent as AboutIcon } from '../../../icons/details.svg';
import { ReactComponent as ClipsIcon } from '../../../icons/clips.svg';
import { ReactComponent as EmptyClipsIcon } from '../../../icons/clips_glyph.svg';
import { ReactComponent as EmptyReviewsIcon } from '../../../icons/reviews_glyph.svg';
import ClipCard from '../../Cards/ClipCard/ClipCard';
import ReactShowMoreText from 'react-show-more-text';
import { BookingStatus } from '../../../core/backend/enums';
import TimeAvailability from '../../../TimeAvailability/TimeAvailability';
import { useExperienceModal } from '../../Modal/ExperienceModal/useExperienceModal';
import GoogleTagManager from '../../../core/services/GoogleTagManager';
import { TrackingEvents } from '../../../core/enums';
import TikTokPixel from '../../../core/services/TikTokPixel';
import MetaPixel from '../../../core/services/MetaPixel';
import UrlToAnchor from '../../UrlToAnchor/UrlToAnchor';
import ShowMore from '../../ShowMore/ShowMore';

interface IProps {
  isLoadingRelatedClips?: boolean;
  event: ExperienceModel;
  attendees: AttendeeViewModel[];
  bookingCount?: number;
  availability: UserTimeAvailabilityInput;
  relatedClips: IVideoModel[];
  onGoBack?: () => void;
}

interface IAttendeesModal {
  isOpen: boolean;
  attendees?: AttendeeViewModel[];
}

const ExperienceDetails = ({
  isLoadingRelatedClips,
  event,
  attendees = [],
  bookingCount = 0,
  availability,
  relatedClips = [],
  onGoBack
}: IProps) => {
  const { userStore, bookingsStore, bookingStore } = useStores();
  const videoModal = useVideoModal();
  const { closeExperienceModal } = useExperienceModal();
  const { showAuthModal } = useAuthModal();
  const { createChatWithUser } = useMessenger();
  const [attendeesModal, setAttendeesModal] = useState<IAttendeesModal>({
    isOpen: false,
    attendees: null
  });
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedTabIndex(event.isEventClosed && relatedClips.length > 0 ? 1 : 0);
  }, [relatedClips]);

  useEffect(() => {
    event.booking = userStore.user
      ? bookingsStore.getMostRecentBookingByExperience(event.id)
      : undefined;
  }, [event, userStore.user, bookingsStore.userBookings]);

  const handleInitChat = (user: UserViewModel) => () => {
    if (!userStore.user) {
      showAuthModal();
      return;
    }

    createChatWithUser(user);
  };

  const handleBookEvent = () => {
    GoogleTagManager.dataLayer({
      event: TrackingEvents.AddToCart,
      contentType: 'Experience',
      userType: userStore.user ? 'Authenticated' : 'Guest'
    });

    MetaPixel.track('AddToCart', {
      content_name: event.name,
      content_type: 'product',
      content_ids: [event.id.toString()],
      value: event.hasCustomTickets ? event.ticketsStartingPrice : event.price
    });

    TikTokPixel.track('AddToCart', {
      content_id: event.id.toString(),
      content_name: event.name,
      content_category: 'Experience',
      value: event.price,
      currency: 'USD'
    });

    navigate(`/book/${event.id}`);
  };

  const handleShowAttendeesModal = (attendees: AttendeeViewModel[]) => () => {
    setAttendeesModal({ isOpen: true, attendees });
  };

  const handleCloseAttendeesModal = () => {
    setAttendeesModal({ isOpen: false, attendees: null });
  };

  const showActionButtons = userStore.user?.id !== event.creator.id;
  const showAddToCalendar =
    userStore.user &&
    event.booking &&
    event.booking?.bookingStatus === BookingStatus.VendorConfirmed;

  const eventLocation: Address =
    event.isOneTimeEvent ||
    event.isCreatorLocation ||
    (event.isCustomerLocation && !event.IsAreaFlexible)
      ? event.location
      : (event.isFlexibleLocation && !event.isVirtual) ||
        (event.isCustomerLocation && event.IsAreaFlexible)
      ? userStore.addressFields
      : null;

  return (
    <div className='ExperienceDetails'>
      {onGoBack && (
        <div className='ExperienceDetails__goBackButtonWrapper'>
          <Button
            className='ExperienceDetails__goBackButton'
            variant='outline'
            size='auto'
            icon={<BackIcon className='ExperienceDetails__goBackButtonIcon' />}
            onClick={onGoBack}
          >
            Video
          </Button>
        </div>
      )}

      <div className='ExperienceDetails__wrapper'>
        <div className='ExperienceDetails__title threeLinesText'>
          {event.name}
        </div>

        <div className='ExperienceDetails__price'>
          <PriceIcon className='ExperienceDetails__priceIcon' />
          <div className='ExperienceDetails__priceDetails'>
            <div className='ExperienceDetails__currentPrice'>
              Booking Price: {event.priceLabel}
            </div>
            {event.discount > 0 && (
              <div className='ExperienceDetails__normalPrice'>
                Discount {event.discountPercentage}%{' '}
                <span>{event.formattedDiscount}</span>
              </div>
            )}
            <div className='ExperienceDetails__additionalPriceInfo'>
              {event.isHourly ? 'Per hour' : 'Per person'}
            </div>
          </div>
        </div>

        {showActionButtons && (
          <div className='ExperienceDetails__actions'>
            <Button
              className='ExperienceDetails__chatAction'
              variant='outline'
              color='primary'
              size='auto'
              icon={<ChatIcon />}
              onClick={handleInitChat(event.creator)}
            >
              Chat
            </Button>

            <Button
              className='ExperienceDetails__bookAction'
              onClick={handleBookEvent}
            >
              Book
            </Button>
          </div>
        )}

        {showAddToCalendar && (
          <GoogleCalendarButton
            className='ExperienceDetails__calendar ExperienceDetails__pb-24'
            eventName={event.name}
            eventDescription={event.shortDescription}
            eventLocation={event.bookingAddress}
            eventDate={event.booking?.timestampUtc}
            eventDuration={event.booking?.duration}
          />
        )}

        {attendees.length > 0 && (
          <div className='ExperienceDetails__block ExperienceDetails__pt-12 ExperienceDetails__pb-12'>
            <h3 className='ExperienceDetails__attendingHeader'>
              Bookings: {bookingCount}
            </h3>
            <div className='ExperienceDetails__description'>
              {/* <AttendeesLoader /> */}
              <Attendees
                attendees={attendees}
                onViewAllClick={handleShowAttendeesModal(attendees)}
              />
            </div>
          </div>
        )}

        <Tabs
          className='ExperienceDetails__tabs ExperienceDetails__pt-12'
          selectedIndex={selectedTabIndex}
          onSelect={setSelectedTabIndex}
        >
          <TabList className='ExperienceDetails__tabList'>
            <Tab className='ExperienceDetails__tab'>
              <AboutIcon />
              <div className='ExperienceDetails__tabLabel'>About</div>
            </Tab>
            <Tab className='ExperienceDetails__tab'>
              <ClipsIcon />
              <div className='ExperienceDetails__tabLabel'>Clips</div>
            </Tab>
          </TabList>
          <TabPanel>
            {event.description && (
              <div className='ExperienceDetails__block ExperienceDetails__separator'>
                <ShowMore
                  className='ExperienceDetails__infoContent'
                  triggerClassName='ExperienceDetails__showMore'
                  lines={5}
                >
                  <UrlToAnchor>{event.description}</UrlToAnchor>
                </ShowMore>
              </div>
            )}

            {event.whatsIncluded && (
              <div className='ExperienceDetails__block ExperienceDetails__separator ExperienceDetails__pt-24'>
                <h3 className='ExperienceDetails__headers'>What's Included</h3>
                <ShowMore
                  className='ExperienceDetails__infoContent'
                  triggerClassName='ExperienceDetails__showMore'
                  lines={5}
                >
                  <UrlToAnchor>{event.whatsIncluded}</UrlToAnchor>
                </ShowMore>
              </div>
            )}

            <div className='ExperienceDetails__block ExperienceDetails__separator ExperienceDetails__pt-24'>
              <h3
                className={classNames('ExperienceDetails__headers', {
                  'ExperienceDetails__headers--location': !event.isVirtual
                })}
              >
                {event.isVirtual ? 'Virtual' : 'Location'}
              </h3>
              {event.isCreatorLocation && !event.isVirtual ? (
                <EventLocation
                  className='ExperienceDetails__location'
                  address={eventLocation}
                  coords={event.coords}
                  serviceRadius={
                    event.isCustomerLocation && !event.IsAreaFlexible
                      ? event.serviceRadius
                      : null
                  }
                  displayDistance={!!event.isCreatorLocation}
                />
              ) : (
                <div className='ExperienceDetails__description'>
                  {event.isVirtual
                    ? 'The Experience Creator will provide you the link shortly'
                    : 'We come to you'}
                </div>
              )}
            </div>

            <div className='ExperienceDetails__block ExperienceDetails__separator'>
              <CreatorCard
                creator={event.creator}
                className='ExperienceDetails__creator'
              />
            </div>

            {event.whatToBring && (
              <div className='ExperienceDetails__block ExperienceDetails__separator'>
                <h3 className='ExperienceDetails__headers'>What to Bring?</h3>
                <ShowMore
                  className='ExperienceDetails__infoContent'
                  triggerClassName='ExperienceDetails__showMore'
                  lines={5}
                >
                  <UrlToAnchor>{event.whatToBring}</UrlToAnchor>
                </ShowMore>
              </div>
            )}

            {availability && (
              <div className='ExperienceDetails__block ExperienceDetails__separator'>
                <h3 className='ExperienceDetails__headers'>
                  Creator's Availability
                </h3>
                {availability?.isFlexible ? (
                  <div className='ExperienceDetails__description'>Flexible</div>
                ) : (
                  <TimeAvailability
                    className='GigDetails__availability'
                    availability={availability}
                  />
                )}
              </div>
            )}

            <div className='ExperienceDetails__block ExperienceDetails__pt-24'>
              <h3 className='ExperienceDetails__headers ExperienceDetails__policyHeader'>
                Cancellation Policy
              </h3>
              <div className='ExperienceDetails__description'>
                Please note that all one-time event bookings are non-refundable.
                Please ensure that you are able to commit to this date and time
                before proceeding.
              </div>
              <a
                className='ExperienceDetails__policyLink'
                href='https://hoopla.com/terms-and-conditions'
                target='_blank'
              >
                Hoopla Terms of Service & Cancellation Policy
              </a>
            </div>
          </TabPanel>
          <TabPanel>
            <div className='ExperienceDetails__clips ExperienceDetails__pt-12'>
              {isLoadingRelatedClips ? (
                <Loader fixed={false} showLogo={false} />
              ) : relatedClips.length > 0 ? (
                relatedClips.map((clip, idx) => (
                  <ClipCard
                    key={`related-clip-${clip.id}`}
                    className='ExperienceDetails__clip'
                    compact
                    clip={clip}
                    onPress={() => {
                      /**
                       * This fixes the overlap issue when video modal is open
                       * and the the event modal is open later and user tries to
                       * open a video from clips. Closes experience modal.
                       */
                      if (videoModal.isOpen) {
                        closeExperienceModal();
                      }

                      videoModal.openVideoModal({
                        media: relatedClips,
                        startAtIndex: idx
                      });
                    }}
                  />
                ))
              ) : (
                <div className='ExperienceDetails__noContent'>
                  <span>There are no clips left yet</span>
                  <EmptyClipsIcon />
                </div>
              )}
            </div>
          </TabPanel>
        </Tabs>
      </div>

      <AttendeesModal
        isOpened={attendeesModal.isOpen}
        bookingCount={bookingCount}
        attendees={attendeesModal.attendees}
        close={handleCloseAttendeesModal}
      />
    </div>
  );
};

export default memo(ExperienceDetails) as React.FC<IProps>;
