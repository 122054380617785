import React, { useEffect, useRef } from 'react';

export interface CredentialResponse {
  /** This field is the returned ID token */
  credential?: string;
  /** This field sets how the credential is selected */
  select_by?:
    | 'auto'
    | 'user'
    | 'user_1tap'
    | 'user_2tap'
    | 'btn'
    | 'btn_confirm'
    | 'brn_add_session'
    | 'btn_confirm_add_session';
  clientId?: string;
}

export interface GoogleCredentialResponse extends CredentialResponse {
  client_id?: string;
}

interface Props {
  type?: 'signin_with' | 'signup_with' | 'continue_with';
  shape?: 'standard' | 'icon';
  onClick?: React.MouseEventHandler;
  onSuccess: (credentialResponse: CredentialResponse) => void;
  onError: () => void;
}

const GoogleAuthButton = ({
  type = 'signin_with',
  shape = 'standard',
  onClick,
  onSuccess,
  onError
}: Props) => {
  const googleButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const google = (window as any).google.accounts.id;
    google.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleGoogleCallbackResponse
    });

    google.renderButton(googleButtonRef.current, {
      theme: 'outline',
      size: 'medium',
      type: shape,
      width: '337px',
      shape: 'rectangular',
      text: type
    });
  }, []);

  const handleGoogleCallbackResponse = (response: GoogleCredentialResponse) => {
    if (!response?.credential) {
      return onError();
    }

    const { credential, select_by } = response;
    onSuccess({
      credential,
      select_by,
      clientId: response.clientId ?? response.client_id
    });
  };

  return <div ref={googleButtonRef}></div>;
};

export default GoogleAuthButton;
