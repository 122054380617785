import cn from 'classnames';
import './PlaceHolderCard.scss';

interface Props {
  icon?: React.ReactNode;
  label?: string;
  light?: boolean;
}

const PlaceHolderCard = ({ icon, label, light = false }: Props) => {
  return (
    <div
      className={cn('PlaceHolderCard', {
        'PlaceHolderCard--light': light
      })}
    >
      {label && <div className='PlaceHolderCard__label'>{label}</div>}
      {icon && <div className='PlaceHolderCard__icon'>{icon}</div>}
    </div>
  );
};

export default PlaceHolderCard;
