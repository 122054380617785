import { useFormik } from 'formik';
import { useState } from 'react';
import { resetUserPassword } from '../../../../../core/api';
import { DEFAULT_ERROR_MESSAGE } from '../../../../../core/validators';
import Button from '../../../../Button/Button';
import TextField from '../../../../TextField/TextField';
import { useAuthModal } from '../../AuthModal';
import Loader from '../../../../Loader/Loader';
import './ResetPasswordForm.scss';
import emailSchema from '../../../../../core/validations/emailSchema';
import appToast from '../../../../../core/toast';

interface FormValues {
  email: string;
}

const ResetPasswordForm = () => {
  const { showAuthModal, closeAuthModal, changeAuthModalTitles, formData } =
    useAuthModal();
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState<string>(null);
  const [instructionsSent, setInstructionsSent] = useState(false);

  const {
    handleSubmit,
    submitForm,
    handleChange,
    values,
    errors,
    handleBlur,
    touched,
    resetForm
  } = useFormik<FormValues>({
    initialValues: { email: formData?.email ?? '' },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: emailSchema,
    onSubmit: (values) => {
      const { email } = values;
      sendResetInstructions(email);
    }
  });

  const sendResetInstructions = async (email: string) => {
    try {
      setIsLoading(true);

      await resetUserPassword(email);
      setInstructionsSent(true);
      changeAuthModalTitles('Check your Email');
    } catch (e: any) {
      const error = e.response?.data?.description || DEFAULT_ERROR_MESSAGE;
      setServerError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetFormData = () => {
    setIsLoading(false);
    setInstructionsSent(false);
    resetForm();
  };

  const handleResendResetPasswordEmail = async () => {
    await submitForm();
    appToast.showSuccess('Instructions Resent');
  };

  const handleSwitchSignUpForm = () => {
    showAuthModal('sign_up', formData);
    handleResetFormData();
  };

  const handleCloseModal = () => {
    closeAuthModal();
    handleResetFormData();
  };

  return (
    <form className='ResetPasswordForm' onSubmit={handleSubmit}>
      {/**
       * Display this loader only when resending email instructions
       */}
      {isLoading && instructionsSent && <Loader />}
      {!instructionsSent ? (
        <>
          <div className='ResetPasswordForm__information'>
            Please enter the email associated with your account
          </div>

          {serverError && (
            <div className='ResetPasswordForm__errorMessage'>{serverError}</div>
          )}

          <div className='ResetPasswordForm__inputs mb-2'>
            <TextField
              label='Email'
              name='email'
              id='email'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={errors.email}
              touched={touched.email}
            />
          </div>

          <div className='ResetPasswordForm__buttons'>
            <Button
              className='ResetPasswordForm__sendButton'
              size='full-width'
              type='submit'
              disabled={isLoading}
            >
              {isLoading ? (
                <Loader light fixed={false} showLogo={false} width='32px' />
              ) : (
                'Send Instructions'
              )}
            </Button>

            <div className='ResetPasswordForm__createAccount'>
              Don't have an account?{' '}
              <Button
                className='ResetPasswordForm__signUp'
                variant='text'
                onClick={handleSwitchSignUpForm}
              >
                Sign Up
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='ResetPasswordForm__information'>
            Instructions to reset your password has been sent to your email
            address: <b>{values.email}</b>
          </div>

          <div className='ResetPasswordForm__notReceivedInformation'>
            Did not receive the email? Please check your spam folder or
            <Button
              className='ResetPasswordForm__resend'
              variant='text'
              onClick={handleResendResetPasswordEmail}
            >
              resend Reset Password email
            </Button>
          </div>

          <div className='ResetPasswordForm__buttons mb-3'>
            <Button
              className='ResetPasswordForm__sendButton'
              size='full-width'
              onClick={handleCloseModal}
            >
              I'll do this later
            </Button>
          </div>
        </>
      )}
    </form>
  );
};

export default ResetPasswordForm;
