import { FormikErrors, useFormik } from 'formik';
import { useState } from 'react';
import {
  getStripePublicKey,
  getUser,
  requestPhoneSMSCode,
  signInWithPhoneSMSCode
} from '../../../../../core/api';
import { DEFAULT_ERROR_MESSAGE } from '../../../../../core/validators';
import Button from '../../../../Button/Button';
import { useAuthModal } from '../../AuthModal';
import appToast from '../../../../../core/toast';
import Loader from '../../../../Loader/Loader';
import ReactCodeInput from 'react-code-input';
import './SmsCodeForm.scss';
import { AUTH_SUCCESS_MSG } from '../../../../../core/consts';
import { getAllUserData, getUserProfileData } from '../../AuthModal.helper';
import { useStores } from '../../../../../hooks';
import { UserViewModel } from '../../../../../core/backend/models';

interface FormValues {
  code: string;
}

const SmsCodeForm = () => {
  const { userStore, stripeStore, bookingsStore, likedMedia, followUsers } =
    useStores();
  const { showAuthModal, closeAuthModal, formData } = useAuthModal();
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState<string>(null);

  const { handleSubmit, setFieldValue, values, errors } = useFormik<FormValues>(
    {
      initialValues: { code: '' },
      validateOnChange: false,
      validateOnBlur: false,
      validate: (values) => {
        setServerError(null);
        const errors: FormikErrors<FormValues> = {};
        if (!values.code) {
          errors.code = 'Required';
        }

        return errors;
      },
      onSubmit: (values) => {
        const { code } = values;
        setIsLoading(true);
        validateAndSignInWithSMSCode(code);
      }
    }
  );

  const getUserDataAndStore = async () => {
    const user = await getUser();
    userStore.saveUserToStore(user);

    getRequiredDataAsync(user);
    return user;
  };

  const getRequiredDataAsync = async (user: UserViewModel) => {
    const userIsCreatorPro = user.isCreatorPro || user.isProviderPro;
    const { events, clips, summary, followers } = await getUserProfileData(
      user.id,
      userIsCreatorPro
    );
    userStore.saveMediaToStore(events, clips);
    userStore.saveStatistics(summary);
    userStore.saveFollowers(followers);

    const {
      userBookings,
      creatorBookings,
      userLikes,
      following,
      paymentMethods
    } = await getAllUserData();

    if (!stripeStore.publicKey) {
      const stripePublicKey = await getStripePublicKey();
      stripeStore.setPublicKey(stripePublicKey.publishableKey);
    }

    userStore.saveUserToStore(user);
    bookingsStore.saveToStore(userBookings, creatorBookings);
    likedMedia.saveToStore(userLikes);
    followUsers.saveToStore(following);
    userStore.savePaymentMethods(paymentMethods);
  };

  const validateAndSignInWithSMSCode = async (code: string) => {
    try {
      await signInWithPhoneSMSCode(code);

      const user = await getUserDataAndStore();

      appToast.showSuccess(AUTH_SUCCESS_MSG);

      if (!user.registrationCompleted) {
        showAuthModal('complete_profile');
      } else {
        closeAuthModal();
      }
    } catch (e: any) {
      const error = e.response?.data?.description || DEFAULT_ERROR_MESSAGE;
      setServerError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSwitchSignUpForm = () => {
    showAuthModal('sign_up', formData);
  };

  const handleResendCode = async () => {
    try {
      setIsLoading(true);
      await requestPhoneSMSCode(encodeURIComponent(formData?.phoneNumber));
      appToast.showSuccess('We sent you a new code to your phone number');
    } catch (e: any) {
      const error = e.response?.data?.description || DEFAULT_ERROR_MESSAGE;
      setServerError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form className='SmsCodeForm' onSubmit={handleSubmit}>
      <div className='SmsCodeForm__description'>
        Check your SMS messages. We've sent you the code at{' '}
        <span>{formData?.phoneNumber}</span>
      </div>
      {serverError && (
        <div className='SmsCodeForm__errorMessage'>{serverError}</div>
      )}

      <div className='SmsCodeForm__inputs'>
        <ReactCodeInput
          className='SmsCodeForm__phoneNumberInput'
          name='code'
          inputMode='numeric'
          fields={6}
          isValid={values.code && !errors?.code}
          value={values.code}
          onChange={(code) => {
            setFieldValue('code', code);
          }}
        />
      </div>

      {formData?.phoneNumber && (
        <div className='SmsCodeForm__resendCode'>
          <span>Didn't receive code?</span>
          <Button
            className='SmsCodeForm__resendButton'
            variant='text'
            onClick={handleResendCode}
          >
            Request again
          </Button>
        </div>
      )}

      <div className='SmsCodeForm__buttons'>
        <Button
          className='SmsCodeForm__signInButton'
          size='full-width'
          type='submit'
          disabled={isLoading}
        >
          {isLoading ? (
            <Loader light fixed={false} showLogo={false} width='32px' />
          ) : (
            'Sign In'
          )}
        </Button>

        <div className='SmsCodeForm__createAccount'>
          Don't have an account?{' '}
          <Button
            className='SmsCodeForm__signUp'
            variant='text'
            onClick={handleSwitchSignUpForm}
          >
            Sign Up
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SmsCodeForm;
