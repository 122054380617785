import { makeAutoObservable } from 'mobx';
import { BannerViewModel, GigViewModel } from '../core/backend/models';
import ExperienceModel from '../core/models/ExperienceModel';
import { CategoryExperiences, IVideoModel } from '../core/types';

class DiscoveryStore {
  fetched: boolean = false;
  banners: BannerViewModel[] | undefined = [];
  nearbyEvents: ExperienceModel[] | undefined = [];
  thisWeekEvents: ExperienceModel[] | undefined = [];
  upcomingEvents: ExperienceModel[] | undefined = [];
  whatsTrendingEvents: ExperienceModel[] | undefined = [];
  popularCategoryEvents: CategoryExperiences[] | undefined = [];
  randomEvents: ExperienceModel[] | undefined = [];
  clips: IVideoModel[] | undefined = [];

  constructor() {
    makeAutoObservable(this);
  }

  saveAll = (
    banners: BannerViewModel[] | undefined,
    nearbyEvents: ExperienceModel[] | undefined,
    thisWeekEvents: ExperienceModel[] | undefined,
    upcomingEvents: ExperienceModel[] | undefined,
    whatsTrendingEvents: ExperienceModel[] | undefined,
    popularCategoryEvents: CategoryExperiences[] | undefined,
    randomEvents: ExperienceModel[] | undefined,
    clips: IVideoModel[] | undefined
  ) => {
    this.banners = banners;
    this.nearbyEvents = nearbyEvents;
    this.thisWeekEvents = thisWeekEvents;
    this.upcomingEvents = upcomingEvents;
    this.whatsTrendingEvents = whatsTrendingEvents;
    this.popularCategoryEvents = popularCategoryEvents;
    this.randomEvents = randomEvents;
    this.clips = clips;
    this.fetched = true;
  };

  saveBanners = (banners: BannerViewModel[] | undefined) => {
    this.banners = banners;
  };

  saveNearbyEvents = (events: ExperienceModel[] | undefined) => {
    this.nearbyEvents = events;
  };

  saveThisWeekEvents = (events: ExperienceModel[] | undefined) => {
    this.thisWeekEvents = events;
  };

  saveUpcomingEvents = (events: ExperienceModel[] | undefined) => {
    this.upcomingEvents = events;
  };

  saveWhatsTrendingEvents = (events: ExperienceModel[] | undefined) => {
    this.whatsTrendingEvents = events;
  };

  savePopularCategoryEvents = (events: CategoryExperiences[] | undefined) => {
    this.popularCategoryEvents = events;
  };

  saveRandomEvents = (events: ExperienceModel[] | undefined) => {
    this.randomEvents = events;
  };

  saveClips = (clips: IVideoModel[] | undefined) => {
    this.clips = clips;
  };
}

export default DiscoveryStore;
