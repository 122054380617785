import { makeAutoObservable } from 'mobx';
import { ICoordinates } from '../core/types';

class LocationStore {
  coords: ICoordinates;
  address: string;

  constructor() {
    makeAutoObservable(this);
  }

  saveLocation = (address: string, coords: ICoordinates) => {
    this.address = address;
    this.coords = coords;
  };

  updateCoords = (coords: ICoordinates) => {
    this.coords = coords;
  };
}

export default LocationStore;
