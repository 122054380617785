import classNames from 'classnames';
import dayjs from 'dayjs';
import { getMediaFile } from '../../core/api';
import { useStores } from '../../hooks';
import Avatar from '../Avatar/Avatar';
import './VideoMessage.scss';
import { VideoMessageModel } from '../../core/backend/models/video-message-model';
import { ReactComponent as HooplaLogo } from '../../images/logo_sm.svg';

interface IProps {
  className?: string;
  isOwnMessage: boolean;
  video: VideoMessageModel;
  sendOn: number;
  onClick: (mediaFileId: number) => void;
}

const VideoMessage = ({
  isOwnMessage,
  video,
  sendOn,
  className = '',
  onClick
}: IProps) => {
  const date = new Date(sendOn);
  const letter = video.UserName ? video.UserName[0] : video.FullName[0];

  const handleClick = () => {
    onClick(video.MediaFileId);
  };

  return (
    <div
      className={classNames(`VideoMessage ${className}`, {
        'VideoMessage--own': isOwnMessage
      })}
      onClick={handleClick}
    >
      <div className='VideoMessage__bubble'>
        {video.AwsUrl ? (
          <img
            className='VideoMessage__image'
            src={getMediaFile(video.AwsUrl)}
          />
        ) : (
          <div className='VideoMessage__placeholder'>
            <HooplaLogo />
          </div>
        )}
        <div className='VideoMessage__content'>
          <div className='VideoMessage__author'>
            <Avatar
              className='VideoMessage__userImage'
              size='xs'
              image={
                video.Image && (
                  <img src={getMediaFile(video.Image)} alt={video.FullName} />
                )
              }
              symbol={letter}
            />
            <div className='VideoMessage__userName oneLineText'>
              {video.UserName}
            </div>
          </div>
          <div className='VideoMessage__title twoLineText'>
            {video.VideoName}
          </div>
          <div className='VideoMessage__time'>
            {dayjs(date).format('h:mma')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoMessage;
