import Button from '../Button/Button';
import { ReactComponent as LikeIcon } from '../../icons/likebutton.svg';
import { ReactComponent as LikedIcon } from '../../icons/likedbutton.svg';
import { ReactComponent as CommentsIcon } from '../../icons/commentsbutton.svg';
import { ReactComponent as ShareIcon } from '../../icons/sharebutton.svg';
import { ReactComponent as DotsIcon } from '../../icons/dots.svg';
import { ReactComponent as ReportIcon } from '../../icons/report.svg';
import { ReactComponent as VolumeIcon } from '../../icons/volume_icon.svg';
import { ReactComponent as MuteIcon } from '../../icons/mute_icon.svg';
import './VideoActions.scss';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import { ListItemProps } from '../Dropdown/Dropdown';
import Loader from '../Loader/Loader';
import { Direction, Range } from 'react-range';

interface Props {
  className?: string;
  displayVolumeControls?: boolean;
  volume: number[];
  isLiked: boolean;
  isLiking: boolean;
  likesCount: number;
  commentsCount: number;
  isMute: boolean;
  onLikeVideo: () => void;
  onShowComments: () => void;
  onShareContent: () => void;
  onReportMediaFile: () => void;
  onVolumeChange: (volume: number[]) => void;
  onToggleMute: () => void;
}

const VideoActions = ({
  className = '',
  displayVolumeControls = false,
  volume,
  isLiked,
  isLiking,
  likesCount,
  commentsCount,
  isMute,
  onLikeVideo,
  onShareContent,
  onShowComments,
  onReportMediaFile,
  onVolumeChange,
  onToggleMute
}: Props) => {
  const options: ListItemProps[] = [
    {
      name: `Report Media`,
      callback: onReportMediaFile,
      icon: <ReportIcon />
    }
  ];

  return (
    <div className={`VideoActions__actions ${className}`}>
      <div className='VideoActions__actionButtonWrapper'>
        <Button
          className='VideoActions__actionButton'
          variant='icon'
          icon={
            isLiking ? (
              <Loader width='20px' fixed={false} showLogo={false} light />
            ) : isLiked ? (
              <LikedIcon />
            ) : (
              <LikeIcon />
            )
          }
          onClick={onLikeVideo}
          disabled={isLiking}
        />
        <span className='VideoActions__actionButtonCounter'>{likesCount}</span>
      </div>

      <div className='VideoActions__actionButtonWrapper'>
        <Button
          className='VideoActions__actionButton'
          variant='icon'
          icon={<CommentsIcon />}
          onClick={onShowComments}
        />
        <span className='VideoActions__actionButtonCounter'>
          {commentsCount}
        </span>
      </div>

      <div className='VideoActions__actionButtonWrapper'>
        <Button
          className='VideoActions__actionButton'
          variant='icon'
          icon={<ShareIcon />}
          onClick={onShareContent}
        />
      </div>

      <DropdownMenu list={options} align='left'>
        <div className='VideoActions__actionButtonWrapper'>
          <Button
            className='VideoActions__actionButton'
            variant='icon'
            icon={<DotsIcon />}
          />
        </div>
      </DropdownMenu>

      {displayVolumeControls && (
        <div className='VideoActions__actionButtonWrapper VideoActions__volumeControls'>
          <div className='VideoActions__volumeRange'>
            <Range
              direction={Direction.Up}
              rtl={false}
              step={0.01}
              min={0}
              max={1}
              values={volume}
              onChange={onVolumeChange}
              renderTrack={({ props, children }) => (
                <div
                  className='VideoActions__volumeTrack'
                  {...props}
                  style={{
                    ...props.style,
                    flexShrink: 0,
                    width: '3px',
                    display: 'flex',
                    height: '80px',
                    backgroundColor: '#ccc',
                    borderRadius: '3px'
                  }}
                >
                  {children}
                </div>
              )}
              renderThumb={({ props }) => {
                const { key, ...restProps } = props;
                return (
                  <div
                    className='VideoActions__volumeThumb'
                    key={key}
                    {...restProps}
                    style={{
                      ...props.style,
                      height: '12px',
                      width: '12px',
                      backgroundColor: '#999',
                      borderRadius: '50%'
                    }}
                  />
                );
              }}
            />
          </div>
          <Button
            className='VideoActions__actionButton'
            variant='icon'
            icon={isMute ? <MuteIcon /> : <VolumeIcon />}
            onClick={onToggleMute}
          />
        </div>
      )}
    </div>
  );
};

export default VideoActions;
