import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal, { IModalProps } from '../Modal';
import { ReactComponent as HooplaIcon } from '../../../images/hooplaicon.svg';
import Button from '../../Button/Button';
import { ReactComponent as CrossIcon } from '../../../icons/cross.svg';
import SignInForm from './Forms/SignInForm/SignInForm';
import SignUpForm from './Forms/SignUpForm/SignUpForm';
import './AuthModal.scss';
import CompleteProfileForm from './Forms/CompleteProfileForm/CompleteProfileForm';
import UsernameForm from './Forms/UsernameForm/UsernameForm';
import DownloadWidget from '../../DownloadWidget/DownloadWidget';
import ResetPasswordForm from './Forms/ResetPasswordForm/ResetPasswordForm';
import PhoneNumberForm from './Forms/PhoneNumberForm/PhoneNumberForm';
import SmsCodeForm from './Forms/SmsCodeForm/SmsCodeForm';

type AuthModalType =
  | 'sign_in'
  | 'sign_up'
  | 'complete_profile'
  | 'username'
  | 'reset_password'
  | 'phone_number'
  | 'sms_code';

export interface AuthModalContextProps {
  isOpen: boolean;
  selectedForm: AuthModalType;
  title?: string;
  description?: string;
  formData?: any;
  changeAuthModalTitles: (title?: string, description?: string) => void;
  showAuthModal(form?: AuthModalType, formData?: any): AuthModalContextProps;
  closeAuthModal(): AuthModalContextProps;
}

const initialContextValues: AuthModalContextProps = {
  isOpen: false,
  selectedForm: 'sign_in',
  changeAuthModalTitles: () => {},
  showAuthModal() {
    return this;
  },
  closeAuthModal() {
    return this;
  }
};

export const AuthModalContext =
  createContext<AuthModalContextProps>(initialContextValues);

interface IFormStateValues {
  isOpen: boolean;
  title: string;
  description: string;
  selectedForm: AuthModalType;
  formData?: any;
}

interface AuthForms {
  [key: string]: {
    title: string;
    description?: string;
    form: React.ReactNode;
  };
}

const authForms: AuthForms = {
  sign_in: {
    title: 'Sign In to Hoopla',
    form: <SignInForm />
  },
  phone_number: {
    title: 'Sign In with Phone Number',
    form: <PhoneNumberForm />
  },
  sms_code: {
    title: 'Verify Phone Number',
    form: <SmsCodeForm />
  },
  sign_up: {
    title: 'Create your account',
    form: <SignUpForm />
  },
  complete_profile: {
    title: 'Complete your account',
    form: <CompleteProfileForm />
  },
  username: {
    title: 'Username Taken',
    form: <UsernameForm />
  },
  reset_password: {
    title: 'Reset Password',
    form: <ResetPasswordForm />
  }
};

const AuthModal = () => {
  const context = useContext<AuthModalContextProps>(AuthModalContext);
  const form = authForms[context.selectedForm];

  return (
    <Modal
      isOpened={context.isOpen}
      close={context.closeAuthModal}
      showCloseButton={false}
      full={false}
    >
      <div className='AuthModal'>
        <div className='AuthModal__header'>
          <div className='AuthModal__logo'>
            <HooplaIcon />
          </div>
          <div className='AuthModal__title'>
            {context.title || form.title}
            <Button
              className='AuthModal__close'
              variant='icon'
              onClick={context.closeAuthModal}
            >
              <CrossIcon className='AuthModal__closeIcon' />
            </Button>
          </div>

          {(context.description || form.description) && (
            <div className='AuthModal__description'>
              {context.description || form.description}
            </div>
          )}
        </div>
        <div className='AuthModal__content'>{form.form}</div>
        {(context.selectedForm === 'sign_in' ||
          context.selectedForm === 'sign_up') && (
          <div className='AuthModal__storeButtons'>
            <DownloadWidget section='sign_form' />
          </div>
        )}
      </div>
    </Modal>
  );
};

export const AuthModalContainer = ({ children }: PropsWithChildren<{}>) => {
  const [form, setForm] = useState<IFormStateValues>({
    isOpen: false,
    title: null,
    description: null,
    formData: null,
    selectedForm: 'sign_in'
  });

  const authModalContext: AuthModalContextProps = {
    ...form,
    changeAuthModalTitles: (title: string, description: string) => {
      setForm({
        ...form,
        title: title ?? null,
        description: description ?? null
      });
    },
    showAuthModal(form: AuthModalType = 'sign_in', formData?: any) {
      setForm({
        title: null,
        description: null,
        isOpen: true,
        selectedForm: form,
        formData
      });

      return this;
    },
    closeAuthModal() {
      setForm({
        title: null,
        description: null,
        isOpen: false,
        selectedForm: 'sign_in',
        formData: null
      });

      return this;
    }
  };

  return (
    <AuthModalContext.Provider value={authModalContext}>
      <AuthModal />
      {children}
    </AuthModalContext.Provider>
  );
};

export const useAuthModal = () => {
  return useContext(AuthModalContext);
};
