type MetaEvents =
  | 'AddPaymentInfo'
  | 'AddToCart'
  | 'AddToWishlist'
  | 'CompleteRegistration'
  | 'Contact'
  | 'CustomizeProduct'
  | 'Donate'
  | 'FindLocation'
  | 'InitiateCheckout'
  | 'Lead'
  | 'Purchase'
  | 'Schedule'
  | 'Search'
  | 'StartTrial'
  | 'SubmitApplication'
  | 'Subscribe'
  | 'ViewContent';

interface MetaPixelParams {
  content_category?: string;
  content_ids?: string[];
  content_name?: string;
  content_type?: 'product' | 'product_group';
  contents?: ContentParams[];
  currency?: 'USD';
  num_items?: number;
  predicted_ltv?: number;
  search_string?: string;
  status?: boolean;
  value?: number;
}

interface ContentParams {
  id: string;
  quantity: number;
}

const MetaPixel = {
  fbq: function () {
    return (window as any).fbq;
  },

  init: function (pixelId: string) {
    const pixelScript = this.createPixelScript(pixelId);
    const pixelNoScript = this.createNoScript(pixelId);

    document.head.insertBefore(pixelNoScript, document.head.childNodes[0]);
    document.head.insertBefore(pixelScript, document.head.childNodes[0]);
  },

  track: function (event: MetaEvents, params?: MetaPixelParams) {
    this.fbq()('track', event, params);
  },

  createPixelScript: function (pixelId: string) {
    const scriptInnerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', ${pixelId});
      fbq('track', 'PageView');
    `;

    const script = document.createElement('script');
    script.innerHTML = scriptInnerHTML;

    return script;
  },

  createNoScript: function (pixelId: string) {
    const scriptInnerHTML = `
    <img height="1" width="1" style="display:none" 
       src="https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1"/>
  `;

    const script = document.createElement('noscript');
    script.innerHTML = scriptInnerHTML;

    return script;
  }
};

export default MetaPixel;
