import { PropsWithChildren, createContext, useEffect, useState } from 'react';
import ExperienceModal from '../Modal/ExperienceModal/ExperienceModal';
import { IVideoModel, PlayerState } from '../../core/types';
import VideoModal from '../Modal/VideoModal/VideoModal';
import { useLocation } from 'react-router-dom';
import ExperienceModel from '../../core/models/ExperienceModel';
import { iOS } from '../../core/helpers';

export interface IExperienceModal extends IExperienceModalProps {
  openExperienceModal?: (
    modalProps: Omit<IExperienceModalProps, 'isOpen'>
  ) => void;
  updateExperienceModalProps?: (modalProps: IExperienceModalProps) => void;
  closeExperienceModal?: () => void;
}

export interface IVideoModal extends IVideoModalProps {
  openVideoModal?: (modalProps: Omit<IVideoModalProps, 'isOpen'>) => void;
  updateVideoModalProps?: (modalProps: IVideoModalProps) => void;
  closeVideoModal?: () => void;
}

export interface IExperienceModalProps {
  isOpen?: boolean;
  section?: string;
  isPreviewing?: boolean;
  forceToBookInMobile?: boolean;
  canGetMoreContent?: boolean;
  showMediaControls?: boolean;
  media?: ExperienceModel[];
  startAtIndex?: number;
  currentMediaIndex?: number;
  mediaState?: PlayerState;
  afterReporting?: (mediaFileId: number) => void;
  afterClosing?: () => void;
}

export interface IVideoModalProps {
  isOpen?: boolean;
  section?: string;
  canGetMoreContent?: boolean;
  showMediaControls?: boolean;
  media?: IVideoModel[];
  startAtIndex?: number;
  mediaState?: PlayerState;
  afterReporting?: (mediaFileId: number) => void;
  afterClosing?: () => void;
}

export const ExperienceModalContext = createContext<IExperienceModal>({});
export const VideoModalContext = createContext<IVideoModal>({});

const experienceModalDefaultProps: IExperienceModalProps = {
  isOpen: false,
  section: null,
  isPreviewing: false,
  forceToBookInMobile: false,
  canGetMoreContent: false,
  showMediaControls: true,
  media: [],
  startAtIndex: -1,
  currentMediaIndex: 0,
  mediaState: 'idle',
  afterReporting: null,
  afterClosing: null
};

const videoModalDefaultProps: IVideoModalProps = {
  isOpen: false,
  section: null,
  canGetMoreContent: false,
  showMediaControls: true,
  media: [],
  startAtIndex: -1,
  mediaState: 'idle',
  afterReporting: null,
  afterClosing: null
};

export const MediaModalsContextProvider = ({
  children
}: PropsWithChildren<{}>) => {
  const { pathname } = useLocation();
  const [experienceModalState, setExperienceModalState] =
    useState<IExperienceModalProps>(experienceModalDefaultProps);
  const [videoModalState, setVideoModalState] = useState<IVideoModalProps>(
    videoModalDefaultProps
  );
  const [currentModalPath, setCurrentModalPath] = useState(pathname);

  /**
   * This closes the modal whenever the user tries to navigate to
   * another page when modal is open
   */
  useEffect(() => {
    if (pathname === currentModalPath) return;

    if (experienceModalState.isOpen) {
      setExperienceModalState(experienceModalDefaultProps);
      experienceModalState?.afterClosing && experienceModalState.afterClosing();
    }

    if (videoModalState.isOpen) {
      setVideoModalState(videoModalDefaultProps);
      videoModalState?.afterClosing && videoModalState.afterClosing();
    }

    setCurrentModalPath(pathname);
  }, [
    experienceModalState.isOpen,
    videoModalState.isOpen,
    pathname,
    currentModalPath
  ]);

  const experienceModalContext: IExperienceModal = {
    ...experienceModalState,
    openExperienceModal: (modalProps) => {
      setExperienceModalState({
        ...experienceModalState,
        ...modalProps,
        isOpen: true,
        currentMediaIndex:
          modalProps.startAtIndex > -1 ? modalProps.startAtIndex : 0,
        mediaState: iOS() ? 'idle' : modalProps.mediaState ?? 'playing' //Fix to avoid auto play issues on iOS
      });
    },
    updateExperienceModalProps: (modalProps) => {
      setExperienceModalState({ ...experienceModalState, ...modalProps });
    },
    closeExperienceModal: () => {
      setExperienceModalState(experienceModalDefaultProps);
      experienceModalState?.afterClosing && experienceModalState.afterClosing();
    }
  };

  const videoModalContext: IVideoModal = {
    ...videoModalState,
    openVideoModal: (modalProps) => {
      setVideoModalState({
        ...videoModalState,
        ...modalProps,
        isOpen: true,
        mediaState: iOS() ? 'idle' : modalProps.mediaState ?? 'playing' //Fix to avoid auto play issues on iOS
      });
    },
    updateVideoModalProps: (modalProps) => {
      setVideoModalState({ ...videoModalState, ...modalProps });
    },
    closeVideoModal: () => {
      setVideoModalState(videoModalDefaultProps);

      videoModalState?.afterClosing && videoModalState.afterClosing();
    }
  };

  return (
    <ExperienceModalContext.Provider value={experienceModalContext}>
      <VideoModalContext.Provider value={videoModalContext}>
        <ExperienceModal />
        <VideoModal />
        {children}
      </VideoModalContext.Provider>
    </ExperienceModalContext.Provider>
  );
};
