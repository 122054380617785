import {
  useState,
  useEffect,
  KeyboardEvent,
  useRef,
  useCallback,
  useMemo
} from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { observer } from 'mobx-react-lite';
import {
  getGigById,
  getOwnLikes,
  getRelatedMemories,
  getVideoComments,
  likeVideo
} from '../../../core/api';
import { RoutesEnum, TrackingEvents } from '../../../core/enums';
import { isMobileDevice, removeMentionsInText } from '../../../core/helpers';
import MixpanelTracking from '../../../core/services/MixpanelTracking';
import { IVideoModel, IVolume, MediaFile } from '../../../core/types';
import SEO from '../../SEO/SEO';
import Modal from '../Modal';
import { ReactComponent as DownArrow } from '../../../icons/downarrow.svg';
import './ExperienceModal.scss';
import Button from '../../Button/Button';
import classNames from 'classnames';
import CommentsModal from '../CommentsModal/CommentsModal';
import VideoActions from '../../VideoActions/VideoActions';
import { useStores } from '../../../hooks';
import { DEFAULT_ERROR_MESSAGE } from '../../../core/validators';
import appToast from '../../../core/toast';
import { useAuthModal } from '../AuthModal/AuthModal';
import ExperienceDescription from '../../ExperienceDescription/ExperienceDescription';
import { useNavigate, useLocation } from 'react-router-dom';
import VideoModel from '../../../core/models/VideoModel';
import MediaPlayer from '../../MediaPlayer/MediaPlayer';
import { useExperienceModal } from './useExperienceModal';
import { useShareModal } from '../ShareContentModal/useShareModal';
import { useVideoModal } from '../VideoModal/useVideoModal';
import { COMMENTS_PER_PAGE } from '../../../core/consts';
import ReportMediaModal from '../ReportMediaModal/ReportMediaModal';
import {
  AttendeeViewModel,
  UserTimeAvailabilityInput
} from '../../../core/backend/models';
import EventDetails from '../../Details/EventDetails/EventDetails';
import ExperienceDetails from '../../Details/ExperienceDetails/ExperienceDetails';
import GoogleTagManager from '../../../core/services/GoogleTagManager';
import TikTokPixel from '../../../core/services/TikTokPixel';
import MetaPixel from '../../../core/services/MetaPixel';
import { InvitationMethod } from '../../../core/backend/enums';
import converters from '../../../core/converters';

interface EventData {
  availability?: UserTimeAvailabilityInput;
  attendees: AttendeeViewModel[];
  hashtags: string[];
  bookedTickets: number;
  requiresInvitationCode: boolean;
  clips: IVideoModel[];
}

const ExperienceModal = () => {
  const { showAuthModal } = useAuthModal();
  const {
    startAtIndex,
    currentMediaIndex,
    media,
    isOpen,
    isPreviewing,
    canGetMoreContent,
    showMediaControls,
    mediaState,
    updateExperienceModalProps,
    closeExperienceModal
  } = useExperienceModal();
  const videoModal = useVideoModal();
  const { showShareModal } = useShareModal();
  const { likedMedia, userStore, commentStore } = useStores();
  //const [isGettingMoreContent, setIsGettingMoreContent] = useState(false);
  const [eventData, setEventData] = useState<EventData>({
    availability: null,
    attendees: [],
    hashtags: [],
    bookedTickets: 0,
    requiresInvitationCode: false,
    clips: []
  });
  const [isLoadingClips, setIsLoadingClips] = useState(false);
  const [isLikingClip, setIsLikingClip] = useState(false);
  const [currentMediaFile, setCurrentMediaFile] = useState<MediaFile>(null);
  const [mediaGroupId, setMediaGroupId] = useState<number>(null);
  const [isShowingComments, setIsShowingComments] = useState(false);
  const [overLapDetails, setOverLapDetails] = useState(false);
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [volume, setVolume] = useState<IVolume>({
    isMute: false,
    displayControls: false,
    volume: [0.5],
    realVolume: [0.5]
  });
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    return resetModalData;
  }, [isOpen]);

  useEffect(() => {
    updateExperienceModalProps({
      mediaState: videoModal.isOpen ? 'paused' : 'playing'
    });

    setOverLapDetails(isMobileDevice());
  }, [videoModal.isOpen]);

  useEffect(() => {
    if (!isOpen || currentMediaIndex < 0 || media.length === 0) return;
    loadModalContent();
  }, [isOpen, currentMediaIndex]);

  const loadModalContent = async () => {
    try {
      const currentGig = media[currentMediaIndex];

      if (!currentGig) return;

      if (isPreviewing) {
        return setEventData({
          ...eventData,
          bookedTickets: 0,
          attendees: currentGig.attendees,
          hashtags: currentGig.hashtags,
          availability: currentGig.creatorAvailability,
          requiresInvitationCode:
            currentGig.additionalData?.invitationMethod ===
            InvitationMethod.InvitationCode
        });
      }

      getGigById(currentGig.id).then((experience) => {
        setEventData({
          ...eventData,
          bookedTickets: experience.totalAttending,
          attendees: experience.attendees,
          hashtags: experience.gigHashtags,
          availability: experience.userAvailability,
          requiresInvitationCode:
            experience.additionalData?.invitationMethod ===
            InvitationMethod.InvitationCode
        });
      });

      getRelatedClips(currentGig.id);

      MixpanelTracking.instance().track(TrackingEvents.ViewGig, {
        'Gig ID': currentGig?.id,
        'Gig Name': currentGig?.name,
        Category: currentGig?.category?.name
      });

      GoogleTagManager.dataLayer({
        event: currentGig?.isOneTimeEvent
          ? TrackingEvents.ViewEvent
          : TrackingEvents.ViewExperience,
        userType: userStore.user ? 'Authenticated' : 'Guest'
      });

      MetaPixel.track('ViewContent', {
        content_name: currentGig?.name,
        content_type: 'product',
        content_ids: [currentGig?.id.toString()],
        content_category: currentGig?.isOneTimeEvent ? 'Event' : 'Experience',
        value: currentGig?.hasCustomTickets
          ? currentGig?.ticketsStartingPrice
          : currentGig?.price
      });

      TikTokPixel.track('ViewContent', {
        content_id: currentGig?.id.toString(),
        content_name: currentGig?.name,
        content_category: currentGig?.isOneTimeEvent ? 'Event' : 'Experience',
        value: currentGig?.hasCustomTickets
          ? currentGig?.ticketsStartingPrice
          : currentGig?.price,
        currency: 'USD'
      });
    } catch (e: any) {
      const error = e.response?.data?.description || DEFAULT_ERROR_MESSAGE;
      appToast.showError(error);
    }
  };

  const resetModalData = () => {
    setIsLoadingClips(false);
    setIsLikingClip(false);
    setIsShowingComments(false);
    setOverLapDetails(false);
    setReportModalOpen(false);
    setMediaGroupId(null);
    setCurrentMediaFile(null);
    setEventData({
      availability: null,
      attendees: [],
      hashtags: [],
      bookedTickets: 0,
      requiresInvitationCode: false,
      clips: []
    });
  };

  //TODO: Remove this func when comments are coming in the media files
  const getMediaComments = async (mediaFileId: number) => {
    try {
      const commentsResponse = await getVideoComments(
        mediaFileId,
        0,
        COMMENTS_PER_PAGE
      );
      const sortedComments = commentsResponse.sort(
        (a, b) => a.timeStamp - b.timeStamp
      );

      commentStore.saveToStore(sortedComments);
    } catch (e: any) {
      const error = e.response?.data?.description || DEFAULT_ERROR_MESSAGE;
      appToast.showError(error);
    }
  };

  const getMoreMediaContent = async () => {
    if (!canGetMoreContent) return;
  };

  const getRelatedClips = async (experienceId: number) => {
    try {
      setIsLoadingClips(true);
      const relatedMoments = await getRelatedMemories(experienceId, 0, 25);
      const videos = relatedMoments.map<IVideoModel>(
        (video) => new VideoModel(video)
      );

      setEventData({ ...eventData, clips: videos });
      setIsLoadingClips(false);
    } catch (e: any) {
      const error = e.response?.data?.description || DEFAULT_ERROR_MESSAGE;
      appToast.showError(error);
    }
  };

  const handlePrevVideo = (force = false) => {
    if (currentMediaIndex > 0) {
      setMediaGroupId(null);
      updateExperienceModalProps({ currentMediaIndex: currentMediaIndex - 1 });
    }
  };

  const handleNextVideo = (force = false) => {
    //Get more content
    // if (
    //   canGetMoreContent &&
    //   currentMediaIndex <= media.length - 6 &&
    //   !isGettingMoreContent
    // ) {
    //   getMoreMediaContent();
    // }

    if (currentMediaIndex < media.length - 1) {
      setMediaGroupId(null);
      updateExperienceModalProps({ currentMediaIndex: currentMediaIndex + 1 });
    }
  };

  const handleLikeMedia = (mediaId: number) => async () => {
    const isLiked = likedMedia.isMediaLiked(mediaId);

    try {
      if (!userStore.user) {
        showAuthModal();
        return;
      }

      setIsLikingClip(true);

      const mixpanelPayload = {
        'Moment ID': mediaId,
        'Moment Description':
          media[currentMediaIndex]?.video?.description ?? '',
        Category:
          media[currentMediaIndex]?.video?.linkedExperience?.category?.name ??
          'None'
      };

      MixpanelTracking.instance().track(
        isLiked ? TrackingEvents.UnlikeMoment : TrackingEvents.LikeMoment,
        mixpanelPayload
      );

      await likeVideo(mediaId);

      //TODO: Ask BE devs to return LikeViewModel in the previous request
      const userLikes = await getOwnLikes();
      likedMedia.saveToStore(userLikes);

      media[currentMediaIndex].likes += isLiked ? -1 : 1;
    } catch (e: any) {
      const error = e.response?.data?.description || DEFAULT_ERROR_MESSAGE;
      appToast.showError(error);
      likedMedia.remove(mediaId);
      media[currentMediaIndex].likes += isLiked ? -1 : 1;
    } finally {
      setIsLikingClip(false);
    }
  };

  const handleVolumeChange = (volumeValue: number[]) => {
    let isMute = false;
    if (volumeValue[0] === 0 && !volume.isMute) {
      isMute = true;
    } else if (volumeValue[0] > 0 && volume.isMute) {
      isMute = false;
    }

    setVolume({
      ...volume,
      isMute,
      volume: volumeValue,
      realVolume: volumeValue
    });
  };

  const handleToggleMute = () => {
    setVolume({
      ...volume,
      isMute: !volume.isMute,
      volume: volume.isMute ? volume.realVolume : [0]
    });
  };

  const removeMediaFileFromClip = (mediaFileId: number) => {
    media[currentMediaIndex].mediaFiles = media[
      currentMediaIndex
    ].mediaFiles.filter((mediaFile) => mediaFile.id !== mediaFileId);
  };

  const handleShowComments = () => {
    if (!userStore.user) {
      showAuthModal();
      return;
    }

    setIsShowingComments(true);
  };

  const handleCloseComments = () => {
    setIsShowingComments(false);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'ArrowUp') {
      handlePrevVideo();
    }

    if (e.key === 'ArrowDown') {
      handleNextVideo();
    }
  };

  const handleShowExperienceDetails = () => {
    setOverLapDetails(true);
  };

  const handleCloseExperienceDetails = useCallback(() => {
    if (!overLapDetails) return;

    setOverLapDetails(false);
  }, [overLapDetails]);

  const handleShowShareModal = (experienceId: number) => () => {
    experienceId &&
      showShareModal({ contentId: experienceId, contentType: 'experience' });
  };

  const handleShowReportMediaModal = () => {
    setReportModalOpen(true);
  };

  const handleCloseReportModal = () => {
    setReportModalOpen(false);
  };

  const handleResetModal = () => {
    setMediaGroupId(null);
  };

  const handleOnClose = () => {
    if (pathname.startsWith('/experience/')) navigate(RoutesEnum.Root);
    handleResetModal();
    closeExperienceModal();
  };

  const event = useMemo(
    () => media[currentMediaIndex],
    [media, currentMediaIndex]
  );

  let title = '',
    description = '';

  if (event) {
    title = removeMentionsInText(event.name);
    description = converters.md2PlainText(event.description);
    description = description.replace(/\n/g, ' ');
    description = removeMentionsInText(
      description.slice(0, 160) ?? 'Check this amazing experience at hoopla.com'
    );

    if (event.isInPerson) {
      // title += ` | ${event.location.city}`;
      description += ` in ${event.location.city}`;
    }
  }

  return event?.mediaFiles ? (
    <>
      <SEO
        title={title}
        description={description}
        keywords={event?.hashtags?.join(', ')}
        url={`/experience/${event?.id}`}
        image={event?.thumbnails?.static}
        video={event?.thumbnails?.video}
      />

      <Modal
        isOpened={isOpen}
        close={closeExperienceModal ? handleOnClose : null}
        onKeyDown={handleKeyDown}
        closeButtonClassName={`ExperienceModal__closeButton ${
          overLapDetails ? 'ExperienceModal__closeButton--hidden' : ''
        }`}
      >
        <div className='ExperienceModal'>
          <div className='ExperienceModal__content'>
            <MediaPlayer
              autoPlayVideos={!overLapDetails && mediaState === 'playing'}
              forceToStopMedia={reportModalOpen}
              isMute={volume.isMute}
              volume={volume.volume[0]}
              files={event?.mediaFiles}
              thumbnail={event?.thumbnails}
              addVideoView={!isPreviewing}
              embed={
                <ExperienceDescription
                  className='ExperienceModal__experienceDescription'
                  experience={event}
                  onViewExperience={handleShowExperienceDetails}
                />
              }
              onChangeMediaFile={(selectedFile) => {
                setCurrentMediaFile(selectedFile);

                setVolume({
                  ...volume,
                  displayControls: selectedFile.isVideo
                });

                if (mediaGroupId) return;

                setMediaGroupId(selectedFile.id); //Set first ID as group id
                userStore.user &&
                  !isPreviewing &&
                  getMediaComments(selectedFile.id);
              }}
              afterViewIsAdded={() => {
                event.videoViews += 1;
              }}
            />

            {!isPreviewing && (
              <VideoActions
                className='ExperienceModal__actions'
                isLiked={likedMedia.isMediaLiked(mediaGroupId ?? 0)}
                isLiking={isLikingClip}
                likesCount={event?.likes ?? 0}
                volume={volume.volume}
                isMute={volume.isMute}
                displayVolumeControls={volume.displayControls}
                commentsCount={commentStore.comments.length}
                onLikeVideo={handleLikeMedia(mediaGroupId ?? 0)}
                onShowComments={handleShowComments}
                onShareContent={handleShowShareModal(event?.id ?? 0)}
                onReportMediaFile={handleShowReportMediaModal}
                onVolumeChange={handleVolumeChange}
                onToggleMute={handleToggleMute}
              />
            )}

            {showMediaControls && (
              <div className='ExperienceModal__mediaControls'>
                <Button
                  className='ExperienceModal__actionButton ExperienceModal__upButton'
                  variant='icon'
                  icon={
                    <DownArrow className='ExperienceModal__actionButton--up' />
                  }
                  onClick={handlePrevVideo}
                  disabled={currentMediaIndex < 1}
                />

                <Button
                  className='ExperienceModal__actionButton'
                  variant='icon'
                  icon={<DownArrow />}
                  onClick={handleNextVideo}
                  disabled={currentMediaIndex >= media.length - 1}
                />
              </div>
            )}
          </div>

          <div
            className={classNames('ExperienceModal__sidebar', {
              'ExperienceModal__sidebar--overlap': overLapDetails
            })}
          >
            <div className='ExperienceModal__content-wrapper'>
              <Scrollbars autoHeight autoHeightMin={100} autoHeightMax='100%'>
                {event.isOneTimeEvent ? (
                  <EventDetails
                    isLoadingRelatedClips={isLoadingClips}
                    event={event}
                    requiresInvitationCode={eventData.requiresInvitationCode}
                    attendees={eventData.attendees}
                    hashtags={eventData.hashtags}
                    bookedTickets={eventData.bookedTickets}
                    relatedClips={eventData.clips}
                    onGoBack={
                      overLapDetails ? handleCloseExperienceDetails : null
                    }
                  />
                ) : (
                  <ExperienceDetails
                    isLoadingRelatedClips={isLoadingClips}
                    event={event}
                    attendees={eventData.attendees}
                    bookingCount={eventData.bookedTickets}
                    availability={eventData.availability}
                    relatedClips={eventData.clips}
                    onGoBack={
                      overLapDetails ? handleCloseExperienceDetails : null
                    }
                  />
                )}
              </Scrollbars>
            </div>
          </div>
        </div>
      </Modal>

      {!isPreviewing && mediaGroupId && isShowingComments && (
        <CommentsModal
          isOpened={isShowingComments}
          mediaFileId={mediaGroupId}
          close={handleCloseComments}
        />
      )}

      <ReportMediaModal
        isOpened={reportModalOpen}
        mediaFileId={currentMediaFile?.id}
        afterReporting={removeMediaFileFromClip}
        close={handleCloseReportModal}
      />
    </>
  ) : null;
};
export default observer(ExperienceModal);
