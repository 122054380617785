import { makeAutoObservable } from 'mobx';
import { makePersistable, clearPersistedStore } from 'mobx-persist-store';
import ExperienceModel from '../core/models/ExperienceModel';

class SingleEventStore {
  event: ExperienceModel = null;

  constructor() {
    makeAutoObservable(this);

    makePersistable(this, {
      name: 'SingleEventStore',
      properties: ['event'],
      storage: localStorage,
      expireIn: 1800000 // 30 minutes
    });
  }

  saveToStore = (event: ExperienceModel) => {
    this.event = event;
  };

  clearStoredEventData = async () => {
    await clearPersistedStore(this);
  };
}

export default SingleEventStore;
