import { FormikErrors, useFormik } from 'formik';
import { useState } from 'react';
import { getStripePublicKey, getUser, signIn } from '../../../../../core/api';
import { isEmail } from '../../../../../core/helpers';
import { DEFAULT_ERROR_MESSAGE } from '../../../../../core/validators';
import { useStores } from '../../../../../hooks';
import Button from '../../../../Button/Button';
import TextField from '../../../../TextField/TextField';
import { useAuthModal } from '../../AuthModal';
import appToast from '../../../../../core/toast';
import Loader from '../../../../Loader/Loader';
import './SignInForm.scss';
import { getAllUserData, getUserProfileData } from '../../AuthModal.helper';
import { useNavigate } from 'react-router-dom';
import SocialAuthentication from '../../../../SocialAuthentication/SocialAuthentication';
import {
  AUTH_SUCCESS_MSG,
  AUTH_UNKNOWN_REASON_MSG
} from '../../../../../core/consts';
import { UserViewModel } from '../../../../../core/backend/models';

interface FormValues {
  email: string;
  password: string;
}

const SignInForm = () => {
  const { userStore, stripeStore, bookingsStore, likedMedia, followUsers } =
    useStores();
  const { showAuthModal, closeAuthModal, formData } = useAuthModal();
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState<string>(null);
  const navigate = useNavigate();

  const { handleSubmit, handleChange, values, errors, handleBlur, touched } =
    useFormik<FormValues>({
      initialValues: { email: formData?.email ?? '', password: '' },
      validateOnChange: false,
      validateOnBlur: false,
      validate: (values) => {
        setServerError(null);
        const errors: FormikErrors<FormValues> = {};
        if (!values.password) {
          errors.password = 'Required';
        }

        if (!values.email) {
          errors.email = 'Required';
        }
        if (!isEmail.test(values.email)) {
          errors.email = 'Email is not valid';
        }

        return errors;
      },
      onSubmit: (values) => {
        const { email, password } = values;
        setIsLoading(true);
        authenticateUser(email, password);
      }
    });

  const getUserDataAndStore = async () => {
    const user = await getUser();
    userStore.saveUserToStore(user);

    getRequiredDataAsync(user);
    return user;
  };

  const getRequiredDataAsync = async (user: UserViewModel) => {
    const userIsCreatorPro = user.isCreatorPro || user.isProviderPro;
    const { events, clips, summary, followers } = await getUserProfileData(
      user.id,
      userIsCreatorPro
    );
    userStore.saveMediaToStore(events, clips);
    userStore.saveStatistics(summary);
    userStore.saveFollowers(followers);

    const {
      userBookings,
      creatorBookings,
      userLikes,
      following,
      paymentMethods
    } = await getAllUserData();

    if (!stripeStore.publicKey) {
      const stripePublicKey = await getStripePublicKey();
      stripeStore.setPublicKey(stripePublicKey.publishableKey);
    }

    userStore.saveUserToStore(user);
    bookingsStore.saveToStore(userBookings, creatorBookings);
    likedMedia.saveToStore(userLikes);
    followUsers.saveToStore(following);
    userStore.savePaymentMethods(paymentMethods);
  };

  const authenticateUser = async (emai: string, password: string) => {
    try {
      await signIn(emai, password);

      const user = await getUserDataAndStore();

      appToast.showSuccess(AUTH_SUCCESS_MSG);

      if (!user.registrationCompleted) {
        showAuthModal('complete_profile');
      } else {
        closeAuthModal();
      }
    } catch (e: any) {
      const error = e.response?.data?.description || DEFAULT_ERROR_MESSAGE;
      setServerError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSocialAuthError = (error?: any) => {
    setServerError(AUTH_UNKNOWN_REASON_MSG);
  };

  const handleSwitchSignUpForm = () => {
    showAuthModal('sign_up', formData);
  };

  const handleSwitchResetPasswordForm = () => {
    showAuthModal('reset_password', { email: values.email });
  };

  return (
    <form className='SignInForm' onSubmit={handleSubmit}>
      {!formData?.hideSocialButtons && (
        <SocialAuthentication
          type='signin_with'
          shape={'icon'}
          forceToAcceptTOS={false}
          hasAcceptedTOS={true}
          displayEmailButton={false}
          onSuccess={getUserDataAndStore}
          onError={handleSocialAuthError}
        />
      )}

      {serverError && (
        <div className='SignInForm__errorMessage'>{serverError}</div>
      )}

      <div className='SignInForm__inputs'>
        <TextField
          label='Email'
          name='email'
          id='email'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          error={errors.email}
          touched={touched.email}
        />
        <div>
          <TextField
            label='Password'
            type='password'
            name='password'
            id='password'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            error={errors.password}
            touched={touched.password}
          />
        </div>

        <div className='SignInForm__forgotPassword'>
          <Button
            className='SignInForm__forgotPasswordButton'
            type='button'
            variant='text'
            onClick={handleSwitchResetPasswordForm}
          >
            Forgot Password?
          </Button>
        </div>
      </div>

      <div className='SignInForm__buttons'>
        <Button
          className='SignInForm__signInButton'
          size='full-width'
          type='submit'
          disabled={isLoading}
        >
          {isLoading ? (
            <Loader light fixed={false} showLogo={false} width='32px' />
          ) : (
            'Log In'
          )}
        </Button>
        <Button
          className='SignInForm__guest'
          color='light'
          size='full-width'
          onClick={closeAuthModal}
        >
          Continue as a Guest
        </Button>
        <div className='SignInForm__createAccount'>
          Don't have an account?{' '}
          <Button
            className='SignInForm__signUp'
            variant='text'
            onClick={handleSwitchSignUpForm}
          >
            Sign Up
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SignInForm;
