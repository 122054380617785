import { Link } from 'react-router-dom';
import { getMediaFile } from '../../core/api';
import { UserViewModel } from '../../core/backend/models';
import './CreatorBadge.scss';

interface IProps {
  user: UserViewModel;
  className?: string;
}

const CreatorBadge = ({ user, className }: IProps) => {
  return (
    <Link className={`CreatorBadge ${className}`} to={`/u/${user.userName}`}>
      {user.image ? (
        <img
          className='CreatorBadge__image'
          src={getMediaFile(user.image)}
          alt={user.userName}
        />
      ) : (
        <span>{user.userName[0]}</span>
      )}
      <div className='CreatorBadge__name oneLineText'>@{user.userName}</div>
    </Link>
  );
};

export default CreatorBadge;
