import './ProBadge.scss';

interface IProps {
  className?: string;
}

const ProBadge = ({ className }: IProps) => {
  return <div className={`ProBadge ${className}`}>PRO</div>;
};

export default ProBadge;
