import { remark } from 'remark';
import html from 'remark-html';
import rehypeParse from 'rehype-parse';
import rehypeRemark from 'rehype-remark';
import remarkStringify from 'remark-stringify';
import strip from 'strip-markdown';

const converters = {
  md2html: (md: string) => {
    const content = remark().use(html).processSync(md);
    return String(content);
  },
  md2PlainText: (md: string) => {
    const content = remark().use(strip).processSync(md);
    return String(content);
  },
  html2md: (html: string) => {
    const content = remark()
      .use(rehypeParse, { emitParseErrors: true, duplicateAttribute: false })
      .use(rehypeRemark)
      .use(remarkStringify, {
        emphasis: '_'
      })
      .processSync(html);
    return String(content);
  }
};

export default converters;
