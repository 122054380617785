import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDebounce } from './useDebounce';

export function useSearch(initialValue: string, delay = 300, minLength = 2) {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const params = new URLSearchParams(search);
  const searchValue = params.get('query');

  const [searchText, setSearchText] = useState<string>(
    searchValue || initialValue
  );
  const trimmed = searchText.trim();
  const debouncedSearchText = useDebounce(
    trimmed.length >= minLength ? trimmed : '',
    delay
  );

  useEffect(() => {
    const params = new URLSearchParams(search);
    if (params.get('query') !== debouncedSearchText) {
      if (!!debouncedSearchText) {
        params.set('query', debouncedSearchText);
      } else {
        params.delete('query');
      }
      navigate(pathname + '?' + params.toString(), { replace: true });
    }
  }, [navigate, pathname, search, debouncedSearchText]);

  const onSearchChange = (value: string) => {
    setSearchText(value);
  };

  return {
    debouncedSearchText,
    searchText,
    onSearchChange
  };
}
