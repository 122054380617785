import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  CSSProperties
} from 'react';
import { Placement } from './Tooltip';
import { createPortal } from 'react-dom';
import { useDebouncedCallback } from '../../hooks';
import { computePlacement, isPlacementFits } from './Tooltip.helpers';

interface Props {
  content: JSX.Element;
  placement?: Placement;
  withPortal?: boolean;
  parent: React.MutableRefObject<any>;
  style?: CSSProperties;
  onScroll: () => void;
}

const TooltipContent: React.FC<Props> = ({
  placement,
  content,
  parent,
  withPortal = false,
  style,
  onScroll
}) => {
  // const scroll = useContext(AppScrollbarsContext);

  const tooltipRef = useRef(null);

  const [autoPlacement, setAutoPlacement] =
    useState<Placement | undefined>(placement);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [isShown, setIsShown] = useState(false);

  const debouncedSetTooltipAutoPlacement = useDebouncedCallback(() => {
    setTooltipAutoPlacement();
  }, 100);

  const debouncedShow = useDebouncedCallback(() => {
    setIsShown(true);
  }, 50);

  const setTooltipAutoPlacement = useCallback(() => {
    if (!tooltipRef.current) return;
    const tooltipRect = tooltipRef.current.getBoundingClientRect();
    if (!isPlacementFits(tooltipRect)) {
      setAutoPlacement(computePlacement(tooltipRect));
    }
    debouncedShow();
  }, [debouncedShow]);

  const setPortalPosition = useCallback(
    (autoPlacement: Placement) => {
      if (!tooltipRef.current) return;
      if (!parent?.current) return;

      const pNode = parent?.current?.getBoundingClientRect();
      const tipNode = tooltipRef.current.getBoundingClientRect();

      switch (autoPlacement) {
        case 'top':
          setTop(pNode.top - tipNode.height - 8);
          setLeft(pNode.left + pNode.width / 2 - tipNode.width / 2);
          break;

        case 'left':
          setTop(pNode.top + pNode.height / 2 - tipNode.height / 2);
          setLeft(pNode.left - tipNode.width - 16);
          break;

        case 'bottom':
          setTop(pNode.top + pNode.height + 8);
          setLeft(pNode.left + pNode.width / 2 - tipNode.width / 2);
          break;

        case 'right':
          setTop(pNode.top + pNode.height / 2 - tipNode.height / 2);
          setLeft(pNode.left + pNode.width + 16);
          break;

        default:
      }

      // debouncedShow();
    },
    [parent]
  );

  useEffect(() => {
    withPortal && setPortalPosition(autoPlacement);
  }, [autoPlacement, setPortalPosition, withPortal]);

  useEffect(() => {
    debouncedSetTooltipAutoPlacement();
  }, [top, left, debouncedSetTooltipAutoPlacement]);

  const resetPlacement = useCallback(() => {
    debouncedSetTooltipAutoPlacement();
  }, [debouncedSetTooltipAutoPlacement]);

  useEffect(() => {
    window.addEventListener('resize', resetPlacement);

    return () => {
      window.removeEventListener('resize', resetPlacement);
    };
  }, [resetPlacement]);

  if (withPortal) {
    return createPortal(
      <div
        className={`Tooltip__content Tooltip__content--portal ${
          isShown ? 'Tooltip__content--isShown' : ''
        }`}
        ref={tooltipRef}
        style={{
          ...style,
          top: top + window.pageYOffset,
          left
        }}
      >
        <span
          className={`Tooltip__arrow Tooltip__arrow_${autoPlacement}`}
        ></span>
        {content}
      </div>,
      document.getElementById('tooltips')
    );
  }
  return (
    <div
      className={`Tooltip__content Tooltip__content_${autoPlacement} ${
        isShown ? 'Tooltip__content--isShown' : ''
      }`}
      ref={tooltipRef}
      style={style}
    >
      <span className={`Tooltip__arrow Tooltip__arrow_${autoPlacement}`}></span>
      {content}
    </div>
  );
};

export default TooltipContent;
