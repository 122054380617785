import { FormikErrors, useFormik } from 'formik';
import { useState } from 'react';
import {
  getStripePublicKey,
  getUser,
  signIn,
  signUpUserWithEmailAndPassword
} from '../../../../../core/api';
import { TrackingEvents } from '../../../../../core/enums';
import { isEmail } from '../../../../../core/helpers';
import { DEFAULT_ERROR_MESSAGE } from '../../../../../core/validators';
import { useStores } from '../../../../../hooks';
import Button from '../../../../Button/Button';
import TextField from '../../../../TextField/TextField';
import './SignUpForm.scss';
import { useAuthModal } from '../../AuthModal';
import appToast from '../../../../../core/toast';
import Loader from '../../../../Loader/Loader';
import Checkbox from '../../../../Checkbox/Checkbox';
import { getAllUserData } from '../../AuthModal.helper';
import {
  ACCEPT_TOS_MSG,
  AUTH_CREATED_SUCCESS_MSG,
  AUTH_UNKNOWN_REASON_MSG
} from '../../../../../core/consts';
import SocialAuthentication from '../../../../SocialAuthentication/SocialAuthentication';
import GoogleTagManager from '../../../../../core/services/GoogleTagManager';
import TikTokPixel from '../../../../../core/services/TikTokPixel';
import MetaPixel from '../../../../../core/services/MetaPixel';

interface FormValues {
  email: string;
  password: string;
  phoneNumber: string;
}

const SignUpForm = () => {
  const { userStore, stripeStore, bookingsStore, likedMedia, followUsers } =
    useStores();
  const { showAuthModal, formData } = useAuthModal();
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState<string>(null);
  const [acceptedTOS, setAcceptedTOS] = useState(false);

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    handleBlur,
    touched,
    setFieldValue
  } = useFormik<FormValues>({
    initialValues: {
      email: formData?.email ?? '',
      password: '',
      phoneNumber: ''
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate: (values) => {
      setServerError(null);
      const errors: FormikErrors<FormValues> = {};
      const { email, password, phoneNumber } = values;

      if (!email) {
        errors.email = 'Required';
      }

      if (!isEmail.test(email)) {
        errors.email = 'Email is not valid';
      }

      if (!password) {
        errors.password = 'Required';
      } else if (password.length < 6) {
        errors.password = 'Minimum length of password should be 6 characters';
      }

      return errors;
    },
    onSubmit: (values) => {
      const { email, password, phoneNumber } = values;
      setIsLoading(true);
      createUserAccount(email, password);
    }
  });

  const getUserDataAndStore = async () => {
    const user = await getUser();
    const {
      userBookings,
      creatorBookings,
      userLikes,
      following,
      paymentMethods
    } = await getAllUserData();

    if (!stripeStore.publicKey) {
      const stripePublicKey = await getStripePublicKey();
      stripeStore.setPublicKey(stripePublicKey.publishableKey);
    }

    userStore.saveUserToStore(user);
    bookingsStore.saveToStore(userBookings, creatorBookings);
    likedMedia.saveToStore(userLikes);
    followUsers.saveToStore(following);
    userStore.savePaymentMethods(paymentMethods);

    return user;
  };

  const createUserAccount = async (emai: string, password: string) => {
    try {
      if (!acceptedTOS) {
        return setServerError(ACCEPT_TOS_MSG);
      }

      await signUpUserWithEmailAndPassword(emai, password);
      await signIn(emai, password);

      const user = await getUserDataAndStore();

      GoogleTagManager.dataLayer({
        event: TrackingEvents.SignUp
      });

      MetaPixel.track('CompleteRegistration', {
        content_name: user.fullName,
        content_type: 'product'
      });

      TikTokPixel.track('CompleteRegistration', {
        content_id: user?.id.toString(),
        content_name: user?.fullName
      });

      appToast.showSuccess(AUTH_CREATED_SUCCESS_MSG);

      showAuthModal('complete_profile');
    } catch (e: any) {
      const error = e.response?.data?.description || DEFAULT_ERROR_MESSAGE;
      setServerError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSocialAuthError = (error?: any) => {
    setServerError(AUTH_UNKNOWN_REASON_MSG);
  };

  const handleSwitchSignInForm = () => {
    showAuthModal('sign_in', formData);
  };

  return (
    <form className='SignUpForm' onSubmit={handleSubmit}>
      {!formData?.hideSocialButtons && (
        <SocialAuthentication
          type='signup_with'
          shape={'icon'}
          hasAcceptedTOS={acceptedTOS}
          displayEmailButton={false}
          onSuccess={getUserDataAndStore}
          onError={handleSocialAuthError}
        />
      )}

      {serverError && (
        <div className='SignUpForm__errorMessage'>{serverError}</div>
      )}

      <div className='SignUpForm__inputs'>
        <TextField
          label='Email'
          name='email'
          id='email'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          error={errors.email}
          touched={touched.email}
        />

        <div className='mb-2'>
          <TextField
            label='Password'
            type='password'
            name='password'
            id='password'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            error={errors.password}
            touched={touched.password}
          />
        </div>
      </div>

      <div className='SignUpForm__acceptTOS'>
        <Checkbox
          value={acceptedTOS}
          onChange={setAcceptedTOS}
          label={
            <div className='SignUpForm__acceptTOSLabel'>
              By signing up you are agreeing to the{' '}
              <a href='https://hoopla.com/terms-and-conditions' target='_blank'>
                Hoopla Terms of Service
              </a>
            </div>
          }
        />
      </div>

      <div className='SignUpForm__buttons'>
        <Button
          className='SignUpForm__signUpButton'
          size='full-width'
          type='submit'
          disabled={isLoading || !acceptedTOS}
        >
          {isLoading ? (
            <Loader light fixed={false} showLogo={false} width='32px' />
          ) : (
            'Sign Me Up'
          )}
        </Button>
        <div className='SignUpForm__createAccount'>
          Already have an account?{' '}
          <Button
            className='SignUpForm__logIn'
            variant='text'
            onClick={handleSwitchSignInForm}
          >
            Log in
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SignUpForm;
