import classNames from 'classnames';
import './MediaPager.scss';

interface Props {
  selectedIndex: number;
  currentPageProgress?: number;
  pages: number;
  className?: string;
  onPageClick: (selectedIndex: number) => void;
}

const MediaPager = ({
  selectedIndex,
  currentPageProgress,
  pages,
  className = '',
  onPageClick
}: Props) => {
  return (
    <div className={`MediaPager d-flex align-items-end ${className}`}>
      {[...Array(pages)].map((_, idx) => (
        <div
          className='MediaPager__item'
          key={idx}
          onClick={() => onPageClick(idx)}
        >
          <div
            className={classNames('MediaPager__figure', {
              'MediaPager__figure--current': idx === selectedIndex
            })}
            key={idx}
          />
          {selectedIndex === idx && currentPageProgress !== null && (
            <div
              className='MediaPager__progress'
              style={{ width: `${currentPageProgress}%` }}
            ></div>
          )}
        </div>
      ))}
    </div>
  );
};

export default MediaPager;
