import ShowMoreText from 'react-show-more-text';
import { getMediaFile } from '../../core/api';
import { FileResponseViewModel } from '../../core/backend/models';
import ExperienceModel from '../../core/models/ExperienceModel';
import Avatar from '../Avatar/Avatar';
import Button from '../Button/Button';
import './ExperienceDescription.scss';
import { convertToCurrency } from '../../core/helpers';

interface IProps {
  className?: string;
  experience: ExperienceModel;
  onViewExperience?: () => void;
}

const ExperienceDescription = ({
  className = '',
  experience,
  onViewExperience
}: IProps) => {
  return (
    <div className={`ExperienceDescription ${className}`}>
      <div className='ExperienceDescription__authorDetails'>
        <Avatar
          key={experience.creator.id}
          className='ExperienceDescription__authorImage'
          size='small'
          image={
            experience.creator.image && (
              <img
                key={experience.creator.id}
                title={experience.creator.userName}
                alt={experience.creator.userName}
                src={getMediaFile(experience.creator.image)}
              />
            )
          }
          symbol={experience.creator.userName[0]}
        />

        <div className='ExperienceDescription__authorInfo'>
          <div className='ExperienceDescription__authorUsername oneLineText'>
            @{experience.creator.userName}
          </div>
          {experience.creator.company && (
            <div className='ExperienceDescription__authorCompany oneLineText'>
              {experience.creator.company}
            </div>
          )}
        </div>
      </div>

      <div className='ExperienceDescription__container'>
        <div className='ExperienceDescription__name'>{experience.name}</div>
        <div className='ExperienceDescription__price'>
          {experience.hasCustomTickets
            ? experience.ticketsStartingPrice === 0
              ? 'Free'
              : convertToCurrency(experience.ticketsStartingPrice)
            : experience.priceLabel}
        </div>
      </div>

      <div className='ExperienceDescription__viewExperience'>
        <Button size='auto' onClick={onViewExperience}>
          Book
        </Button>
      </div>
    </div>
  );
};

export default ExperienceDescription;
