import { makeAutoObservable } from 'mobx';
import { getMediaFile } from '../api';
import { ContentType } from '../backend/enums';
import { FileResponseViewModel } from '../backend/models';
import { IVideoModel, MediaFile, Thumbnails } from '../types';
import ExperienceModel from './ExperienceModel';

class VideoModel implements IVideoModel {
  _video: FileResponseViewModel = null;
  _experience: ExperienceModel = undefined;
  _thumbnails: Thumbnails = undefined;
  _mediaFiles: MediaFile[] = [];

  constructor(video: FileResponseViewModel) {
    makeAutoObservable(this);

    this._video = video;
    this._experience = video.linkedGig
      ? new ExperienceModel(video.linkedGig)
      : undefined;
    this._processFile();
  }

  _processFile() {
    const _thumbs = this._video.thumbnails;

    const videoTypes = this._video.mediaFileContentTypes ?? [];
    const isImageClip =
      videoTypes.includes(ContentType.GifImage) ||
      videoTypes.includes(ContentType.StoryImage) ||
      videoTypes.includes(ContentType.ReviewImage);

    const staticFiles: string[] = (_thumbs?.mobile as any)?.static.slice();
    const videoPreview = _thumbs?.mobile?.animated
      ?.slice()
      .find((file) => file.endsWith('.mp4'));

    const webFiles: string[] = _thumbs?.web.slice();

    this._thumbnails = {
      video: videoPreview
        ? getMediaFile(videoPreview)
        : _thumbs?.mobile?.animated[0]
        ? getMediaFile(_thumbs?.mobile?.animated[0])
        : getMediaFile(this._video.awsUrl),
      animated: isImageClip
        ? getMediaFile(this._video.awsUrl)
        : webFiles && webFiles[0]
        ? getMediaFile(webFiles[0])
        : null,
      static: isImageClip
        ? getMediaFile(this._video.awsUrl)
        : staticFiles && staticFiles[0]
        ? getMediaFile(staticFiles[0])
        : null
    };
  }

  get id() {
    return this._video.id;
  }

  get description() {
    return this._video.description;
  }

  get views() {
    return this._video.viewedTimes;
  }

  set views(count: number) {
    this._video.viewedTimes = count;
  }

  get likes() {
    return this._video.likes;
  }

  set likes(count: number) {
    this._video.likes = count;
  }

  get commentsCount() {
    return this._video.comments;
  }

  set commentsCount(count: number) {
    this._video.comments = count;
  }

  get owner() {
    return this._video.owner;
  }

  get mediaUrl() {
    return getMediaFile(this._video.awsUrl);
  }

  get mediaFiles(): Array<MediaFile> {
    return [{ id: this.id, isVideo: true, url: this.mediaUrl }];
  }

  set mediaFiles(mediaFiles: Array<MediaFile>) {
    this._mediaFiles = mediaFiles;
  }

  get thumbnails() {
    return this._thumbnails;
  }

  get linkedExperience() {
    return this._experience;
  }

  get isEventVideo() {
    return (
      this._video.mediaFileContentTypes.includes(ContentType.GigVideo) ||
      this._video.mediaFileContentTypes.includes(ContentType.GifImage)
    );
  }
}

export default VideoModel;
