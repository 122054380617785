import { CommentViewModelV21 } from '../../../core/backend/models';
import { CommentInput } from '../../CommentForm/CommentForm';
import Comment from '../Comment/Comment';
import './CommentList.scss';
interface IProps {
  className?: string;
  comments: CommentViewModelV21[];
  onLike?: (comment: CommentViewModelV21) => React.MouseEventHandler;
  onReply?: (replyToCommentId: number, comment: CommentInput) => Promise<any>;
}

const CommentList = ({ comments, className, onLike, onReply }: IProps) => {
  return (
    <div className={`CommentList ${className}`}>
      {comments.length > 0 &&
        comments.map((comment) => (
          <Comment
            key={`comment-${comment.id}`}
            className='CommentList__comment'
            comment={comment}
            onLike={onLike}
            onReply={onReply}
          />
        ))}
    </div>
  );
};

export default CommentList;
