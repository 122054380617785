import React, {
  useRef,
  ReactElement,
  useState,
  useEffect,
  useContext,
  useLayoutEffect
} from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import { useDebounce, useOnClickOutside } from '../../hooks';
import { Alignment } from '../Dropdown/Dropdown';

interface Props {
  children: ReactElement<any>;
  shown: boolean;
  triggerRef: React.MutableRefObject<HTMLElement>;
  align?: Alignment;
  onClose?: (e?: React.MouseEvent) => void;
  fullScreen?: boolean;
  indentX?: number;
  indentY?: number;
  className?: string;
  refreshPosition?: boolean;
  disableCloseOnScroll?: boolean;
}

const DropDownWithPortal: React.FC<Props> = ({
  children,
  shown,
  align = 'left',
  onClose,
  fullScreen = false,
  triggerRef,
  indentX = 0,
  indentY = 0,
  className,
  refreshPosition,
  disableCloseOnScroll
}) => {
  const dropdown = useRef(null);
  const [left, setLeft] = useState<number>(0);
  const [top, setTop] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);

  const handleOutsideClick = (e: React.MouseEvent) => {
    if (
      !triggerRef.current.contains(e.target as Node) &&
      shown &&
      !fullScreen
    ) {
      onClose();
    }
  };

  useOnClickOutside(dropdown, handleOutsideClick);

  // close on scroll
  // useEffect(() => {
  //   if (!disableCloseOnScroll) {
  //     const listener = () => {
  //       onClose();
  //     };
  //     const container = scroll.container
  //       ? scroll.container === document.body
  //         ? document
  //         : scroll.container
  //       : null;
  //     if (container && shown) {
  //       container.addEventListener('scroll', listener);
  //     }
  //     return () => {
  //       container.removeEventListener('scroll', listener);
  //     };
  //   }
  // }, [scroll, shown]);

  useLayoutEffect(() => {
    if (shown && !fullScreen && width) {
      const viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;
      const viewportWidth =
        window.innerWidth || document.documentElement.clientWidth;
      const boundingRect = triggerRef.current.getBoundingClientRect();
      const dropdownRect = dropdown.current.getBoundingClientRect();
      let left = boundingRect.left;
      let top = boundingRect.top + boundingRect.height + indentY;
      switch (align) {
        case 'right': {
          left = boundingRect.right - boundingRect.width;
          if (left + dropdownRect.width > viewportWidth)
            left = viewportWidth - dropdownRect.width;
          break;
        }
        case 'center': {
          left =
            boundingRect.left + boundingRect.width / 2 - boundingRect.width / 2;
          if (left + dropdownRect.width > viewportWidth)
            left = viewportWidth - dropdownRect.width;
          break;
        }
        case 'side-left-center': {
          left = boundingRect.x - dropdownRect.width - indentX;
          top =
            boundingRect.bottom -
            boundingRect.height / 2 -
            dropdownRect.height / 2;
          break;
        }
        case 'side-right-center': {
          left = boundingRect.right + indentX;
          top =
            boundingRect.bottom -
            boundingRect.height / 2 -
            dropdownRect.height / 2;
          if (top < 0) top = 0;
          else if (top + dropdownRect.height > viewportHeight)
            top = viewportHeight - dropdownRect.height;
          break;
        }
        case 'left':
        default:
          left = boundingRect.x - dropdownRect.width - indentX;
          top = boundingRect.y;
      }

      if (top < 0) top = 0;
      if (top + dropdownRect.height > viewportHeight) {
        top = viewportHeight - dropdownRect.height;
      }
      if (top < 0 || top + dropdownRect.height > viewportHeight) {
        top = viewportHeight / 2 - dropdownRect.height / 2;
      }

      if (left < 0) {
        left = boundingRect.right + indentX;
      }
      if (left + dropdownRect.width + indentX > viewportWidth) {
        left = boundingRect.left - dropdownRect.width - indentX;
      }
      if (left < 0 || left + dropdownRect.width + indentX > viewportWidth) {
        left = viewportWidth / 2 - dropdownRect.width / 2;
      }

      setLeft(left);
      setTop(top);
    }
  }, [
    shown,
    fullScreen,
    triggerRef,
    refreshPosition,
    width,
    indentY,
    align,
    indentX
  ]);

  useEffect(() => {
    const boundingRect = triggerRef.current.getBoundingClientRect();
    setWidth(boundingRect.width);
  }, [triggerRef]);

  const debouncedIsShown = useDebounce(shown, 200);
  const isShown = shown || debouncedIsShown;

  return isShown
    ? createPortal(
        <>
          <div
            style={
              fullScreen
                ? undefined
                : {
                    top,
                    left,
                    minWidth: width,
                    maxWidth: 'fit-content'
                  }
            }
            className={cn(
              'Dropdown__menu Dropdown__menu--portal',
              {
                'Dropdown__menu--fullScreen': fullScreen,
                fadeIn: shown,
                fadeOut: !shown
              },
              className
            )}
            onClick={(e) => e.stopPropagation()}
            ref={dropdown}
          >
            {children}
          </div>
          {fullScreen && (
            <div className='Dropdown__backdrop' onClick={onClose}></div>
          )}
        </>,
        // @ts-ignore
        document.getElementById('dropdowns')
      )
    : null;
};

export default DropDownWithPortal;
