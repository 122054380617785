import { PropsWithChildren, createContext, useContext, useState } from 'react';
import { ContentType } from '../../../core/types';
import ShareContentModal from './ShareContentModal';

interface IModalState {
  isOpen?: boolean;
  contentId?: number;
  url?: string;
  contentType?: ContentType;
}

type ShareModalConfigType = Omit<IModalState, 'isOpen'>;

interface IShareModal extends IModalState {
  closeShareModal?: () => void;
  showShareModal?: (config: ShareModalConfigType) => void;
}

export const ShareModalContext = createContext<IShareModal>({});

export const ShareModalProvider = ({ children }: PropsWithChildren<{}>) => {
  const [modalState, setModalState] = useState<IModalState>({
    isOpen: false,
    contentId: 0,
    url: null,
    contentType: 'clip'
  });

  const shareModalContext: IShareModal = {
    ...modalState,
    showShareModal: (config: ShareModalConfigType) => {
      setModalState({
        ...modalState,
        ...config,
        isOpen: true
      });
    },
    closeShareModal: () => {
      setModalState({
        ...modalState,
        isOpen: false,
        contentId: 0,
        url: null,
        contentType: 'clip'
      });
    }
  };

  return (
    <ShareModalContext.Provider value={shareModalContext}>
      <ShareContentModal />
      {children}
    </ShareModalContext.Provider>
  );
};

export const useShareModal = () => useContext(ShareModalContext);
