type TiktokEvents =
  | 'AddPaymentInfo'
  | 'AddToCart'
  | 'AddToWishlist'
  | 'ClickButton'
  | 'CompletePayment'
  | 'CompleteRegistration'
  | 'Contact'
  | 'Download'
  | 'InitiateCheckout'
  | 'PlaceAnOrder'
  | 'Search'
  | 'SubmitForm'
  | 'Subscribe'
  | 'ViewContent';

interface TiktokParams {
  content_type?: 'product' | 'product_group';
  content_id?: string;
  contents?: ContentParams[];
  content_category?: string;
  content_name?: string;
  currency?: 'USD';
  value?: number;
  price?: number;
  quantity?: number;
  query?: string;
  description?: string;
  status?: string;
}

interface ContentParams {
  content_id?: string;
  content_type?: string;
  content_name?: string;
  quantity: number;
  price: number;
}

interface TikTokIdentifyParams {
  email: string;
  phone_number: string;
  external_id?: string;
}

const TikTokPixel = {
  ttq: function () {
    return (window as any).ttq;
  },

  init: function (pixelId: string) {
    const pixelScript = this.createPixelScript(pixelId);
    document.head.insertBefore(pixelScript, document.head.childNodes[0]);
  },

  idenfify: function (identifier: TikTokIdentifyParams) {
    this.ttq().identify(identifier);
  },

  track: function (event: TiktokEvents, params?: TiktokParams) {
    this.ttq().track(event, params);
  },

  createPixelScript: function (pixelId: string) {
    const scriptInnerHTML = `
    !function (w, d, t) {
      w.TiktokAnalyticsObject=t;
      var ttq=w[t]=w[t]||[];
      ttq.methods=[
        "page",
        "track",
        "identify",
        "instances",
        "debug",
        "on",
        "off",
        "once",
        "ready",
        "alias",
        "group",
        "enableCookie",
        "disableCookie"
      ],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};
      for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);
      ttq.instance=function(t){
        for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);
        return e},
        ttq.load=function(e,n){
          var i="https://analytics.tiktok.com/i18n/pixel/events.js";
          ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};
          var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;
          var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
    

      ttq.load('${pixelId}');
      ttq.page();
    }(window, document, 'ttq');
        `;

    const script = document.createElement('script');
    script.onload = function () {
      if (process.env.REACT_APP_ENV !== 'production')
        console.log('TikTok Pixel script loaded');
    };
    script.innerHTML = scriptInnerHTML;

    return script;
  }
};

export default TikTokPixel;
