import React, { useEffect, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { ReactComponent as CrossIcon } from '../../icons/cross.svg';
import cn from 'classnames';
import { useDebounce } from '../../hooks';
import './Modal.styles.scss';
import Button from '../Button/Button';
import useViewPortHeightListener from '../../hooks/useViewPortHeightListener';

export interface IModalProps {
  isOpened: boolean;
  close?: () => void;
  children?: ReactNode;
  style?: React.CSSProperties;
  full?: boolean;
  className?: string;
  closeOnBackdrop?: boolean;
  showCloseButton?: boolean;
  animated?: boolean;
  animation?: 'fade' | 'bounce';
  onKeyDown?: React.KeyboardEventHandler;
  closeButtonClassName?: string;
}

const Modal = ({
  isOpened,
  style,
  children,
  close,
  className = '',
  full = true,
  closeOnBackdrop = true,
  showCloseButton = true,
  animated = true,
  animation = 'fade',
  onKeyDown,
  closeButtonClassName = ''
}: IModalProps) => {
  const { unsubscribePortViewHeight } = useViewPortHeightListener();
  const debouncedIsOpened = useDebounce(isOpened, 200);
  const isModalOpened = isOpened || debouncedIsOpened;

  useEffect(() => {
    if (!isOpened) return;

    const root: HTMLElement = document.querySelector('body');
    const alreadyLocked = root.classList.contains('lock-scroll');

    if (alreadyLocked) return;

    const scrollY = window.scrollY;
    root.classList.add('lock-scroll');

    return () => {
      if (!alreadyLocked) {
        root.classList.remove('lock-scroll');
        window.scrollTo(0, scrollY);
      }

      unsubscribePortViewHeight();
    };
  }, [isOpened]);

  return createPortal(
    <>
      {isModalOpened && (
        <div
          className={cn('Modal', className, {
            fadeIn: isOpened && animated,
            fadeOut: !isOpened && animated,
            'Modal--full-screen': full,
            'Modal--normal-screen': !full
          })}
          onClick={(e: React.MouseEvent) => e.stopPropagation()}
          onKeyDown={onKeyDown}
          tabIndex={0}
        >
          {!full && (
            <div
              className='Modal__overlay'
              onClick={closeOnBackdrop ? close : undefined}
            />
          )}

          {showCloseButton && close && (
            <Button
              className={cn(`Modal__close ${closeButtonClassName}`)}
              variant='icon'
              icon={<CrossIcon className='Modal__crossIcon' />}
              size='auto'
              borderRadius='rounded'
              onClick={close}
            />
          )}

          <div
            className={cn('Modal__container', {
              shake: isOpened && animated && animation === 'bounce',
              bounceOut: !isOpened && animated && animation === 'bounce'
            })}
          >
            <div className='Modal__inner' style={style}>
              <div className='Modal__body'>{children}</div>
            </div>
          </div>
        </div>
      )}
    </>,
    document.getElementById('modals')
  );
};

export default Modal;
