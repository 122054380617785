import { makeAutoObservable } from 'mobx';
import { makePersistable, clearPersistedStore } from 'mobx-persist-store';
import { IRecurrentEventData } from '../core/types';

class BookingStore {
  recurrentDateData: IRecurrentEventData = null;
  invitationCode: string = null;
  isGrammyEvent: boolean = false;

  constructor() {
    makeAutoObservable(this);

    makePersistable(this, {
      name: 'BookingStore',
      properties: ['recurrentDateData'],
      storage: localStorage,
      expireIn: 1800000 // 30 minutes
    });
  }

  saveDateDataToStore = (data: IRecurrentEventData) => {
    this.recurrentDateData = data;
  };

  saveIsGrammyEventToStore = (data: boolean) => {
    this.isGrammyEvent = data;
  };

  saveInvitationCodeToStore = (code: string) => {
    this.invitationCode = code;
  };

  clearStoredDateData = async () => {
    await clearPersistedStore(this);
  };
}

export default BookingStore;
