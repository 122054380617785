const GoogleTagManager = {
  init: function (id: string) {
    const tagManagerScript = this.createTagManagerScript(id);
    const tagManagerDataLayer = this.createTagManagerDataLayer(id);

    document.head.insertBefore(tagManagerScript, document.head.childNodes[0]);
    document.head.insertBefore(
      tagManagerDataLayer,
      document.head.childNodes[1]
    );
  },
  dataLayer: function (data: any) {
    (window as any).dataLayer.push(data);
  },

  createTagManagerScript: function (id: string) {
    const scriptUrl = `https://www.googletagmanager.com/gtag/js?id=${id}`;

    const script = document.createElement('script');
    script.async = true;
    script.src = scriptUrl;
    script.onload = function () {
      if (process.env.REACT_APP_ENV !== 'production')
        console.log('Google Tag Manager script loaded');
    };

    return script;
  },
  createTagManagerDataLayer: function (id: string) {
    const scriptInnerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${id}');
        `;

    const script = document.createElement('script');
    script.innerHTML = scriptInnerHTML;

    return script;
  }
};

export default GoogleTagManager;
