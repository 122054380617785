import classNames from 'classnames';
import dayjs from 'dayjs';
import { v4 as uuidv4, v4 } from 'uuid';
import { UserTimeAvailabilityInput } from '../core/backend/models';
import './TimeAvailability.scss';

interface Props {
  availability: UserTimeAvailabilityInput;
  className?: string;
}
const TimeAvailability = ({ availability, className = '' }: Props) => {
  return (
    <div className={classNames(`TimeAvailability ${className}`)}>
      <div className='TimeAvailability__inner'>
        {availability?.timeAvailabilities
          ?.sort((a, b) => {
            if (a.dayOfWeek === 0) return 1;
            if (b.dayOfWeek === 0) return -1;

            return a.dayOfWeek - b.dayOfWeek;
          })
          .map((time) => {
            const dayOfTheWeek = dayjs().set('day', time.dayOfWeek);
            return (
              <div className='TimeAvailability__timeRow' key={v4()}>
                <div className='TimeAvailability__dayOfWeek'>
                  {dayOfTheWeek.format('dddd')}
                </div>
                <div className='TimeAvailability__timeAvailable'>
                  {time.enabled === true ? (
                    time.availablePeriods?.map((period) => (
                      <div
                        className='TimeAvailability__timeAvailableRow'
                        key={v4()}
                      >
                        {`${dayjs(period.startTime * 1000).format(
                          'h:mm a'
                        )} - ${dayjs(period.endTime * 1000).format('h:mm a')}`}
                      </div>
                    ))
                  ) : (
                    <div className='TimeAvailability__notAvailableRow'>
                      Not Available
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default TimeAvailability;
