import { makeAutoObservable } from 'mobx';
import {
  FollowerViewModel,
  PaymentMethodViewModel,
  ProviderSummaryResponse,
  UserViewModel
} from '../core/backend/models';
import {
  Address,
  EventsWithStatus,
  ICoordinates,
  IVideoModel
} from '../core/types';
import ExperienceModel from '../core/models/ExperienceModel';
import { ProfileTabsEnum } from '../core/enums';

class UserStore {
  user: UserViewModel | null = null;
  statistics: ProviderSummaryResponse = {
    earnings: 0,
    videoViewsCount: 0,
    videoLikesCount: 0,
    followersCount: 0,
    providerBookingsCount: 0
  };
  followers: FollowerViewModel[] = [];
  paymentMethods: PaymentMethodViewModel[] = [];
  mappedEvents: EventsWithStatus = {
    [ProfileTabsEnum.Upcoming]: [],
    [ProfileTabsEnum.Past]: [],
    [ProfileTabsEnum.Cancelled]: []
  };
  clips: IVideoModel[] = [];
  coords: ICoordinates = { lat: 0, lng: 0 };
  fullAddress: string = '';
  addressFields: Address = {
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: ''
  };

  constructor() {
    makeAutoObservable(this);
  }

  saveUserToStore = (user: UserViewModel | undefined) => {
    this.user = user;
  };

  savePaymentMethods = (paymentMethods: PaymentMethodViewModel[]) => {
    this.paymentMethods = paymentMethods;
  };

  saveStatistics = (stats: ProviderSummaryResponse) => {
    this.statistics = stats;
  };

  saveFollowers = (followers: FollowerViewModel[]) => {
    this.followers = followers;
  };

  saveMediaToStore = (mappedEvents: EventsWithStatus, clips: IVideoModel[]) => {
    this.mappedEvents = mappedEvents;
    this.clips = clips;
  };

  saveAddress = (
    fullAddress: string,
    addressFields: Address,
    coords: ICoordinates
  ) => {
    this.fullAddress = fullAddress;
    this.addressFields = addressFields;
    this.coords = coords;
  };
}

export default UserStore;
