import { ChatViewModel, MessageViewModel } from '../../backend/models';
import SignalSocket from './signal-socket';

class ChatSocket extends SignalSocket<MessageViewModel> {
  _chat: ChatViewModel;
  constructor() {
    super();
  }

  setChat(chat: ChatViewModel) {
    this._chat = chat;
    return this;
  }

  whenReceivingMessage(callback: (message: MessageViewModel) => void) {
    this.on('MessageSent', callback);
    return this;
  }

  send(messageBody: string) {
    return this.call('SendMessage', { chatId: this._chat?.id, messageBody });
  }
}

export default ChatSocket;
