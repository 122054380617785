import { useEffect, useRef, useState } from 'react';
import { Scrollbar, ScrollbarContext } from 'react-scrollbars-custom';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import {
  ChatMemberViewModel,
  MessageViewModel,
  UserViewModel
} from '../../core/backend/models';
import Button from '../Button/Button';
import { ReactComponent as CrossIcon } from '../../icons/cross.svg';
import ChatSocket from '../../core/classes/signal/chat-socket';
import { createDirectChat, getMediaFile } from '../../core/api';
import CommentForm, { CommentInput } from '../CommentForm/CommentForm';
import Message from '../Message/Message';
import './Chat.scss';
import Avatar from '../Avatar/Avatar';
import classNames from 'classnames';
import { DEFAULT_ERROR_MESSAGE } from '../../core/validators';
import appToast from '../../core/toast';
import Loader from '../Loader/Loader';
import BookingCard from '../Cards/BookingAttachment/BookingAttachment';
import { useStores } from '../../hooks';
import useViewPortHeightListener from '../../hooks/useViewPortHeightListener';
import { VideoMessageModel } from '../../core/backend/models/video-message-model';
import VideoMessage from '../VideoMessage/VideoMessage';
import getGlobal from '../../core/globals';

interface Props {
  user: UserViewModel;
  isExpanded: boolean;
  onExpandCollapseTab: () => void;
  onClose: () => void;
}

const MESSAGE_COUNT_PER_PAGE = 10;

const Chat = ({ user, isExpanded, onExpandCollapseTab, onClose }: Props) => {
  const { userStore, bookingsStore } = useStores();
  const { unsubscribePortViewHeight } = useViewPortHeightListener();
  const [isLoading, setIsLoading] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [conversation, setConversation] = useState<MessageViewModel[]>([]);
  const [messages, setMessages] = useState<MessageViewModel[]>([]);
  const [chatMembers, setChatMembers] = useState<UserViewModel[]>([]);
  const [offset, setOffset] = useState(0);
  const [message, setMessage] = useState<CommentInput>({
    text: '',
    plainText: '',
    mentions: []
  });
  const [chatSocket, setChatSocket] = useState<ChatSocket>(null);
  const scroller = useRef<Scrollbar | null>(null);

  useEffect(() => {
    createDirectChatConnection();

    return () => {
      if (chatSocket?.isConnected) disconnectFromChat();
      unsubscribePortViewHeight();
    };
  }, [chatSocket]);

  useEffect(() => {
    if (isLoading) return;

    scroller?.current?.scrollToBottom();
  }, [isLoading]);

  const createDirectChatConnection = async () => {
    try {
      if (chatSocket) return;

      setIsLoading(true);

      const chat = await createDirectChat(user.id);

      const socket = new ChatSocket()
        .setChat(chat)
        .whenReceivingMessage(handleNewMessageReceived);

      setOffset(MESSAGE_COUNT_PER_PAGE);

      setChatMembers([user, userStore.user]);
      setConversation(chat.messages);
      setMessages(filterMessagePages(chat.messages, MESSAGE_COUNT_PER_PAGE));

      setIsLoading(false);

      await socket.init();
      setChatSocket(socket);
    } catch (e: any) {
      setIsLoading(false);
      const error = e.response?.data?.description || DEFAULT_ERROR_MESSAGE;
      appToast.showError(error);
    }
  };

  const disconnectFromChat = async () => {
    if (!chatSocket?.isConnected) return;

    await chatSocket.stop();
    setChatSocket(null);
  };

  const handleNewMessageReceived = (message: MessageViewModel) => {
    setConversation((prevState) => [...prevState, message]);
    setMessages((prevState) => [...prevState, message]);
    setOffset((prevState) => prevState + 1);
    scroller?.current?.scrollToBottom();
  };

  const handleSendMessage = async () => {
    try {
      if (!chatSocket?.isConnected) {
        return;
      }

      setIsSendingMessage(true);

      await chatSocket.send(message.text);
      setMessage({
        text: '',
        plainText: '',
        mentions: []
      });
    } catch (e: any) {
      const error = e.response?.data?.description || DEFAULT_ERROR_MESSAGE;
      appToast.showError(error);
    } finally {
      setIsSendingMessage(false);
    }
  };

  const handleLoadMoreMessages = () => {
    setOffset((prevState) => prevState + MESSAGE_COUNT_PER_PAGE);
    setMessages(
      filterMessagePages(conversation, offset + MESSAGE_COUNT_PER_PAGE)
    );
  };

  const filterMessagePages = (messages: MessageViewModel[], offset: number) => {
    const startFrom = messages.length - offset;
    return messages.slice(startFrom >= 0 ? startFrom : 0);
  };

  const handleCloseTab = () => {
    disconnectFromChat();
    onClose();
  };

  return (
    <div
      className={classNames('Chat', {
        'Chat--expanded': isExpanded
      })}
    >
      <div className='Chat__header'>
        <Avatar
          className='Chat__userImage'
          size='small'
          image={
            user.image && (
              <img
                src={getMediaFile(user.image)}
                onClick={onExpandCollapseTab}
                alt={user.fullName}
              />
            )
          }
          symbol={user.userName[0]}
        />
        <div
          className='Chat__userName oneLineText'
          onClick={onExpandCollapseTab}
        >
          @{user.userName}
        </div>
        <Button className='Chat__close' variant='icon' onClick={handleCloseTab}>
          <CrossIcon className='CommentsModal__closeIcon' />
        </Button>
      </div>
      {isExpanded && (
        <div className='Chat__body'>
          <div className='Chat__wrapper'>
            {isLoading ? (
              <Loader
                className='Chat__loader'
                fixed={false}
                showLogo={false}
                width='32px'
              />
            ) : (
              <Scrollbar
                noScrollX={true}
                thumbYProps={{ className: 'thumbX' }}
                trackYProps={{ className: 'trackX' }}
                height='100%'
                width='100%'
                createContext={true}
              >
                <ScrollbarContext.Consumer>
                  {({ parentScrollbar }) => {
                    scroller.current = parentScrollbar;
                    let lastDate: string | null = null;

                    return conversation.length > 0 ? (
                      <div className='Chat__messages'>
                        {conversation.length > 0 &&
                          offset < conversation.length && (
                            <Button
                              key={`load-more-button-${uuidv4()}`}
                              className='Chat__loadMoreButton'
                              size='sm'
                              variant='text'
                              color='primary'
                              onClick={handleLoadMoreMessages}
                            >
                              Load more messages
                            </Button>
                          )}
                        {messages.map((message) => {
                          const currentDate = dayjs(
                            new Date(message.sendOn)
                          ).format('MMM DD, YYYY');

                          const sender = chatMembers.find(
                            (member) => member.id === message.senderId
                          );

                          const shouldDisplayDate = lastDate !== currentDate;
                          if (shouldDisplayDate) lastDate = currentDate;

                          let messageCard;
                          if (message.isAppointmentMessage) {
                            const eventObject = JSON.parse(message.body);
                            const booking = bookingsStore.getBooking(
                              eventObject.Id
                            );

                            if (booking?.gig)
                              messageCard = (
                                <BookingCard
                                  event={eventObject}
                                  booking={booking}
                                />
                              );
                          } else if (message.isVideoMessage) {
                            const videoObject: VideoMessageModel = JSON.parse(
                              message.body
                            );

                            messageCard = (
                              <VideoMessage
                                video={videoObject}
                                isOwnMessage={
                                  message.senderId === userStore.user.id
                                }
                                sendOn={message.sendOn}
                                onClick={(mediaFileId: number) => {
                                  window.open(
                                    `${getGlobal(
                                      'baseUrl'
                                    )}/clip/${mediaFileId}`
                                  );
                                }}
                              />
                            );
                          } else {
                            messageCard = (
                              <Message
                                key={message.id}
                                sender={sender}
                                message={message}
                              />
                            );
                          }

                          return (
                            <>
                              {shouldDisplayDate && (
                                <div
                                  key={`date-${message.id}`}
                                  className='Chat__dateRow'
                                >
                                  <div className='Chat__date'>
                                    {currentDate}
                                  </div>
                                </div>
                              )}
                              {messageCard}
                            </>
                          );
                        })}
                      </div>
                    ) : (
                      <div className='Chat__noMessages'>
                        You will you see all the messages between You and @
                        {user.userName} here.
                      </div>
                    );
                  }}
                </ScrollbarContext.Consumer>
              </Scrollbar>
            )}
          </div>
          <CommentForm
            className='Chat__messageForm'
            placeholder='Write a message...'
            isProcessing={isSendingMessage}
            comment={message}
            onChange={setMessage}
            onSend={handleSendMessage}
          />
        </div>
      )}
    </div>
  );
};

export default Chat;
