import ReactPlayer from 'react-player';
import { PlayerState } from '../../core/types';
import { ReactComponent as PlayIcon } from '../../icons/play.svg';
import './VideoPlayer.scss';

interface IProps {
  url: string;
  thumbnail?: string | undefined;
  background?: string;
  playerState?: PlayerState;
  isMute?: boolean;
  volume?: number;
  onPlayerStateChange: (state: PlayerState, prevState?: PlayerState) => void;
  onDuration: (duration: number) => any;
  onProgress?: (playedMilliseconds: number) => void;
}

const VideoPlayer = ({
  url,
  playerState,
  thumbnail,
  background = '',
  isMute = false,
  volume = 0.5,
  onPlayerStateChange,
  onDuration,
  onProgress
}: IProps) => {
  const togglePlay = () => {
    onPlayerStateChange(
      ['idle', 'paused', 'finished'].includes(playerState)
        ? 'playing'
        : 'paused',
      playerState
    );
  };

  return (
    <div className='VideoPlayer'>
      {/* <div
        className='VideoPlayer__background'
        style={{
          background: `#000 url(${background}) no-repeat center / cover`
        }}
      ></div> */}
      <div className='VideoPlayer__wrapper'>
        <ReactPlayer
          url={url}
          className='react-player'
          playing={playerState === 'playing'}
          width='100%'
          height='100%'
          progressInterval={1}
          playsinline={true}
          muted={isMute}
          volume={volume}
          onPlay={() => onPlayerStateChange('playing', playerState)}
          onPause={() => onPlayerStateChange('paused', playerState)}
          onEnded={() => {
            onPlayerStateChange('finished', playerState);
          }}
          onDuration={onDuration}
          onProgress={({ played, playedSeconds }) => {
            onProgress && onProgress(playedSeconds * 1000);
          }}
          config={{
            file: {
              attributes: {
                poster: thumbnail
              }
            }
          }}
        />

        <div className='VideoPlayer__touchToPlay' onClick={togglePlay}>
          {playerState !== 'playing' && (
            <>
              <div className='VideoPlayer__overlay'></div>
              <PlayIcon className='VideoPlayer__playIcon' />
              <div className='VideoPlayer__watchVideo'>Watch Video</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
