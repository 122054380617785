import { makeAutoObservable } from 'mobx';
import { GigViewModel } from '../core/backend/models';
import ExperienceModel from '../core/models/ExperienceModel';

class GigStore {
  gigs: ExperienceModel[] | undefined = [];

  constructor() {
    makeAutoObservable(this);
  }
  saveToStore = (gigs: ExperienceModel[] | undefined) => {
    this.gigs = gigs;
  };

  addOneViewToExperience = (experienceId: number) => {
    this.gigs = this.gigs.map<ExperienceModel>((experience) => {
      const exp = experience;

      if (experience.id === experienceId) {
        experience.videoViews += 1;
      }

      return exp;
    });
  };
}

export default GigStore;
