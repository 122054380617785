import { useFormik } from 'formik';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useEffect, useMemo, useState } from 'react';
import { updateUser, uploadUserImage } from '../../../../../core/api';
import { DEFAULT_ERROR_MESSAGE } from '../../../../../core/validators';
import { useStores } from '../../../../../hooks';
import Button from '../../../../Button/Button';
import TextField from '../../../../TextField/TextField';
import './UsernameForm.scss';
import { useAuthModal } from '../../AuthModal';
import Loader from '../../../../Loader/Loader';
import Steps from '../../../../Steps/Steps';
import FileUploader from '../../../../FileUploader/FileUploader';
import Avatar from '../../../../Avatar/Avatar';
import { ReactComponent as HooplaIcon } from '../../../../../images/hooplaicon.svg';
import validationSchema from '../../../../../core/validations/completeUsername';
import appToast from '../../../../../core/toast';
import Filter from '../../../../../core/classes/filter/filter';

interface FormValues {
  userName: string;
  profileImage?: File;
}

interface Step {
  title: string;
  description: string;
  component: React.ReactNode;
}

dayjs.extend(relativeTime);
const filter = new Filter();

const UsernameForm = () => {
  const { userStore } = useStores();
  const { closeAuthModal, changeAuthModalTitles, formData } = useAuthModal();
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState<string>(null);
  const [currentStep, setCurrentStep] = useState(0);

  const {
    submitForm,
    setFieldValue,
    handleChange,
    values,
    errors,
    handleBlur,
    touched
  } = useFormik<FormValues>({
    initialValues: {
      userName: formData?.userName ?? ''
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (currentStep === 0) {
        const { userName } = values;

        if (filter.isProfane(userName)) {
          return appToast.showError(
            "We're sorry, but it appears that your username contains inappropriate language."
          );
        }

        updateUsername(userName);
      } else if (currentStep === 1) {
        const { profileImage } = values;
        uploadProfileImage(profileImage);
      }
    }
  });

  useEffect(() => {
    const { title, description } = steps[currentStep];
    changeAuthModalTitles(title, description);
  }, [currentStep]);

  const updateUsername = async (userName: string) => {
    try {
      setIsLoading(true);

      const user = await updateUser({ newUsername: userName });
      userStore.saveUserToStore(user);

      setCurrentStep((current) => current + 1);
      setServerError(null);
    } catch (e: any) {
      const error = e.response?.data?.description || DEFAULT_ERROR_MESSAGE;
      setServerError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeProfileImage = async (image: File) => {
    setFieldValue('profileImage', image);
  };

  const uploadProfileImage = async (image: File) => {
    try {
      setIsLoading(true);

      const user = await uploadUserImage(image);
      userStore.saveUserToStore(user);

      setServerError(null);
      closeAndComplete();
    } catch (e: any) {
      const error = e.response?.data?.description || DEFAULT_ERROR_MESSAGE;
      setServerError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const closeAndComplete = () => {
    closeAuthModal();
    appToast.showSuccess('Your profile is complete!');
  };

  const handleGoBack = () => {
    if (currentStep === 0) return;

    setCurrentStep(currentStep - 1);
  };

  const steps = useMemo<Step[]>(
    () => [
      {
        title: 'Username Taken',
        description: 'Type a new username',
        component: (
          <div className='UsernameForm__inputs'>
            <TextField
              label='Username'
              name='userName'
              id='userName'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.userName}
              error={errors.userName}
              touched={touched.userName}
            />
          </div>
        )
      },
      {
        title: 'Almost there',
        description: 'Add a picture to help others identify you on hoopla',
        component: (
          <div className='UsernameForm__uploaderWrapper d-inline-block mx-auto'>
            <FileUploader
              className='UsernameForm__uploader'
              onChange={handleChangeProfileImage}
            >
              <div className='UsernameForm__uploaderBtnText'>
                Change your profile picture
              </div>
              <Avatar
                className='UsernameForm__profileImage'
                size='large'
                image={
                  values.profileImage ? (
                    <img
                      src={URL.createObjectURL(values.profileImage)}
                      alt='avatar'
                    />
                  ) : (
                    <HooplaIcon />
                  )
                }
              />
            </FileUploader>
            <Button
              className='UsernameForm__later'
              onClick={closeAndComplete}
              variant='text'
              disabled={isLoading}
            >
              I'll do this later
            </Button>
          </div>
        )
      }
    ],
    [values, errors, touched]
  );

  return (
    <div className='UsernameForm'>
      {serverError && (
        <div className='UsernameForm__errorMessage'>{serverError}</div>
      )}

      <Steps activeId={currentStep} steps={steps.map((_, i) => i)} />

      <div className='UsernameForm__content'>
        {steps[currentStep].component}
      </div>

      <div className='UsernameForm__buttons'>
        <Button
          className='UsernameForm__back'
          variant='outline'
          onClick={handleGoBack}
          disabled={isLoading || currentStep === 0}
        >
          Back
        </Button>
        <Button
          className='UsernameForm__next'
          type='submit'
          disabled={isLoading}
          onClick={submitForm}
        >
          {isLoading ? (
            <Loader light fixed={false} showLogo={false} width='32px' />
          ) : currentStep === 0 ? (
            'Next'
          ) : (
            'Upload'
          )}
        </Button>
      </div>
    </div>
  );
};

export default UsernameForm;
