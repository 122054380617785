import classNames from 'classnames';
import { ReactComponent as HooplaLogo } from '../../images/logo_sm.svg';
import './EventBadge.scss';

interface Props {
  className?: string;
  type?:
    | 'event'
    | 'soldOut'
    | 'passed'
    | 'private'
    | 'private_event'
    | 'recurrent'
    | 'none';
  size?: 'small' | 'medium';
  shadowed?: boolean;
}

const eventLabel = {
  event: 'Event',
  soldOut: 'Sold Out',
  passed: 'Event is Closed',
  private: 'Private',
  private_event: 'Private Event',
  recurrent: 'Recurring'
};

const EventBadge = ({
  className = '',
  type = 'event',
  size = 'small',
  shadowed = true
}: Props) => {
  if (type === 'none') return null;
  return (
    <div
      className={classNames(`EventBadge ${className}`, {
        'EventBadge--event': type === 'event',
        'EventBadge--private': ['private', 'private_event'].includes(type),
        'EventBadge--soldOut': type === 'soldOut',
        'EventBadge--passed': type === 'passed',
        'EventBadge--medium': size === 'medium',
        'EventBadge--shadowed': shadowed,
        'EventBadge--recurrent': type === 'recurrent'
      })}
    >
      {type === 'event' && (
        <HooplaLogo className='EventBadge__logo' width='80%' />
      )}
      <div className='EventBadge__label'>{eventLabel[type]}</div>
    </div>
  );
};

export default EventBadge;
