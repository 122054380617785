import mixpanel from 'mixpanel-browser';

class MixpanelTracking {
  private static __instance: MixpanelTracking;

  constructor() {
    if (MixpanelTracking.__instance) throw new Error('Tracking already exists');

    mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY, {
      debug: process.env.REACT_APP_ENV !== 'production',
      ignore_dnt: true
    });
  }

  public static instance(): MixpanelTracking {
    if (!MixpanelTracking.__instance)
      return (MixpanelTracking.__instance = new MixpanelTracking());

    return MixpanelTracking.__instance;
  }

  identify(distinctId: string) {
    mixpanel.identify(distinctId);

    return this;
  }

  setProps(props: object = {}) {
    mixpanel.people.set(props);

    return this;
  }

  track(name: string, data: object = {}) {
    mixpanel.track(name, data);

    return this;
  }
}

export default MixpanelTracking;
