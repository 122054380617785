import { Outlet } from 'react-router-dom';
import { MediaModalsContextProvider } from '../../components/MediaModalsContextProvider/MediaModalsContextProvider';
import { MessengerContainer } from '../../components/Messenger/Messenger';
import { AuthModalContainer } from '../../components/Modal/AuthModal/AuthModal';
import { ShareModalProvider } from '../../components/Modal/ShareContentModal/useShareModal';

const ContextsLayout = () => {
  return (
    <AuthModalContainer>
      <ShareModalProvider>
        <MessengerContainer>
          <MediaModalsContextProvider>
            <Outlet />
          </MediaModalsContextProvider>
        </MessengerContainer>
      </ShareModalProvider>
    </AuthModalContainer>
  );
};

export default ContextsLayout;
