import { useEffect } from 'react';

const useViewPortHeightListener = () => {
  useEffect(() => {
    window.addEventListener('resize', calculateViewPortHeight);

    window.setTimeout(() => {
      calculateViewPortHeight();
    }, 1000);

    return unsubscribePortViewHeight;
  }, []);

  const calculateViewPortHeight = () => {
    const vh = window.innerHeight;
    document.documentElement.style.setProperty('--viewport-height', `${vh}px`);
  };

  const unsubscribePortViewHeight = () => {
    window.removeEventListener('resize', calculateViewPortHeight);
  };

  return { unsubscribePortViewHeight };
};

export default useViewPortHeightListener;
