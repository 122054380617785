import { makeAutoObservable } from 'mobx';
import { IVideoModel } from '../core/types';

class VideoStore {
  videos: IVideoModel[] = [];

  constructor() {
    makeAutoObservable(this);
  }
  saveToStore = (videos: IVideoModel[]) => {
    this.videos = videos;
  };
}

export default VideoStore;
