import React, { ReactElement, useState, useRef, useCallback } from 'react';
import DropDownWithPortal from '../DropDownWithPortal/DropDownWithPortal';
import DropDownWithoutPortal from '../DropDownWithoutPortal/DropDownWithoutPortal';
import './Dropdown.styles.scss';
import { useMedia, useOnClickOutside } from '../../hooks';
import Button from '../Button/Button';
import Tooltip, { Props as TooltipProps } from '../Tooltip/Tooltip';

export interface ListItemProps {
  name: string;
  callback: () => void;
  icon?: any;
  dataTestid?: string;
}

export type Alignment =
  | 'right'
  | 'left'
  | 'center'
  | 'side-left-center'
  | 'side-right-center';

interface Props {
  trigger: JSX.Element;
  children?: (cb?: () => void) => ReactElement<any>;
  className?: string;
  align?: Alignment;
  withPortal?: boolean;
  fullScreen?: boolean;
  onClose?: () => void;
  isOpened?: boolean;
  dropdownIndentX?: number;
  dropdownIndentY?: number;
  autoClose?: boolean;
  refreshPosition?: boolean;
  disableCloseOnScroll?: boolean;
}

const Dropdown: React.FC<Props> = ({
  className = '',
  children,
  trigger,
  align = 'left',
  withPortal = true,
  fullScreen = false,
  isOpened,
  onClose,
  dropdownIndentX,
  dropdownIndentY,
  refreshPosition,
  disableCloseOnScroll = false
}) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState<boolean>(false);
  const isMobile = useMedia(['(min-width: 576px)'], [false], true);
  const dropdown = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const isExternalControled = isOpened !== undefined && !!onClose;

  isOpened = isExternalControled ? isOpened : isDropdownOpened;

  const handleClose = useCallback(
    (e?: React.MouseEvent) => {
      e?.stopPropagation();

      if (!isOpened && !!withPortal) {
        return;
      }
      if (!isExternalControled) {
        setIsDropdownOpened(false);
        return;
      }
      if (onClose) onClose();
    },
    [isExternalControled, onClose, isOpened]
  );

  useOnClickOutside(dropdown, handleClose);

  // useOverflow(isMobile && isOpened, 'dropdown-opened');

  const handleClickButton = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsDropdownOpened(!isOpened);
  };

  let triggerProps: Record<string, any> = {
    ref: buttonRef,
    className: `${trigger.props.className} Dropdown__trigger`
  };

  if (!isExternalControled) {
    if (trigger.type === Button) {
      triggerProps['handleClick'] = handleClickButton;
    } else if (trigger.type === Tooltip) {
      triggerProps['trigger'] = React.cloneElement(
        (trigger.props as TooltipProps).trigger,
        {
          onClick: handleClickButton,
          ref: buttonRef
        }
      );
      delete triggerProps.ref;
    } else {
      triggerProps['onClick'] = handleClickButton;
    }
  }

  const triggerElem = React.cloneElement(trigger, triggerProps);

  return (
    <div
      className={`Dropdown ${className} ${isOpened ? 'Dropdown--opened' : ''}`}
      ref={dropdown}
    >
      {triggerElem}
      {withPortal ? (
        <DropDownWithPortal
          triggerRef={buttonRef}
          shown={isOpened}
          onClose={handleClose}
          fullScreen={fullScreen && isMobile}
          align={align}
          indentX={dropdownIndentX}
          indentY={dropdownIndentY}
          className={className}
          refreshPosition={refreshPosition}
          disableCloseOnScroll={disableCloseOnScroll}
        >
          {children(handleClose)}
        </DropDownWithPortal>
      ) : (
        <DropDownWithoutPortal
          shown={isOpened}
          align={align}
          fullScreen={fullScreen && isMobile}
          onClose={handleClose}
        >
          {children(handleClose)}
        </DropDownWithoutPortal>
      )}
    </div>
  );
};

export default Dropdown;
