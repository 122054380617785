import { useEffect } from 'react';
import Button from '../Button/Button';
import { ReactComponent as MobileIcon } from '../../icons/mobile.svg';
import './MobileAuthButton.scss';
import classNames from 'classnames';

interface Props {
  type?: 'signin_with' | 'signup_with';
  shape?: 'standard' | 'icon';
  onClick: () => void;
}

const MobileAuthButton = ({
  type = 'signin_with',
  shape = 'standard',
  onClick
}: Props) => {
  return (
    <Button
      className={classNames('MobileAuthButton', {
        'MobileAuthButton--icon': shape === 'icon'
      })}
      onClick={onClick}
    >
      <div className='MobileAuthButton__wrapper'>
        <MobileIcon className='MobileAuthButton__icon' />
        {shape === 'standard' && (
          <div className='MobileAuthButton__label'>
            {type === 'signin_with' ? 'Sign in' : 'Sign up'} with Phone Number
          </div>
        )}
      </div>
    </Button>
  );
};

export default MobileAuthButton;
