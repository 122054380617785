import { formatUrl } from '../../core/helpers';
import './UrlToAnchor.scss';
import { PropsWithChildren } from 'react';

interface IProps {
  children: string;
}

const UrlToAnchor = ({ children }: IProps) => {
  const urlRegex = /((https?):\/\/[^\s]+)|(www\.[^\s]+)/g;
  const newString = children.replace(urlRegex, (url) => {
    return `<a href="${
      formatUrl(url).formattedUrl
    }" target="_blank" rel="noopener noreferrer">${url}</a>`;
  });

  return <div dangerouslySetInnerHTML={{ __html: newString }} />;
};

export default UrlToAnchor;
