import { makeAutoObservable } from 'mobx';
import { ISearchFilter } from '../components/Modal/SearchFilters/SearchFilters';
import { DiscoveryFilterSortBy, GigsSortOrder } from '../core/backend/enums';
import { HOOPLA_LOCALDATA } from '../core/consts';
import { AppLocalData, ICoordinates } from '../core/types';

class AppDataManager {
  data: AppLocalData = {
    hyperLocal: { fullAddress: null, coords: null },
    filters: { sortBy: DiscoveryFilterSortBy.NoSort }
  };

  constructor() {
    makeAutoObservable(this);
  }

  loadData = () => {
    const _data = JSON.parse(localStorage.getItem(HOOPLA_LOCALDATA));
    if (_data) this.data = Object.assign(this.data, _data);
  };

  updateData = () => {
    localStorage.setItem(HOOPLA_LOCALDATA, JSON.stringify(this.data));
  };

  updateHyperLocalData = (address: string, coords: ICoordinates) => {
    this.data.hyperLocal.fullAddress = address;
    this.data.hyperLocal.coords = coords;

    this.updateData();
  };

  updateFilters = (filters: ISearchFilter) => {
    this.data.filters = filters;

    this.updateData();
  };
}

export default AppDataManager;
