import React from 'react';
import { ReactComponent as HooplaLogo } from '../../images/logo_sm.svg';
import classNames from 'classnames';
import './Loader.scss';

interface Props {
  className?: string;
  showLogo?: boolean;
  width?: string;
  fixed?: boolean;
  light?: boolean;
}

const Loader: React.FC<Props> = ({
  className = '',
  width = '70px',
  fixed = true,
  showLogo = true,
  light = false
}) => {
  return (
    <div
      className={classNames(`Loader ${className}`, {
        'Loader--fixed': fixed,
        'Loader--light': light
      })}
    >
      <div className='spinner' style={{ width }}>
        <svg className='circular' viewBox='22 22 44 44'>
          <circle
            className='path'
            cx='44'
            cy='44'
            r='20.2'
            fill='none'
            strokeWidth='3.6'
          />
        </svg>
        {showLogo && <HooplaLogo className='Loader__logo' width='80%' />}
      </div>
    </div>
  );
};

export default Loader;
