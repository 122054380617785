import './Header.scss';
import { ReactComponent as HooplaLogo } from '../../images/logo.svg';
import { ReactComponent as HooplaSparksIcon } from '../../images/logo_sm.svg';
import { ReactComponent as HooplaCircledLogo } from '../../images/hoopla_circled_logo.svg';
import { ReactComponent as UserIcon } from '../../icons/user.svg';
import { ReactComponent as MenuIcon } from '../../icons/menu_icon.svg';
import { ReactComponent as TicketsIcon } from '../../icons/tickets_icon.svg';
import { ReactComponent as CreateEventIcon } from '../../icons/users_icon.svg';
import { ReactComponent as LogOutIcon } from '../../icons/logout_icon.svg';
import { ReactComponent as CheckInIcon } from '../../icons/check_in_icon.svg';
import { ReactComponent as DownArrowIcon } from '../../icons/down_arrow_icon.svg';
import { ReactComponent as AppStoreIcon } from '../../icons/appstore_badge.svg';
import { ReactComponent as CheckIcon } from '../../icons/clipboard_check_icon.svg';
import GooglePlayIcon from '../GooglePlayIcon/GooglePlayIcon';
import { RoutesEnum } from '../../core/enums';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useDebounce, useStores } from '../../hooks';
import { getMediaFile, logOut } from '../../core/api';
import DropdownMenu, { ListItemProps } from '../DropdownMenu/DropdownMenu';
import Avatar from '../Avatar/Avatar';
import { useEffect, useMemo, useState } from 'react';
import SearchInput from '../SearchInput/SearchInput';
import { useAuthModal } from '../Modal/AuthModal/AuthModal';
import {
  generateFormattedAddress,
  getCurrentCoords,
  iOS,
  isAndroid,
  isMobileDevice,
  numFormatter
} from '../../core/helpers';
import appToast from '../../core/toast';
import { DEFAULT_ERROR_MESSAGE } from '../../core/validators';
import Loader from '../Loader/Loader';
import CategoryMenu from '../CategoryMenu/CategoryMenu';
import { IRouteParams } from '../../pages/Category/Category';
import HyperLocal from '../HyperLocal/HyperLocal';
import SearchFilters, {
  ISearchFilter
} from '../Modal/SearchFilters/SearchFilters';
import { useMessenger } from '../Messenger/Messenger';
import { GRAMMY_ADMINS, MY_LOCATION, SUPER_USERS } from '../../core/consts';
import { downloadApp } from '../DownloadWidget/DownloadWidget.helper';

interface Props {
  icon?: React.ReactNode;
  title?: string;
  className?: string;
  subSection?: React.ReactNode;
}

const Header = ({ icon, title, subSection, className }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    userStore,
    likedMedia,
    stripeStore,
    bookingsStore,
    followUsers,
    categoryStore,
    locationStore,
    appDataManager
  } = useStores();
  const { showAuthModal } = useAuthModal();
  const { closeAllAtiveChats } = useMessenger();
  const [searchQuery, setSearchQuery] = useState('');
  const trimmed = searchQuery.trim();
  const debouncedText = useDebounce(
    trimmed.length >= 3 ? searchQuery : '',
    500
  );
  const [hyperLocalValue, setHyperLocaValue] = useState(
    locationStore.address ?? ''
  );
  const [filters, setFilters] = useState<ISearchFilter>(
    appDataManager.data.filters
  );
  const [showHyperLocal, setShowHyperLocal] = useState(false);
  const [showFilters, setShowFilter] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const { slug } = useParams<IRouteParams>();

  useEffect(() => {
    if (!debouncedText) {
      return;
    }

    navigate(RoutesEnum.Search + '?query=' + debouncedText);
  }, [debouncedText]);

  const logOutUser = async () => {
    try {
      setIsLoggingOut(true);
      await logOut();

      closeAllAtiveChats();

      userStore.saveUserToStore(undefined);
      userStore.saveAddress(undefined, undefined, undefined);
      userStore.savePaymentMethods([]);
      likedMedia.saveToStore([]);
      stripeStore.setClientSecret(undefined);
      stripeStore.setPublicKey(undefined);
      bookingsStore.saveToStore([]);
      followUsers.saveToStore([]);

      appToast.showSuccess('You are now logged out!');
    } catch (error) {
      appToast.showError(DEFAULT_ERROR_MESSAGE);
    } finally {
      setIsLoggingOut(false);
    }
  };

  const isAValidRouteForSearch = (route: string) => {
    const validRoutes = [
      RoutesEnum.Experiences,
      RoutesEnum.Root,
      RoutesEnum.Clips
    ];

    return validRoutes.some((path) => path.match(route));
  };

  const handleToggleHyperLocal = () => {
    /**
     * Request and update user location if he has granted permissions
     */
    if (!showHyperLocal) {
      getCurrentCoords(
        async (coords) => {
          //Update hyper local coords if user has selected my location
          if (appDataManager.data?.hyperLocal?.fullAddress === MY_LOCATION) {
            locationStore.updateCoords(appDataManager.data.hyperLocal?.coords);
          }

          const addressData = await generateFormattedAddress(coords);

          userStore.saveAddress(
            addressData.formattedAddress,
            addressData.addressResult,
            addressData.latLng
          );
        },
        () => {}
      );
    }

    setShowFilter(false);
    setShowHyperLocal(!showHyperLocal);
  };

  const handleToggleFilters = () => {
    setShowHyperLocal(false);
    setShowFilter(!showFilters);
  };

  const userMenu: ListItemProps[] = useMemo<ListItemProps[]>(() => {
    const navMenu: ListItemProps[] = [];
    const restrictedMenu: ListItemProps[] = [];

    const goToHome = () => navigate(RoutesEnum.Root);
    const goToProfile = () => navigate(`/u/${userStore.user.userName}`);
    const goToCreateEvent = () => navigate(RoutesEnum.CreateEvent);
    const goToBookings = () => navigate(RoutesEnum.Bookings);
    const goToDownloadTickets = () => navigate(RoutesEnum.GuestTicketsSignIn);
    const goToGrammy = () => {
      //   const grammyAccessPermission = getGrammyUserAccessPermission(
      //     userStore.user.email
      //   );
      //   navigate(
      //     grammyAccessPermission === 'grammy_checkin'
      //       ? RoutesEnum.RegistrationAttendees
      //       : RoutesEnum.RegistrationInviteeList
      //   );
    };
    const goToCheckIn = () => navigate(RoutesEnum.EventsManager);
    const handleDownloadApp = () =>
      downloadApp(iOS() ? 'ios' : isAndroid() ? 'android' : null);

    /**
     * Add menu items in this order
     */

    navMenu.push({
      name: 'Explore',
      callback: goToHome,
      icon: <HooplaSparksIcon />
    });

    if (userStore.user) {
      navMenu.push({
        name: 'Profile',
        callback: goToProfile,
        icon: <UserIcon />
      });

      if (userStore.user.isProviderPro || userStore.user.isCreatorPro) {
        navMenu.push({
          name: 'Create Event',
          callback: goToCreateEvent,
          icon: <CreateEventIcon />
        });
      }

      if (SUPER_USERS.includes(userStore.user.userName.toLowerCase())) {
        navMenu.push({
          name: 'Check-In',
          callback: goToCheckIn,
          icon: <CheckInIcon />
        });
      }
    }

    navMenu.push({
      name: userStore.user ? 'Your Tickets' : 'Download your Tickets',
      callback: userStore.user ? goToBookings : goToDownloadTickets,
      icon: <TicketsIcon />
    });

    navMenu.push({
      name: 'Download App',
      callback: isMobileDevice() ? handleDownloadApp : null,
      list: [
        {
          name: <AppStoreIcon />,
          callback: () => downloadApp('ios')
        },
        {
          name: <GooglePlayIcon />,
          callback: () => downloadApp('android')
        }
      ],
      icon: <DownArrowIcon />
    });

    if (userStore.user) {
      navMenu.push({
        name: 'Log out',
        callback: logOutUser,
        icon: <LogOutIcon />
      });
    } else {
      navMenu.push({
        name: 'Sign In',
        callback: showAuthModal.bind(null, 'sign_in'),
        icon: <LogOutIcon />
      });
    }

    return navMenu;
  }, [userStore.user]);

  return (
    <>
      {isLoggingOut && <Loader />}

      <div
        className={classNames('Header', className, {
          'Header--sm': title,
          'Header--auto': subSection || title
        })}
      >
        <div className='Header__wrapper'>
          <div className='Header__content'>
            <div className='Header__topSection'>
              <Link className='Header__logoContainer' to={RoutesEnum.Root}>
                {isMobileDevice() ? (
                  <HooplaCircledLogo className='Header__logo--small' />
                ) : (
                  <HooplaLogo className='Header__logo' />
                )}
              </Link>

              <div className='Header__session'>
                {/* <Button
                  className='Header__whatIsHoopla me-2'
                  size='auto'
                  variant='text'
                  onClick={() => window.open('https://hoopla.com/')}
                >
                  What is Hoopla?
                </Button> */}

                <DropdownMenu
                  className='Header__userMenu'
                  header={
                    userStore.user ? (
                      <div
                        className='Header__userCard mb-1'
                        onClick={() => {
                          navigate(`/u/${userStore.user.userName}`);
                        }}
                      >
                        <Avatar
                          className='Header__user mb-3'
                          size={isMobileDevice() ? 'small' : 'medium'}
                          symbol={userStore.user.userName[0]}
                          image={
                            userStore.user.image && (
                              <img
                                src={getMediaFile(userStore.user.image)}
                                alt={userStore.user.userName}
                              />
                            )
                          }
                        />
                        <div className='Header__userName oneLineText'>
                          {userStore.user.fullName}
                        </div>
                        {userStore.followers?.length > 0 && (
                          <div className='Header__userFollowers'>
                            {numFormatter(userStore.followers.length)} followers
                          </div>
                        )}
                      </div>
                    ) : null
                  }
                  list={userMenu}
                  align='left'
                >
                  <div className='Header__userMenuTrigger'>
                    <MenuIcon />
                  </div>
                </DropdownMenu>
              </div>
            </div>

            {subSection && (
              <div className='Header__bottomSection'>{subSection}</div>
            )}

            {title && (
              <div className='Header__bottom'>
                {icon && typeof icon === 'string' ? (
                  <img
                    className='Header__icon'
                    src={getMediaFile(icon)}
                    alt='Header Icon'
                  />
                ) : (
                  icon
                )}
                {title && (
                  <div
                    className={classNames('Header__title', {
                      'Header__title--spaced': !!icon
                    })}
                  >
                    {title}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {isAValidRouteForSearch(location.pathname) && (
          <div className='Header__filters'>
            <div className='Header__searchWrapper'>
              <SearchInput
                className='Header__search'
                isHyperLocalActive={showHyperLocal || !!locationStore.coords}
                showFilterAction={location.pathname === RoutesEnum.Root}
                isFilterPanelActive={showFilters}
                value={searchQuery}
                onChange={(value) => {
                  if (!value) {
                  }

                  setSearchQuery(value);
                }}
                onClickHyperLocal={handleToggleHyperLocal}
                onClickFilters={handleToggleFilters}
              />

              {showHyperLocal && (
                <HyperLocal
                  className='Header__floatingPanels'
                  value={hyperLocalValue}
                  onValueChange={(text) => setHyperLocaValue(text)}
                  onSelectLocation={async ({ fullAddress }, coords) => {
                    locationStore.saveLocation(fullAddress, coords);
                    appDataManager.updateHyperLocalData(fullAddress, coords);
                    navigate(RoutesEnum.Search);
                  }}
                  onClearSelection={() => {
                    setHyperLocaValue('');
                    locationStore.saveLocation(undefined, undefined);
                    appDataManager.updateHyperLocalData(undefined, undefined);
                  }}
                />
              )}

              {showFilters && (
                <SearchFilters
                  className='Header__floatingPanels'
                  values={filters}
                  onChange={(selectedFilters) => {
                    setFilters(selectedFilters);
                    appDataManager.updateFilters(selectedFilters);
                  }}
                />
              )}
            </div>

            <CategoryMenu
              className='Header__categories'
              items={categoryStore.categories}
              activeCategory={slug}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
