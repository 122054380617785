import * as yup from 'yup';
import dayjs from 'dayjs';

const validationSchema = yup.object().shape({
  userName: yup
    .string()
    .required('Required')
    .min(4, 'Minimum length of Username should be 4 characters')
    .max(18, 'Maximum length of Username should be 18 characters')
    .test(
      'userName',
      'Username cannot contain whitespaces',
      function (value: string) {
        if (!value) return true;

        return !value.includes(' ');
      }
    )
    .test(
      'userName',
      'Username cannot contain “Hoopla” word',
      function (value: string) {
        if (!value) return true;

        return !value.toLowerCase().includes('hoopla');
      }
    ),
  fullName: yup
    .string()
    .required('Required')
    .min(3, 'Minimum length of Full Name should be 3 characters'),
  phoneNumber: yup.string().required('Required').min(10, 'Invalid Phone Number')
  // dateOfBirthSeconds: yup
  //   .string()
  //   .nullable()
  //   .test(
  //     'dateOfBirthSeconds',
  //     'You must be 16 years old at least',
  //     function (value: any) {
  //       if (!value) return true;
  //       return dayjs().diff(value, 'year') >= 16;
  //     }
  //   )
});

export default validationSchema;
