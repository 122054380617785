import { makeAutoObservable } from 'mobx';
import { getMediaFile } from '../api';
import { GroupedFileViewModel } from '../backend/models';
import { IVideoModel, MediaFile, Thumbnails } from '../types';
import ExperienceModel from './ExperienceModel';

class GroupedVideoModel implements IVideoModel {
  _video: GroupedFileViewModel = null;
  _experience: ExperienceModel = undefined;
  _thumbnails: Thumbnails = undefined;
  _mediaFiles: MediaFile[] = [];

  constructor(video: GroupedFileViewModel) {
    makeAutoObservable(this);

    this._video = video;
    this._experience = video.linkedGig
      ? new ExperienceModel(video.linkedGig)
      : undefined;
    this._processFile();
  }

  _processFile() {
    const _sortedFiles = this._video.mediaFiles?.sort((a, b) =>
      a.awsUrl.endsWith('.mp4') ? -1 : a.order - b.order
    );

    if (!_sortedFiles?.length) return;

    const _file = _sortedFiles[0];

    const staticFiles: string[] = (
      _file?.thumbnails?.mobile as any
    )?.static.slice();
    const videoPreview = _file?.thumbnails?.mobile?.animated
      ?.slice()
      .find((file) => file.endsWith('.mp4'));
    const webFiles: string[] = _file?.thumbnails?.web.slice();

    this._thumbnails = {
      video: videoPreview
        ? getMediaFile(videoPreview)
        : _file?.thumbnails?.mobile?.animated[0]
        ? getMediaFile(_file?.thumbnails?.mobile?.animated[0])
        : getMediaFile(_file.awsUrl),
      animated: getMediaFile(
        webFiles && webFiles[0] ? webFiles[0] : _file.awsUrl
      ),
      static: getMediaFile(
        staticFiles && staticFiles[0] ? staticFiles[0] : _file.awsUrl
      )
    };

    this._mediaFiles = _sortedFiles.map((file) => ({
      id: file.id,
      url: getMediaFile(file.awsUrl),
      isVideo: file.awsUrl.endsWith('mp4')
    }));
  }

  get id() {
    return this._mediaFiles[0]?.id ?? 0;
  }

  get description() {
    return this._video.mediaFiles[0]?.description ?? '';
  }

  get views() {
    return this._video.groupTotalViewedTimes;
  }

  set views(count: number) {
    this._video.groupTotalViewedTimes = count;
  }

  get likes() {
    return this._video.groupTotalLikes;
  }

  set likes(count: number) {
    this._video.groupTotalLikes = count;
  }

  get commentsCount() {
    return this._video.groupTotalComments;
  }

  set commentsCount(count: number) {
    this._video.groupTotalComments = count;
  }

  fileViews?(fileId: number) {
    const _file = this._video.mediaFiles.find((file) => file.id === fileId);

    return _file?.viewedTimes ?? 0;
  }

  get owner() {
    return this._video.owner;
  }

  get mediaUrl() {
    return this._mediaFiles[0] ? getMediaFile(this._mediaFiles[0]?.url) : '';
  }

  get mediaFiles(): Array<MediaFile> {
    return this._mediaFiles;
  }

  set mediaFiles(mediaFiles: Array<MediaFile>) {
    this._mediaFiles = mediaFiles;
  }

  get thumbnails() {
    return this._thumbnails;
  }

  get linkedExperience() {
    return this._experience;
  }
}

export default GroupedVideoModel;
