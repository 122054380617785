import { useEffect } from 'react';
import Button from '../Button/Button';
import { ReactComponent as MailIcon } from '../../icons/email.svg';
import './EmailAuthButton.scss';
import classNames from 'classnames';

interface Props {
  type?: 'signin_with' | 'signup_with';
  shape?: 'standard' | 'icon';
  onClick: () => void;
}

const EmailAuthButton = ({
  type = 'signin_with',
  shape = 'standard',
  onClick
}: Props) => {
  return (
    <Button
      className={classNames('EmailAuthButton', {
        'EmailAuthButton--icon': shape === 'icon'
      })}
      onClick={onClick}
    >
      <div className='EmailAuthButton__wrapper'>
        <MailIcon className='EmailAuthButton__icon' />
        {shape === 'standard' && (
          <div className='EmailAuthButton__label'>
            {type === 'signin_with' ? 'Sign in' : 'Sign up'} with Email
          </div>
        )}
      </div>
    </Button>
  );
};

export default EmailAuthButton;
