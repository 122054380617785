import { BookingViewModel } from './../../../core/backend/models/booking-view-model';
import {
  getCreatorBookings,
  getCreatorStats,
  getFollowingUsers,
  getGigsByUser,
  getMemoriesByUser,
  getOwnLikes,
  getUserBookings,
  getUserFollowers,
  getUserPaymentMethods
} from '../../../core/api';
import {
  FollowerViewModel,
  LikeViewModel,
  PaymentMethodViewModel,
  ProviderSummaryResponse,
  UserFollowingViewModel
} from '../../../core/backend/models';
import { APPLE_PAY_METHOD, GOOGLE_PAY_METHOD } from '../../../core/consts';
import { ProfileTabsEnum } from '../../../core/enums';
import {
  convertGigModelToEventsWithStatus,
  iOS,
  isAppleDevice,
  isChromeBrowser,
  isInstagramInAppBrowser,
  isSafariBrowser
} from '../../../core/helpers';
import { EventsWithStatus, IVideoModel } from '../../../core/types';
import GroupedVideoModel from '../../../core/models/GroupedVideoModel';

interface SessionData {
  userBookings: BookingViewModel[];
  creatorBookings: BookingViewModel[];
  userLikes: LikeViewModel[];
  paymentMethods: PaymentMethodViewModel[];
  following: UserFollowingViewModel[];
}

interface ProfileData {
  events: EventsWithStatus;
  clips: IVideoModel[];
  followers: FollowerViewModel[];
  summary: ProviderSummaryResponse;
}

export const getAllUserData = async (
  getBookings = false
): Promise<SessionData> => {
  let creatorBookings: BookingViewModel[] = [];
  const userBookings = await getUserBookings();
  const userLikes = await getOwnLikes();
  const paymentMethods = await getUserPaymentMethods();
  const following = await getFollowingUsers();

  if (getBookings) creatorBookings = await getCreatorBookings();

  const _paymentMethods: PaymentMethodViewModel[] = [];
  if (isAppleDevice() && (isSafariBrowser() || isInstagramInAppBrowser()))
    _paymentMethods.push(APPLE_PAY_METHOD);

  if (!iOS() && (isChromeBrowser() || isInstagramInAppBrowser()))
    _paymentMethods.push(GOOGLE_PAY_METHOD);

  return {
    userBookings,
    creatorBookings,
    userLikes,
    paymentMethods: _paymentMethods.concat(paymentMethods),
    following
  };
};

export const getUserProfileData = async (
  userId: string,
  isCreator: boolean
): Promise<ProfileData> => {
  try {
    let eventsWithStatus: EventsWithStatus = {
      [ProfileTabsEnum.Upcoming]: [],
      [ProfileTabsEnum.Past]: [],
      [ProfileTabsEnum.Cancelled]: []
    };

    if (isCreator) {
      const events = await getGigsByUser(userId, 0, 1000);
      const mappedEvents = convertGigModelToEventsWithStatus(events);
      eventsWithStatus = mappedEvents;
    }

    const clipsResponse = await getMemoriesByUser(userId, 0, 12);
    const clips = clipsResponse.map<IVideoModel>(
      (video) => new GroupedVideoModel(video)
    );
    const summary = await getCreatorStats(userId);
    const followers = await getUserFollowers(userId);

    return {
      events: eventsWithStatus,
      clips,
      summary,
      followers
    };
  } catch (e: any) {}
};
