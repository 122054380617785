import { Scrollbars } from 'react-custom-scrollbars';
import { getMediaFile } from '../../../core/api';
import { AttendeeViewModel } from '../../../core/backend/models';
import Button from '../../Button/Button';
import Modal, { IModalProps } from '../Modal';
import { ReactComponent as CrossIcon } from '../../../icons/cross.svg';
import './AttendeesModal.scss';
import Avatar from '../../Avatar/Avatar';
import FollowButton from '../../FollowButton/FollowButton';
import { useStores } from '../../../hooks';

export interface IProps extends IModalProps {
  attendees: AttendeeViewModel[];
  bookingCount?: number;
}

const AttendeesModal = ({
  isOpened,
  attendees,
  bookingCount,
  ...restProps
}: IProps) => {
  const { userStore, followUsers } = useStores();
  return (
    <Modal
      isOpened={isOpened}
      full={false}
      showCloseButton={false}
      {...restProps}
    >
      <div className='AttendeesModal'>
        <div className='AttendeesModal__title'>
          Bookings: {bookingCount}
          <Button
            className='AttendeesModal__close'
            variant='icon'
            onClick={restProps.close}
          >
            <CrossIcon className='AttendeesModal__closeIcon' />
          </Button>
        </div>

        <div className='AttendeesModal__content'>
          <Scrollbars autoHeight autoHeightMin={100} autoHeightMax='100%'>
            <div className='AttendeesModal__attendeesList'>
              {attendees?.length > 0 &&
                attendees.map((attendee) => (
                  <div key={attendee.id} className='AttendeesModal__attendee'>
                    <Avatar
                      className='AttendeesModal__attendeeImage'
                      size='small'
                      image={
                        attendee.image && (
                          <img
                            key={attendee.id}
                            title={attendee.userName}
                            alt={attendee.userName}
                            src={getMediaFile(attendee.image)}
                          />
                        )
                      }
                      symbol={attendee.userName[0]}
                    />
                    <div className='AttendessModal__attendeeInfo'>
                      <div className='AttendessModal__attendeeUsername oneLineText'>
                        @
                        {attendee.userName.startsWith('Guest#') ||
                        attendee.userName.startsWith('Generic')
                          ? attendee.fullName
                          : attendee.userName}
                      </div>
                    </div>
                    {(!userStore.user || userStore.user.id !== attendee.id) &&
                      !attendee.expressCheckout && (
                        <FollowButton
                          className='AttendeesModal__followButton'
                          size='sm'
                          user={attendee}
                          isFollowing={
                            userStore.user
                              ? followUsers.isFollowingUser(
                                  userStore.user.id,
                                  attendee.id
                                )
                              : false
                          }
                        />
                      )}
                  </div>
                ))}
            </div>
          </Scrollbars>
        </div>
      </div>
    </Modal>
  );
};

export default AttendeesModal;
