import React, { ReactElement, useState } from 'react';
import './DropdownMenu.styles.scss';
import Tooltip from '../Tooltip/Tooltip';
import { ReactComponent as HorizontalDotsIcon } from '../../icons/horizontal-dots.svg';
import cn from 'classnames';
import Dropdown from '../Dropdown/Dropdown';
import classNames from 'classnames';

export interface ListItemProps {
  name: string | React.ReactNode;
  list?: Array<ListItemProps>;
  icon?: any;
  dataTestid?: string;
  className?: string;
  callback?: () => void;
}

interface Props {
  header?: string | React.ReactNode;
  children?: ReactElement<any>;
  list: Array<ListItemProps>;
  className?: string;
  withPortal?: boolean;
  withToolitip?: boolean;
  align?: 'right' | 'left' | 'center';
  buttonClassName?: string;
  menuListClassName?: string;
  fullScreen?: boolean;
}

const DropdownMenu: React.FC<Props> = ({
  list,
  className = '',
  header,
  children,
  align = 'right',
  withPortal = false,
  withToolitip = false,
  buttonClassName = '',
  menuListClassName = '',
  fullScreen = false
}) => {
  const [selectedItem, setSelectedItem] = useState<number | null>(null);

  const handleClick = (
    callback: any,
    closeDropdown: () => void,
    index: number,
    e: React.MouseEvent
  ) => {
    e.stopPropagation();

    if (!callback && index) {
      setSelectedItem(index === selectedItem ? null : index);
    } else {
      callback();
      closeDropdown();
    }

    (e.target as HTMLDivElement).setAttribute('disabled', 'disabled');
  };

  const renderList = (closeDropdown: () => void) => {
    return (
      <div
        className={cn('DropdownMenu__list', menuListClassName, {
          'DropdownMenu__list--fullScreen': fullScreen
        })}
      >
        {header && <div className='DropdownMenu__header'>{header}</div>}
        {list.map((listItem, index) => {
          return (
            <div
              className={classNames(
                'DropdownMenu__itemWrapper',
                listItem.className
              )}
              key={index}
            >
              <button
                data-testid={listItem.dataTestid}
                className={cn('DropdownMenu__item', {
                  'DropdownMenu__item--fullScreen': fullScreen,
                  'DropdownMenu__item--selected': index === selectedItem
                })}
                type='button'
                onClick={handleClick.bind(
                  null,
                  listItem.callback,
                  closeDropdown,
                  index
                )}
              >
                {listItem.icon ? (
                  <span className='DropdownMenu__item-icon'>
                    {listItem.icon}
                  </span>
                ) : null}
                <span className={`DropdownMenu__item-text`}>
                  {listItem.name}
                </span>
              </button>
              {listItem.list?.length > 0 && (
                <div
                  className={cn('DropdownMenu__subList', {
                    'DropdownMenu__subList--display': index === selectedItem
                  })}
                >
                  {listItem.list.map((subItem, subIndex) => {
                    return (
                      <button
                        className='DropdownMenu__item'
                        key={'subItem-' + subIndex}
                        type='button'
                        onClick={handleClick.bind(
                          null,
                          subItem.callback,
                          closeDropdown,
                          null
                        )}
                      >
                        {subItem.icon ? (
                          <span className='DropdownMenu__item-icon'>
                            {subItem.icon}
                          </span>
                        ) : null}
                        <span className={`DropdownMenu__item-text`}>
                          {subItem.name}
                        </span>
                      </button>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const trigger = children ? (
    children
  ) : (
    <div
      className={`
        DropdownMenu__button
        ${buttonClassName}
      `}
    >
      <HorizontalDotsIcon />
    </div>
  );

  const triggerElem = !withToolitip ? (
    trigger
  ) : (
    <Tooltip
      withPortal
      className='d-flex align-items-center justify-content-center'
      trigger={trigger}
      content={<>Menu</>}
      triggerType='hover'
    />
  );

  return (
    <Dropdown
      className={`DropdownMenu ${className}`}
      trigger={triggerElem}
      align={align}
      withPortal={withPortal}
      fullScreen={fullScreen}
    >
      {renderList}
    </Dropdown>
  );
};

export default DropdownMenu;
