import { FormikErrors, useFormik } from 'formik';
import { useState } from 'react';
import { requestPhoneSMSCode } from '../../../../../core/api';
import { DEFAULT_ERROR_MESSAGE } from '../../../../../core/validators';
import Button from '../../../../Button/Button';
import { useAuthModal } from '../../AuthModal';
import appToast from '../../../../../core/toast';
import Loader from '../../../../Loader/Loader';
import { Country, isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInput from '../../../../PhoneInput/PhoneInput';
import './PhoneNumberForm.scss';

interface FormValues {
  phoneNumber: string;
}

const PhoneNumberForm = () => {
  const { showAuthModal, formData } = useAuthModal();
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState<string>(null);

  const { handleSubmit, setFieldValue, values, errors } = useFormik<FormValues>(
    {
      initialValues: { phoneNumber: formData?.phoneNumber ?? '' },
      validateOnChange: false,
      validateOnBlur: false,
      validate: (values) => {
        setServerError(null);
        const errors: FormikErrors<FormValues> = {};
        if (!isValidPhoneNumber(values.phoneNumber)) {
          errors.phoneNumber = 'Invalid phone number';
        }

        return errors;
      },
      onSubmit: (values) => {
        const { phoneNumber } = values;
        setIsLoading(true);
        sendSMSCodeToPhone(phoneNumber);
      }
    }
  );

  const sendSMSCodeToPhone = async (phoneNumber: string) => {
    try {
      await requestPhoneSMSCode(encodeURIComponent(phoneNumber));
      showAuthModal('sms_code', values);
      appToast.showSuccess('We sent you a code to your phone number');
    } catch (e: any) {
      appToast.showError('Your phone number is not registered yet');
      showAuthModal('sign_up', formData);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSwitchSignUpForm = () => {
    showAuthModal('sign_up', formData);
  };

  return (
    <form className='PhoneNumberForm' onSubmit={handleSubmit}>
      {serverError && (
        <div className='PhoneNumberForm__errorMessage'>{serverError}</div>
      )}

      <div className='PhoneNumberForm__inputs'>
        <PhoneInput
          className='PhoneNumberForm__phoneNumberInput'
          label='Enter Your Phone Number'
          value={values.phoneNumber}
          error={errors.phoneNumber}
          onChange={(phoneNumber) => {
            setFieldValue('phoneNumber', phoneNumber);
          }}
        />
      </div>

      <div className='PhoneNumberForm__buttons'>
        <Button
          className='PhoneNumberForm__signInButton'
          size='full-width'
          type='submit'
          disabled={isLoading}
        >
          {isLoading ? (
            <Loader light fixed={false} showLogo={false} width='32px' />
          ) : (
            'Continue'
          )}
        </Button>

        <div className='PhoneNumberForm__createAccount'>
          Don't have an account?{' '}
          <Button
            className='PhoneNumberForm__signUp'
            variant='text'
            onClick={handleSwitchSignUpForm}
          >
            Sign Up
          </Button>
        </div>
      </div>
    </form>
  );
};

export default PhoneNumberForm;
