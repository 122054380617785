import React, { useState, useRef } from 'react';
import './Tooltip.styles.scss';
import TooltipContent from './TooltipContent';
import { useOnClickOutside } from '../../hooks';
import { isMobileDevice } from '../../core/helpers';

export type Placement = 'top' | 'bottom' | 'left' | 'right';

export interface Props {
  trigger: JSX.Element;
  content: JSX.Element;
  placement?: Placement;
  triggerType?: 'hover' | 'click';
  className?: string;
  maxWidth?: number;
  withPortal?: boolean;
  conatinerRef?: React.MutableRefObject<any>;
  disabled?: boolean;
  minWidth?: number;
  autoMaxWidth?: boolean;
  textAlign?: 'right' | 'left' | 'center';
}

const Tooltip: React.FC<Props> = ({
  trigger,
  content,
  className = '',
  withPortal = false,
  placement = 'top',
  triggerType = 'hover',
  disabled,
  maxWidth,
  minWidth = 56,
  autoMaxWidth = true,
  textAlign = 'center'
}) => {
  const parent = useRef(null);
  const [isTriggered, setIsTriggered] = useState(false);

  const isTouch = 'ontouchend' in document;

  const hoverHandler = (show: boolean) => () => {
    if (
      !disabled &&
      triggerType === 'hover' &&
      (!isMobileDevice() || !isTouch)
    ) {
      setIsTriggered(show);
    }
  };

  const clickHandler = () => {
    if (!disabled && (triggerType === 'click' || isMobileDevice() || isTouch)) {
      setIsTriggered(!isTriggered);
    }
  };

  useOnClickOutside(parent, () => isTriggered && clickHandler());

  return (
    <div className={`Tooltip ${className}`}>
      <div
        className='Tooltip__container'
        onMouseEnter={hoverHandler(true)}
        onMouseLeave={hoverHandler(false)}
        onClick={clickHandler}
        ref={parent}
      >
        {trigger}
        {isTriggered && (
          <TooltipContent
            style={{
              minWidth,
              maxWidth: autoMaxWidth
                ? parent.current?.offsetWidth > maxWidth
                  ? maxWidth
                  : parent.current?.offsetWidth
                : maxWidth,
              textAlign
            }}
            parent={parent}
            placement={placement}
            content={content}
            withPortal={withPortal}
            onScroll={() => setIsTriggered(false)}
          />
        )}
      </div>
    </div>
  );
};

export default Tooltip;
