import { memo } from 'react';
import './DataList.scss';

interface Props<T> {
  className?: string;
  listClassName?: string;
  data: T[];
  emptyListMessage?: string;
  emptyListComponent?: React.ReactNode;
  renderItem: (item: T, index: number) => React.ReactNode;
}

const DataList = <T extends {}>({
  data,
  emptyListMessage = 'No data to show',
  renderItem,
  className = '',
  listClassName = '',
  emptyListComponent
}: Props<T>) => {
  return (
    <div className={`DataList ${className}`}>
      {data.length > 0 ? (
        <div className={`DataList__list ${listClassName}`}>
          {data.map(renderItem)}
        </div>
      ) : (
        emptyListComponent ?? (
          <div className='DataList__empty'>{emptyListMessage}</div>
        )
      )}
    </div>
  );
};

export default memo(DataList) as typeof DataList;
