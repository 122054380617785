import { Link } from 'react-router-dom';
import { UserViewModel } from '../../core/backend/models';
import './Mentions.scss';

interface IProps {
  content: string;
  taggedUsers: UserViewModel[];
}

const Mentions = ({ content, taggedUsers }: IProps) => {
  return (
    <>
      {taggedUsers.length
        ? content.split(/\s/).map((slice) => {
            const user = taggedUsers.find(
              (user) => '@' + user.userName === slice
            );
            if (user)
              return (
                <>
                  <Link className='Mentions__link' to={`/u/${user.userName}`}>
                    @{user.userName}
                  </Link>{' '}
                </>
              );

            return slice + ' ';
          })
        : content}
    </>
  );
};

export default Mentions;
