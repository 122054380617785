import { makeAutoObservable } from 'mobx';
import { CategoryViewModel } from '../core/backend/models';

class CategoryStore {
  categories: CategoryViewModel[] = [];
  topCategories: CategoryViewModel[] = [];

  constructor() {
    makeAutoObservable(this);
  }
  saveToStore = (categories: CategoryViewModel[]) => {
    this.categories = categories;
  };

  saveTopCategories = (categories: CategoryViewModel[]) => {
    this.topCategories = categories;
  };
}

export default CategoryStore;
