import { makeAutoObservable } from 'mobx';
import { BookingViewModel, CategoryViewModel } from '../core/backend/models';

class BookingsStore {
  fetched = false;
  userBookings: BookingViewModel[] = [];
  creatorBookings: BookingViewModel[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  saveToStore = (
    userBookings: BookingViewModel[],
    creatorBookings?: BookingViewModel[]
  ) => {
    this.userBookings = userBookings;
    this.creatorBookings = creatorBookings;
    this.fetched = true;
  };

  saveBooking = (booking: BookingViewModel) => {
    this.userBookings = [...this.userBookings, booking];
  };

  getBooking = (bookingId: number) => {
    const booking = this.userBookings.find(
      (booking) => booking.id === bookingId
    );

    return (
      booking ??
      this.creatorBookings.find((booking) => booking.id === bookingId)
    );
  };

  getMostRecentBookingByExperience = (experienceId: number) => {
    return this.userBookings
      .sort((a, b) => b.timestampUtc - a.timestampUtc)
      .find((booking) => booking.gig.id === experienceId);
  };
}

export default BookingsStore;
