import { useState } from 'react';
import appToast from '../../core/toast';
import { AddressFields, ICoordinates } from '../../core/types';
import usePlacesAutocomplete from '../../hooks/usePlacesAutocomplete';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import './HyperLocal.scss';
import {
  extractAddressFields,
  getCurrentCoords,
  getCurrentLocationPermission,
  isSafariBrowser
} from '../../core/helpers';
import { MY_LOCATION } from '../../core/consts';
import { ReactComponent as LocationFilled } from '../../icons/locationfilled.svg';
import { ReactComponent as HyperLocalIcon } from '../../icons/hyperlocalicon.svg';
import UserLocationModal from '../Modal/UserLocationModal/UserLocationModal';

interface IProps {
  className?: string;
  value: string;
  onValueChange: (text: string) => void;
  onSelectLocation: (address: AddressFields, coords: ICoordinates) => void;
  onClearSelection: () => void;
}

const HyperLocal = ({
  className = '',
  value,
  onSelectLocation,
  onValueChange,
  onClearSelection
}: IProps) => {
  const [isGettingAddress, setIsGettingAddress] = useState(false);
  const [showUserLocationModal, setShowUserLocationModal] = useState(false);
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading
  } = usePlacesAutocomplete({
    sessionToken: true,
    debounce: 500
  });

  const handleLocationTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    getPlacePredictions({ input: e.currentTarget.value });
    onValueChange(e.currentTarget.value);
  };

  const handleSelectLocation = (placeId: string) => () => {
    try {
      setIsGettingAddress(true);

      placesService?.getDetails({ placeId }, (placeDetails: any) => {
        onSelectLocation(extractAddressFields(placeDetails), {
          lat: placeDetails.geometry.location.lat(),
          lng: placeDetails.geometry.location.lng()
        });

        getPlacePredictions({ input: null });
      });
    } catch (e: any) {
      appToast.showError('Could not get suggestions. Try again.');
    } finally {
      setIsGettingAddress(false);
    }
  };

  const handleSelectCurrentLocation = () => {
    setIsGettingAddress(true);

    getCurrentCoords(
      (coords) => {
        onSelectLocation({ fullAddress: MY_LOCATION }, coords);
        setIsGettingAddress(false);
        handleCloseUserLocationModal();
      },
      (errorMessage, geolocationError) => {
        appToast.showError(errorMessage);
        setIsGettingAddress(false);
      }
    );
  };

  const handleCloseUserLocationModal = () => {
    setShowUserLocationModal(false);
  };

  return (
    <div className={`HyperLocal ${className}`}>
      {isGettingAddress && <Loader />}
      <div className='HyperLocal__inputWrapper'>
        <HyperLocalIcon className='HyperLocal__icon' />
        <input
          className='HyperLocal__input'
          placeholder='Search by location'
          value={value}
          onChange={handleLocationTextChange}
        />
        {value.trim().length > 0 && (
          <Button
            className='HyperLocal__clear'
            variant='text'
            onClick={onClearSelection}
          >
            x
          </Button>
        )}
      </div>

      <Button
        className='HyperLocal__myLocationButton'
        variant='text'
        size='full-width'
        onClick={() => {
          if (isSafariBrowser()) {
            return setShowUserLocationModal(true);
          }

          getCurrentLocationPermission().then((permission) => {
            if (permission.state !== 'granted') {
              return setShowUserLocationModal(true);
            }

            handleSelectCurrentLocation();
          });
        }}
      >
        {MY_LOCATION}
      </Button>

      {isPlacePredictionsLoading && (
        <Loader fixed={false} showLogo={false} width='32px' />
      )}

      {placePredictions && placePredictions.length > 0 && (
        <div className='HyperLocal__suggestions'>
          {placePredictions.slice(0, 4).map((suggestion) => (
            <Button
              key={suggestion.place_id}
              className='HyperLocal__locationItem'
              variant='text'
              size='full-width'
              onClick={handleSelectLocation(suggestion.place_id)}
            >
              {suggestion.description
                .replace(', EE. UU.', '')
                .replace(', USA', '')}
            </Button>
          ))}
        </div>
      )}
      <UserLocationModal
        isOpened={showUserLocationModal}
        close={handleCloseUserLocationModal}
        onRequestLocationPermissions={handleSelectCurrentLocation}
      />
    </div>
  );
};

export default HyperLocal;
