import { memo, PropsWithChildren, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Button from '../Button/Button';
import './ShowMore.scss';

interface Props {
  className?: string;
  triggerClassName?: string;
  height?: string;
  lines?: number;
  showLessText?: string;
  showMoreText?: string;
}

const ShowMore: React.FC<PropsWithChildren<Props>> = ({
  children,
  className = '',
  triggerClassName = '',
  height,
  lines,
  showMoreText = 'Show more',
  showLessText = 'Show less'
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const renderedRef = useRef<boolean>(false);
  const [maxHeight, setMaxHeight] = useState<string>('0px');
  const [isOpen, setIsOpen] = useState(false);
  const [showMoreButton, setShowMoreButton] = useState(true);

  useEffect(() => {
    if (renderedRef.current) return;

    let maxContentHeight = height;

    if (!maxContentHeight) {
      const contentLineHeight = getContentLineHeight(contentRef.current);
      maxContentHeight = `${contentLineHeight * (lines || 3)}px`;
    }

    const contentHeight = contentRef.current.offsetHeight;
    const shouldForceToggle = contentHeight > parseInt(maxContentHeight, 10);
    const containerHeight = shouldForceToggle ? maxContentHeight : 'auto';

    console.log({
      children,
      contentHeight,
      maxContentHeight,
      shouldForceToggle,
      containerHeight
    });

    contentRef.current.style.height = containerHeight;
    contentRef.current.style.textOverflow = 'ellipsis';
    setMaxHeight(containerHeight);
    setShowMoreButton(shouldForceToggle);

    renderedRef.current = true;
  }, [children]);

  const handleToggle = () => {
    const newIsOpenState = !isOpen;
    contentRef.current.style.height = isOpen ? maxHeight : 'auto';
    setIsOpen(newIsOpenState);
  };

  const getContentLineHeight = (element: Element) => {
    const elementLineHeight = window.getComputedStyle(element).lineHeight;
    return elementLineHeight === 'normal' ? 24 : parseFloat(elementLineHeight);
  };

  console.log('ShowMore render');

  return (
    <div className='ShowMore'>
      <div
        ref={contentRef}
        className={classNames(`ShowMore__content ${className}`, {
          'ShowMore__content--closed': !isOpen
        })}
      >
        {children}
      </div>
      {showMoreButton && (
        <button className={triggerClassName} onClick={handleToggle}>
          {isOpen ? showLessText : showMoreText}
        </button>
      )}
    </div>
  );
};

export default memo(ShowMore) as typeof ShowMore;
