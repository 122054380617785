import './Avatar.scss';
import classNames from 'classnames';
import ProBadge from '../ProBadge/ProBadge';
import { SizeType } from '../../core/types';
interface Props {
  symbol?: string;
  className?: string;
  image?: JSX.Element;
  size?: SizeType;
  showProBadge?: boolean;
  showShadow?: boolean;
  showBorder?: boolean;
}

const Avatar = ({
  symbol,
  className = '',
  image,
  size = 'medium',
  showProBadge = false,
  showShadow = false,
  showBorder = true
}: Props) => {
  return (
    <div
      className={classNames(`Avatar ${className}`, {
        'Avatar--border': showBorder,
        'Avatar--xs': size === 'xs',
        'Avatar--small': size === 'small',
        'Avatar--medium': size === 'medium',
        'Avatar--large': size === 'large',
        'Avatar--xl': size === 'xl',
        'Avatar--shadow': showShadow
      })}
    >
      {image ? (
        <div className='Avatar__image'>{image}</div>
      ) : (
        <span>{symbol}</span>
      )}
      {showProBadge && <ProBadge className='Avatar__badge' />}
    </div>
  );
};

export default Avatar;
