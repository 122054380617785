import ContentLoader from 'react-content-loader';

interface IProps {
  className?: string;
}

const RecurrenceLoader = ({ className = '' }: IProps) => (
  <ContentLoader
    className={className}
    speed={1.3}
    width={507}
    height={160}
    viewBox='0 0 507 160'
    backgroundColor='#e6e6e6'
    foregroundColor='#f5f5f5'
  >
    <rect x='0' y='0' rx='8' ry='8' width='120' height='160' />
    <rect x='128' y='0' rx='8' ry='8' width='120' height='160' />
    <rect x='256' y='0' rx='6' ry='6' width='120' height='160' />
    <rect x='386' y='0' rx='6' ry='6' width='120' height='160' />
  </ContentLoader>
);

export default RecurrenceLoader;
