import ShowMoreText from 'react-show-more-text';
import { getMediaFile } from '../../core/api';
import Avatar from '../Avatar/Avatar';
import Button from '../Button/Button';
import './VideoDescription.scss';
import { IVideoModel } from '../../core/types';
import { ReactComponent as LinkedIcon } from '../../icons/linked.svg';

interface IProps {
  className?: string;
  video: IVideoModel;
  onViewExperience?: () => void;
}

const VideoDescription = ({
  className = '',
  video,
  onViewExperience
}: IProps) => {
  return (
    <div className={`VideoDescription ${className}`}>
      <div className='VideoDescription__authorDetails'>
        <Avatar
          key={video.owner.id}
          className='VideoDescription__authorImage'
          size='small'
          image={
            video.owner.image && (
              <img
                key={video.owner.id}
                title={video.owner.userName}
                alt={video.owner.userName}
                src={getMediaFile(video.owner.image)}
              />
            )
          }
          symbol={video.owner.userName[0]}
          showProBadge={video.owner?.isProviderPro}
        />

        <div className='VideoDescription__authorInfo'>
          <div className='VideoDescription__authorUsername'>
            @{video.owner.userName}
          </div>
          {video.owner.company && (
            <div className='VideoDescription__authorCompany'>
              {video.owner.company}
            </div>
          )}
        </div>
      </div>

      {video?.linkedExperience?.id > 0 && (
        <div className='VideoDescription__viewExperience'>
          <Button
            className='VideoDescription__viewExperienceButton'
            onClick={onViewExperience}
          >
            <LinkedIcon />
            View Original{' '}
            {video?.linkedExperience?.isOneTimeEvent ? 'Event' : 'Experience'}
          </Button>
        </div>
      )}

      {video.description && (
        <ShowMoreText
          className='VideoDescription__description'
          anchorClass='VideoDescription__showMore'
          lines={2}
        >
          {video.description}
        </ShowMoreText>
      )}
    </div>
  );
};

export default VideoDescription;
