import classNames from 'classnames';
import dayjs from 'dayjs';
import { getMediaFile } from '../../../core/api';
import {
  BookingViewModel,
  ChatAppointmentViewModel
} from '../../../core/backend/models';
import ExperienceModel from '../../../core/models/ExperienceModel';
import { useStores } from '../../../hooks';
import Avatar from '../../Avatar/Avatar';
import './BookingAttachment.scss';
import CreatorBadge from '../../CreatorBadge/CreatorBadge';
import ViewsBadge from '../../ViewsBadge/ViewsBadge';
import { ReactComponent as HooplaLogo } from '../../../images/logo_sm.svg';

interface IProps {
  event: ChatAppointmentViewModel;
  booking?: BookingViewModel;
  className?: string;
}

const BookingAttachment = ({ event, booking, className = '' }: IProps) => {
  const { userStore } = useStores();
  const _event = new ExperienceModel(booking.gig);
  const eventDate = dayjs(event.TimestampUtc);
  const formattedEventDate = eventDate.format('MMM DD, YYYY');
  const formattedEventStartTime = eventDate.format('h:mm a');
  const formattedEventEndTime = eventDate
    .add(event.Duration, 'milliseconds')
    .format('h:mm a');
  const address1 = [event.Location ?? event.Address2]
    .filter(Boolean)
    .join(', ');
  const address2 = [event.City, `${event.State} ${event.Zip}`]
    .filter(Boolean)
    .join(', ');

  const user = [0, 3, 4, 6].includes(event.BookingStatus)
    ? booking.customer
    : booking.gig.provider;

  const convertBookingStatus = (code: number) => {
    const byOwnUser = 'Cancelled by You';
    switch (code) {
      case 0:
        return "Awaiting Provider's Confirmation";
      case 1:
        return booking.gig.provider.id === userStore.user.id
          ? byOwnUser
          : 'Cancelled by Creator';
      case 2:
        return 'Confirmed';
      case 3:
        return booking.customer.id === userStore.user.id
          ? byOwnUser
          : 'Cancelled by Customer';
      case 5:
        return 'Completed';
      case 7:
        return 'Auto-Declined';
      default:
        return '';
    }
  };

  const experiencePreview =
    _event.thumbnails?.static ?? _event.thumbnails?.animated;

  return (
    <div
      className={classNames(`BookingAttachment ${className}`, {
        'BookingAttachment--awaiting': event.BookingStatus === 0,
        'BookingAttachment--providerDeclined': event.BookingStatus === 1,
        'BookingAttachment--confirmed': event.BookingStatus === 2,
        'BookingAttachment--customerCancelled': event.BookingStatus === 3,
        'BookingAttachment--complete': event.BookingStatus === 5,
        'BookingAttachment--auto-declined': event.BookingStatus === 7
      })}
    >
      <div className='BookingAttachment__status'>
        {convertBookingStatus(event.BookingStatus)}
      </div>

      <div className='BookingAttachment__body'>
        <div className='BookingAttachment__details'>
          <div className='BookingAttachment__eventName'>{event.GigName}</div>
          <div className='BookingAttachment__row'>{formattedEventDate}</div>
          <div className='BookingAttachment__row'>
            {formattedEventStartTime} - {formattedEventEndTime}
          </div>
          <div className='BookingAttachment__row'>{address1}</div>
          <div className='BookingAttachment__row'>{address2}</div>
          <div className='BookingAttachment__eventPrice'>
            {_event.formattedPrice}
          </div>
        </div>
        <div className='BookingAttachment__video'>
          <div className='BookingAttachment__videoWrapper'>
            <CreatorBadge
              className='BookingAttachment__videoUser'
              user={_event.creator}
            />
            <ViewsBadge
              className='BookingAttachment__videoViews'
              viewsCount={_event.videoViews}
            />
            {experiencePreview ? (
              <img
                className='BookingAttachment__videoThumbnail'
                src={experiencePreview}
              />
            ) : (
              <div className='BookingAttachment__videoPlaceholder'>
                <HooplaLogo />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className='BookingAttachment__customer'>
        <Avatar
          className='BookingAttachment__userImage'
          size='small'
          image={
            user.image && (
              <img src={getMediaFile(user.image)} alt={user.userName} />
            )
          }
          symbol={user.userName[0]}
        />
        <div className='BookingAttachment__customerDetails'>
          <div className='BookingAttachment__customerUsername oneLineText'>
            @{user.userName}
          </div>
          <div className='BookingAttachment__customerName oneLineText'>
            {user.fullName}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingAttachment;
