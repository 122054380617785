import { useFormik } from 'formik';
import dayjs from 'dayjs';
import {
  isValidPhoneNumber,
  formatPhoneNumber,
  Value
} from 'react-phone-number-input';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useEffect, useMemo, useState } from 'react';
import { completeRegistration, uploadUserImage } from '../../../../../core/api';
import { DEFAULT_ERROR_MESSAGE } from '../../../../../core/validators';
import { useStores } from '../../../../../hooks';
import Button from '../../../../Button/Button';
import TextField from '../../../../TextField/TextField';
import './CompleteProfileForm.scss';
import { useAuthModal } from '../../AuthModal';
import Loader from '../../../../Loader/Loader';
import Steps from '../../../../Steps/Steps';
import FileUploader from '../../../../FileUploader/FileUploader';
import Avatar from '../../../../Avatar/Avatar';
import { ReactComponent as HooplaIcon } from '../../../../../images/hooplaicon.svg';
import validationSchema from '../../../../../core/validations/completeProfile';
import appToast from '../../../../../core/toast';
import Filter from '../../../../../core/classes/filter/filter';
import PhoneInput from '../../../../PhoneInput/PhoneInput';

interface FormValues {
  userName: string;
  fullName: string;
  phoneNumber: string;
  // dateOfBirthSeconds?: Date;
  profileImage?: File;
}

interface Step {
  title: string;
  description: string;
  component: React.ReactNode;
}

dayjs.extend(relativeTime);
const filter = new Filter();

const CompleteProfileForm = () => {
  const { userStore } = useStores();
  const { showAuthModal, closeAuthModal, changeAuthModalTitles, formData } =
    useAuthModal();
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState<string>(null);
  const [currentStep, setCurrentStep] = useState(0);

  const {
    submitForm,
    setFieldValue,
    handleChange,
    values,
    errors,
    handleBlur,
    touched
  } = useFormik<FormValues>({
    initialValues: {
      userName: formData?.userName ?? '',
      fullName: formData?.fullName ?? '',
      phoneNumber: ''
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (currentStep === 0) {
        const { userName, fullName, phoneNumber } = values;

        if (filter.isProfane(userName)) {
          return appToast.showError(
            "We're sorry, but it appears that your username contains inappropriate language."
          );
        }

        if (filter.isProfane(fullName)) {
          return appToast.showError(
            "We're sorry, but it appears that your name contains inappropriate language."
          );
        }

        if (!phoneNumber) {
          return appToast.showError('Phone Number is Required');
        } else if (!isValidPhoneNumber(phoneNumber, 'US')) {
          return appToast.showError('Invalid Phone Number');
        }

        completeUserAccount(
          userName,
          fullName,
          formatPhoneNumber(phoneNumber as Value),
          new Date(1990, 8, 26, 0, 0, 0, 0).getTime() / 1000
        );
      } else if (currentStep === 1) {
        const { profileImage } = values;

        if (!profileImage) return setServerError('An Image is Required');

        uploadProfileImage(profileImage);
      }
    }
  });

  useEffect(() => {
    const { title, description } = steps[currentStep];
    changeAuthModalTitles(title, description);
  }, [currentStep]);

  const completeUserAccount = async (
    userName: string,
    fullName: string,
    phoneNumber: string,
    dateOfBirthSeconds: number
  ) => {
    try {
      setIsLoading(true);

      const user = await completeRegistration(
        userName,
        fullName,
        phoneNumber,
        dateOfBirthSeconds
      );
      userStore.saveUserToStore(user);

      setCurrentStep((current) => current + 1);
      setServerError(null);
    } catch (e: any) {
      const error = e.response?.data?.description || DEFAULT_ERROR_MESSAGE;
      setServerError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeProfileImage = async (image: File) => {
    setFieldValue('profileImage', image);
  };

  const uploadProfileImage = async (image: File) => {
    try {
      setIsLoading(true);
      const user = await uploadUserImage(image);
      userStore.saveUserToStore(user);

      closeAndComplete();
    } catch (e: any) {
      const error = e.response?.data?.description || DEFAULT_ERROR_MESSAGE;
      setServerError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePhoneNumberChange = (phoneNumber: string) => {
    setFieldValue('phoneNumber', phoneNumber);
  };

  const closeAndComplete = () => {
    closeAuthModal();
    appToast.showSuccess('Your profile is complete!');
  };

  const handleSwitchSignInForm = () => {
    showAuthModal('sign_in');
  };

  const handleGoBack = () => {
    if (currentStep === 0) return;

    setCurrentStep(currentStep - 1);
  };

  const steps = useMemo<Step[]>(
    () => [
      {
        title: "What's your name?",
        description: 'Enter your username and full name',
        component: (
          <div className='CompleteProfileForm__inputs'>
            <TextField
              label='Username'
              name='userName'
              id='userName'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.userName}
              error={errors.userName}
              touched={touched.userName}
            />

            <TextField
              label='Full Name'
              name='fullName'
              id='fullName'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.fullName}
              error={errors.fullName}
              touched={touched.fullName}
            />

            <div className='mb-2'>
              <PhoneInput
                label='Phone for Verification and Notification'
                value={values.phoneNumber}
                error={errors.phoneNumber}
                onChange={handlePhoneNumberChange}
              />
            </div>
          </div>
        )
      },
      {
        title: 'Almost there',
        description: 'Add a picture to help others identify you on hoopla',
        component: (
          <div className='CompleteProfileForm__uploaderWrapper d-inline-block mx-auto'>
            <FileUploader
              className='CompleteProfileForm__uploader'
              onChange={handleChangeProfileImage}
            >
              <div className='CompleteProfileForm__uploaderBtnText'>
                Change your profile picture
              </div>
              <Avatar
                className='CompleteProfileForm__profileImage'
                size='large'
                image={
                  values.profileImage ? (
                    <img
                      src={URL.createObjectURL(values.profileImage)}
                      alt='avatar'
                    />
                  ) : (
                    <HooplaIcon />
                  )
                }
              />
            </FileUploader>
            <Button
              className='CompleteProfileForm__later'
              onClick={closeAndComplete}
              variant='text'
              disabled={isLoading}
            >
              I'll do this later
            </Button>
          </div>
        )
      }
    ],
    [values, errors, touched]
  );

  return (
    <div className='CompleteProfileForm'>
      {serverError && (
        <div className='CompleteProfileForm__errorMessage'>{serverError}</div>
      )}

      <Steps activeId={currentStep} steps={steps.map((_, i) => i)} />

      <div className='CompleteProfileForm__content'>
        {steps[currentStep].component}
      </div>

      <div className='CompleteProfileForm__buttons'>
        <Button
          className='CompleteProfileForm__back'
          variant='outline'
          onClick={handleGoBack}
          disabled={isLoading || currentStep === 0}
        >
          Back
        </Button>
        <Button
          className='CompleteProfileForm__next'
          type='submit'
          disabled={isLoading}
          onClick={submitForm}
        >
          {isLoading ? (
            <Loader light fixed={false} showLogo={false} width='32px' />
          ) : currentStep === 0 ? (
            'Next'
          ) : (
            'Upload'
          )}
        </Button>
      </div>
      <div className='CompleteProfileForm__createAccount'>
        Already have an account?{' '}
        <Button variant='text' onClick={handleSwitchSignInForm}>
          Sign in
        </Button>
      </div>
    </div>
  );
};

export default CompleteProfileForm;
