const MINIMAL_PASSWORD_LENGTH = 8;
export const DEFAULT_ERROR_MESSAGE =
  'Something went wrong. Please, try again later!';

export function isLongEnough(v: string, length: number = 8) {
  return v.length >= length;
}

export function containsNonCapital(password: string) {
  return /[a-z]/.test(password);
}

export function containsCapital(password: string) {
  return /[A-Z]/.test(password);
}

export function containsDigit(password: string) {
  return /\d/.test(password);
}

export function isNotSpacesOnSides(str: string | number) {
  return `${str}`.trim() === `${str}`;
}
export function containsSpecialCharacter(password: string) {
  return /[^a-zA-Z\d\s:]/.test(password);
}
export function isPhone(v: string) {
  return /\+?\d+/.test(v);
}

export const validatePassword = (password: string) => {
  if (password === '') {
    return 'Required';
  } else {
    if (!isLongEnough(password, MINIMAL_PASSWORD_LENGTH)) {
      return `should have at least ${MINIMAL_PASSWORD_LENGTH} characters`;
    }
    if (!containsNonCapital(password)) {
      return 'should contain a non-capital character';
    }
    if (!containsCapital(password)) {
      return 'should contain a capital character';
    }
    if (!containsSpecialCharacter(password)) {
      return 'should contain a special character';
    }
    if (!containsDigit(password)) {
      return 'should contain a digit';
    }
    if (!isNotSpacesOnSides(password)) {
      return `Please don't begin or end your password with blank space`;
    }
  }
};
