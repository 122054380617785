import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { CategoryViewModel } from '../../core/backend/models';
import './CategoryBadge.scss';

interface IProps {
  compact?: boolean;
  linkTo?: string;
  image?: string | React.ReactNode;
  title?: string;
  isSelected?: boolean;
  className?: string;
}

const CategoryBadge = ({
  compact,
  linkTo,
  image,
  title,
  isSelected = false,
  className
}: IProps) => {
  return (
    <div
      className={classNames(`CategoryBadge ${className}`, {
        'CategoryBadge--selected': isSelected,
        'CategoryBadge--compact': compact
      })}
    >
      {compact ? (
        <div className='CategoryBadge__compactWrapper'>
          {typeof image === 'string' ? (
            <img className='CategoryBadge__icon' src={image} alt={title} />
          ) : (
            image
          )}
          <div className='CategoryBadge__title'>{title}</div>
        </div>
      ) : (
        <Link className='CategoryBadge__link' to={linkTo}>
          {typeof image === 'string' ? (
            <img className='CategoryBadge__icon' src={image} alt={title} />
          ) : (
            image
          )}
          <div className='CategoryBadge__title'>{title}</div>
        </Link>
      )}
    </div>
  );
};

export default CategoryBadge;
