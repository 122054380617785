import Modal, { IModalProps } from '../Modal';
import { ReactComponent as HooplaIcon } from '../../../images/hooplaicon.svg';
import Button from '../../Button/Button';
import { ReactComponent as CrossIcon } from '../../../icons/cross.svg';
import './UserLocationModal.scss';

interface IProps extends IModalProps {
  onRequestLocationPermissions: () => void;
}

const UserLocationModal = ({
  onRequestLocationPermissions,
  close,
  isOpened
}: IProps) => {
  return (
    <Modal
      isOpened={isOpened}
      close={close}
      showCloseButton={false}
      full={false}
    >
      <div className='UserLocationModal'>
        <div className='UserLocationModal__header'>
          <div className='UserLocationModal__logo'>
            <HooplaIcon />
          </div>
          <div className='UserLocationModal__title'>
            Location Services Off?
            <Button
              className='UserLocationModal__close'
              variant='icon'
              onClick={close}
            >
              <CrossIcon className='UserLocationModal__closeIcon' />
            </Button>
          </div>

          <div className='UserLocationModal__description'>
            Turn on Location Services to find experiences near you
          </div>
        </div>
        <div className='UserLocationModal__actions'>
          <Button
            className='UserLocationModal__closePermissions'
            variant='outline'
            size='auto'
            onClick={close}
          >
            Close
          </Button>
          <Button
            className='UserLocationModal__grantPermissions'
            variant='action'
            onClick={onRequestLocationPermissions}
          >
            Allow hoopla to access my location
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UserLocationModal;
