import { makeAutoObservable } from 'mobx';

class StripeStore {
  publicKey: string;
  clientSecret: string;
  appleClientSecret: string;

  constructor() {
    makeAutoObservable(this);
  }
  setPublicKey = (key: string) => {
    this.publicKey = key;
  };

  setClientSecret = (key: string) => {
    this.clientSecret = key;
  };

  setAppleClientSecret = (key: string) => {
    this.appleClientSecret = key;
  };
}

export default StripeStore;
