import { APPSTORE_URL, PLAYSTORE_URL } from '../../core/consts';

export const platformLinks: { [n: string]: string } = {
  ios: APPSTORE_URL,
  android: PLAYSTORE_URL
};

export const downloadApp = (platform: string) => {
  if (!platform) return;

  window.open(platformLinks[platform], '_blank');
};
