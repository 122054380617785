//import ReactMarkdown, {} from 'react-markdown';
import { memo } from 'react';
import Markdown from 'markdown-to-jsx';
import './RichText.scss';

interface IProps {
  className?: string;
  content: string;
}

const RichText = ({ className = '', content = '' }: IProps) => {
  return (
    <div className={`RichText ${className}`}>
      <Markdown>{content}</Markdown>
    </div>
  );
};

export default memo(RichText) as typeof RichText;
