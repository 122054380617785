import { useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { RecurrenceViewModel } from '../../core/backend/models/recurrence-view-model';
import { ReactComponent as PriceIcon } from '../../icons/price_tag.svg';
import { ReactComponent as NextIcon } from '../../icons/categoryslidernext.svg';
import { ReactComponent as PrevIcon } from '../../icons/categorysliderprev.svg';
import './RecurrentDateSelector.scss';

interface IProps {
  className?: string;
  gapBetweenItems?: number;
  selectedDate: RecurrenceViewModel | undefined;
  dates: RecurrenceViewModel[];
  onPress: (selectedDate: RecurrenceViewModel) => void;
}

const scrollOffset = 145;

const RecurrentDateSelector = ({
  className = '',
  gapBetweenItems = 8,
  dates,
  selectedDate,
  onPress
}: IProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const [prevButtonDisabled, setPrevButtonDisabled] = useState(false);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);

  useEffect(() => {
    if (!listRef.current) return;

    listRef.current?.addEventListener('scroll', handleScroll);
    listRef.current?.addEventListener('wheel', handleWheel);

    //Add style to item container
    listRef.current.style.gap = `${gapBetweenItems}px`;
    containerRef.current?.style.setProperty(
      'height',
      `${getListFirstItemHeight()}px`
    );

    calculateScrollerValues();
    scrollToSelectedDate();

    return () => {
      listRef.current?.removeEventListener('scroll', handleScroll);
      listRef.current?.removeEventListener('wheel', handleWheel);
    };
  }, []);

  const handleScroll = (e: Event) => {
    calculateScrollerValues();
  };

  const handleWheel = (e: WheelEvent) => {
    e.preventDefault();

    if (e.deltaY == 0) return;

    scrollToXPosition(e.deltaY);
  };

  const handleSlideBack = () => {
    scrollToXPosition(-(scrollOffset + gapBetweenItems));
  };

  const handleSlideForward = () => {
    scrollToXPosition(scrollOffset + gapBetweenItems);
  };

  const scrollToXPosition = (increment: number) => {
    if (!listRef.current) return;

    listRef.current?.scrollTo({
      left: listRef.current?.scrollLeft + increment + gapBetweenItems,
      behavior: 'smooth'
    });
  };

  const calculateScrollerValues = () => {
    const scrollLeft = listRef.current?.scrollLeft;
    const scrollWidth = listRef.current?.scrollWidth;
    const containerWidth = listRef.current?.offsetWidth;

    setPrevButtonDisabled(scrollLeft === 0);
    setNextButtonDisabled(scrollLeft + containerWidth === scrollWidth);
  };

  const getListFirstItemHeight = () => {
    if (!listRef.current) return 0;

    const el = listRef.current?.querySelector<HTMLElement>(
      '.RecurrentDateSelector__item'
    );

    return el ? el.offsetHeight : 0;
  };

  const scrollToSelectedDate = () => {
    const selectedDateElement = document.getElementById(
      `recurrent-date-${selectedDate.id}`
    );

    if (selectedDateElement) {
      listRef.current?.scrollTo({
        left: selectedDateElement.offsetLeft,
        behavior: 'auto'
      });
    }
  };

  return (
    <div ref={containerRef} className={`RecurrentDateSelector ${className}`}>
      <button
        onClick={handleSlideBack}
        className={classNames('RecurrentDateSelector__prevButton', {
          'RecurrentDateSelector__prevButton--disable': prevButtonDisabled
        })}
      >
        <PrevIcon />
      </button>

      <button
        className={classNames('RecurrentDateSelector__nextButton', {
          'RecurrentDateSelector__nextButton--disable': nextButtonDisabled
        })}
        onClick={handleSlideForward}
      >
        <NextIcon />
      </button>
      <div ref={listRef} className='RecurrentDateSelector__list'>
        {dates.map((eventDate) => {
          const date = dayjs.utc(
            eventDate.startDateTime * 1000 +
              eventDate.timeZoneOffsetInSeconds * 1000
          );
          const endTime = date.add(eventDate.duration, 'seconds');
          return (
            <div
              key={`recurrent-date-${eventDate.id}`}
              id={`recurrent-date-${eventDate.id}`}
              className={classNames('RecurrentDateSelector__item', {
                'RecurrentDateSelector__item--selected':
                  selectedDate?.id === eventDate.id,
                'RecurrentDateSelector__item--disabled':
                  !eventDate.isActive || eventDate.isClosed,
                'RecurrentDateSelector__item--clickable':
                  eventDate.isActive &&
                  !eventDate.isClosed &&
                  !eventDate.isSoldOut
              })}
              onClick={() => {
                if (
                  selectedDate?.isActive &&
                  !eventDate.isClosed &&
                  !eventDate.isSoldOut &&
                  selectedDate?.id !== eventDate.id
                )
                  onPress(eventDate);
              }}
            >
              {!eventDate.isClosed && eventDate.isSoldOut && (
                <div className='RecurrentDateSelector__badge'>
                  <PriceIcon />
                  <span>Sold Out</span>
                </div>
              )}

              {/* {eventDate.isCancelled && (
                <div className='RecurrentDateSelector__badge'>
                  <span>Cancelled</span>
                </div>
              )} */}

              <div className='RecurrentDateSelector__date'>
                {date.format('D')}
              </div>
              <div className='RecurrentDateSelector__monthYear'>
                {date.format('MMM, YYYY')}
              </div>
              <div className='RecurrentDateSelector__day'>
                {date.format('dddd')}
              </div>
              <div className='RecurrentDateSelector__time'>
                {date.format('h:mm A')} - {endTime.format('h:mm A')}{' '}
                {eventDate.timeZoneAbbreviation}
              </div>
              {eventDate.isActive && eventDate.isClosed && (
                <div className='RecurrentDateSelector__closed'>Closed</div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RecurrentDateSelector;
