import classNames from 'classnames';
import { numFormatter } from '../../core/helpers';
import { ReactComponent as ViewsIcon } from '../../icons/views.svg';
import './ViewsBadge.scss';

interface IProps {
  viewsCount: number;
  large?: boolean;
  className?: string;
  reverse?: boolean;
  darkTheme?: boolean;
}

const ViewsBadge = ({
  viewsCount,
  large = false,
  className = '',
  reverse = false,
  darkTheme = false
}: IProps) => {
  return (
    <div
      className={classNames(`ViewsBadge ${className}`, {
        'ViewsBadge--large': large,
        'ViewsBadge--reverse': reverse,
        'ViewsBadge--dark': !!darkTheme
      })}
    >
      <ViewsIcon />
      <div className='ViewsBadge__count'>{numFormatter(viewsCount)}</div>
    </div>
  );
};

export default ViewsBadge;
