import * as yup from 'yup';

const validationSchema = yup.object().shape({
  userName: yup
    .string()
    .required('Required')
    .min(4, 'Minimum length of Username should be 4 characters')
    .max(18, 'Maximum length of Username should be 18 characters')
    .test(
      'userName',
      'Username cannot contain whitespaces',
      function (value: string) {
        if (!value) return true;

        return !value.includes(' ');
      }
    )
    .test(
      'userName',
      'Username cannot contain “Hoopla” word',
      function (value: string) {
        if (!value) return true;

        return !value.toLowerCase().includes('hoopla');
      }
    )
});

export default validationSchema;
