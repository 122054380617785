import {
  ReactNode,
  useEffect,
  useRef,
  ChangeEventHandler,
  DragEventHandler
} from 'react';
import appToast from '../../core/toast';

const DEFAULT_MIME_TYPES = 'image/jpeg,image/png,video/mp4';

interface Props {
  children: ReactNode;
  className?: string;
  acceptTypes?: string[];
  maxFileSize?: number;
  onChange: (file: File | null) => any;
}

const FileUploader = ({
  className = '',
  children,
  acceptTypes,
  maxFileSize,
  onChange
}: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onFileInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { files } = event.target;

    //Check file size
    if (files[0].size > maxFileSize) {
      return appToast.showError('File size exceeds the maximum limit');
    }

    onChange(files[0]);
    fileInputRef.current.value = '';
  };

  const onTargetClick = () => {
    fileInputRef.current?.click();
  };

  const handleDrop: DragEventHandler<HTMLDivElement> = (event) => {
    const files = event.dataTransfer ? event.dataTransfer.files : null;
    onChange(files[0]);
    fileInputRef.current.value = '';
  };

  const handleWindowDragOverOrDrop = (event: DragEvent) => {
    event.preventDefault();
  };

  useEffect(() => {
    window.addEventListener('dragover', handleWindowDragOverOrDrop);
    window.addEventListener('drop', handleWindowDragOverOrDrop);

    return () => {
      window.removeEventListener('dragover', handleWindowDragOverOrDrop);
      window.removeEventListener('drop', handleWindowDragOverOrDrop);
    };
  }, []);

  return (
    <div className={className} onDrop={handleDrop} role='button'>
      <div onClick={onTargetClick}>{children}</div>
      <input
        ref={fileInputRef}
        onChange={onFileInputChange}
        type='file'
        hidden
        accept={acceptTypes ? acceptTypes.join(',') : DEFAULT_MIME_TYPES}
      />
    </div>
  );
};

export default FileUploader;
