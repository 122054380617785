import { makeAutoObservable } from 'mobx';
import { UserFollowingViewModel } from '../core/backend/models';

class FollowUsersStore {
  following: UserFollowingViewModel[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  saveToStore = (following: UserFollowingViewModel[] | undefined) => {
    this.following = following;
  };

  saveFollowing = (newFollowing: UserFollowingViewModel) => {
    this.following = [...this.following, newFollowing];
  };

  removeFollowing = (userId: string) => {
    this.following = this.following.filter(
      (following) => following.followerUserId !== userId
    );
  };

  isFollowingUser = (follower: string, following: string) => {
    return this.following.find(
      (follow) =>
        follow.followerUserId === following &&
        follow.followingUserId === follower
    )
      ? true
      : false;
  };
}

export default FollowUsersStore;
