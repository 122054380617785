import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { createPortal } from 'react-dom';
import { ChatViewModel, UserViewModel } from '../../core/backend/models';
import appToast from '../../core/toast';
import Chat from '../Chat/Chat';
import './Messenger.scss';

interface MessengerTab {
  user: UserViewModel;
  isExpanded: boolean;
}
interface ContextProps {
  chats?: MessengerTab[];
  createChatWithUser(user: UserViewModel): void;
  expandCollapseTab(userId: string): void;
  closeChat?: (userId: string) => void;
  closeAllAtiveChats?: () => void;
}

const initialContextValues: ContextProps = {
  chats: [],
  createChatWithUser(user: UserViewModel) {},
  expandCollapseTab(userId) {},
  closeChat: (userId: string) => {}
};

const ModalContext = createContext<ContextProps>(initialContextValues);

const Messenger = () => {
  const context = useContext<ContextProps>(ModalContext);

  const handleExpandCollapseTab = (user: UserViewModel) => () => {
    context?.expandCollapseTab(user.id);
  };

  const handleCloseChatTab = (user: UserViewModel) => () => {
    context?.closeChat(user.id);
  };

  return createPortal(
    context?.chats.length > 0 && (
      <div className='Messenger'>
        {context?.chats.map((chat) => (
          <Chat
            key={chat.user.id}
            user={chat.user}
            isExpanded={chat.isExpanded}
            onExpandCollapseTab={handleExpandCollapseTab(chat.user)}
            onClose={handleCloseChatTab(chat.user)}
          />
        ))}
      </div>
    ),
    document.getElementById('messenger')
  );
};

export const MessengerContainer = ({ children }: PropsWithChildren<{}>) => {
  const [chats, setChats] = useState<MessengerTab[]>([]);

  const createMessengerTabObject = (user: UserViewModel): MessengerTab => ({
    user,
    isExpanded: true
  });

  const messengerContext: ContextProps = {
    chats,
    expandCollapseTab(userId) {
      const expandedTabChats = chats.map((chat) =>
        chat.user.id === userId
          ? { ...chat, isExpanded: !chat.isExpanded }
          : chat
      );

      setChats(expandedTabChats);
    },
    createChatWithUser(user) {
      const messengerTab = chats.find((chat) => chat.user.id === user.id);

      if (messengerTab)
        return messengerContext.expandCollapseTab(messengerTab.user.id);

      if (chats.length === 3)
        return appToast.showWarning(
          'You can have a maximun of 3 chat tabs at the same time'
        );

      const newMessengerTab = createMessengerTabObject(user);
      setChats([newMessengerTab, ...chats]);
    },

    closeChat(userId) {
      const _chats = chats.filter((chat) => chat.user.id !== userId);
      setChats(_chats);
    },

    closeAllAtiveChats() {
      setChats([]);
    }
  };

  return (
    <ModalContext.Provider value={messengerContext}>
      <Messenger />
      {children}
    </ModalContext.Provider>
  );
};

export const useMessenger = () => {
  return useContext(ModalContext);
};
