import { Helmet } from 'react-helmet';
import getGlobal from '../../core/globals';

interface IProps {
  title?: string;
  description?: string;
  image?: string;
  video?: string;
  url?: string;
  keywords?: string;
  type?: string;
  robots?: 'Index, Follow' | 'Index, Nofollow' | 'Noindex';
  schemaJLd?: any;
}

const defaultValues = {
  title:
    'Welcome to hoopla.com | Discover, Watch, Book Tickets to Local Events',
  description:
    'Your favorite site to discover, watch, and book tickets to events, concerts, festivals and more.',
  kewords:
    'events, concerts, festivals, events, tickets, book tickets, discover events, watch events, local events, experiences',
  image: getGlobal('baseUrl') + '/hoopla_default_image.jpg',
  url: getGlobal('baseUrl')
};

const SEO = ({
  title,
  description,
  keywords,
  image,
  url,
  video,
  schemaJLd = {},
  robots = 'Index, Follow'
}: IProps) => {
  const appTitle = !!title ? `${title} - hoopla.com` : defaultValues.title;
  const canonical = url ? defaultValues.url + url : `${defaultValues.url}/`;

  return (
    <Helmet script={[schemaJLd]}>
      {/* <!-- Primary Meta Tags --> */}
      <title>{appTitle}</title>
      <meta name='title' content={!!title ? title : defaultValues.title} />
      <meta
        name='description'
        content={!!description ? description : defaultValues.description}
      />
      <meta
        name='keywords'
        content={!!keywords ? keywords : defaultValues.kewords}
      />
      <meta name='robots' content={robots} />

      {/* <!-- Open Graph / Facebook --> */}
      <meta property='og:type' content='website' />
      <meta property='og:url' content={canonical} />
      <meta property='og:site_name' content='hoopla.com' />
      <meta
        property='og:title'
        content={!!title ? title : defaultValues.title}
      />
      <meta
        property='og:description'
        content={!!description ? description : defaultValues.description}
      />
      {video && <meta property='og:video' content={video} />}
      <meta property='og:image' content={image} />
      <meta property='og:image:secure_url' content={image} />

      {/* <!-- Twitter --> */}
      <meta property='twitter:card' content='summary_large_image' />
      <meta property='twitter:url' content={canonical} />
      <meta
        property='twitter:title'
        content={!!title ? title : defaultValues.title}
      />
      <meta
        property='twitter:description'
        content={!!description ? description : defaultValues.description}
      />
      {video && <meta property='twitter:player:stream' content={video} />}
      <meta property='twitter:image' content={image} />
    </Helmet>
  );
};

export default SEO;
