import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import { ReactComponent as FilterIcon } from '../../icons/filter.svg';
import { ReactComponent as HyperIcon } from '../../icons/hyperlocal.svg';
import './SearchInput.scss';
import { ChangeEventHandler, forwardRef } from 'react';
import Button from '../Button/Button';
import classNames from 'classnames';

interface Props {
  value: string;
  className?: string;
  isHyperLocalActive: boolean;
  showFilterAction?: boolean;
  showHyperLocalAction?: boolean;
  isFilterPanelActive?: boolean;
  onChange: (v?: string) => void;
  onClickHyperLocal?: () => void;
  onClickFilters?: () => void;
  placeholderTxt?: string;
}

const SearchInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      className = '',
      value,
      onChange,
      isHyperLocalActive,
      showFilterAction,
      showHyperLocalAction = true,
      isFilterPanelActive,
      onClickHyperLocal,
      onClickFilters,
      placeholderTxt
    },
    ref
  ) => {
    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      onChange(e.target.value);
    };

    return (
      <div className={`SearchInput ${className}`}>
        <div className='SearchInput__inputWrapper'>
          <div className='SearchInput__searchIcon'>
            <SearchIcon />
          </div>
          <input
            ref={ref}
            type='text'
            value={value}
            onChange={handleChange}
            className='SearchInput__input'
            placeholder={placeholderTxt ?? 'Search anything'}
          />
        </div>
        <div className='SearchInput__controls'>
          {showFilterAction && (
            <Button
              className={classNames('SearchInput__controlButton', {
                'SearchInput__hyperLocalButton--active': isFilterPanelActive
              })}
              variant='icon'
              icon={
                <FilterIcon
                  className={classNames('SearchInput__controlIcon', {
                    'SearchInput__controlIcon--active': isFilterPanelActive
                  })}
                />
              }
              onClick={onClickFilters}
            />
          )}
          {showHyperLocalAction && (
            <Button
              className={classNames('SearchInput__controlButton', {
                'SearchInput__controlButton--active': isHyperLocalActive
              })}
              hint={`HyperLocal is ${
                isHyperLocalActive ? 'enabled' : `disabled`
              }`}
              variant='icon'
              icon={
                <HyperIcon
                  className={classNames('SearchInput__controlIcon', {
                    'SearchInput__controlIcon--active': isHyperLocalActive
                  })}
                />
              }
              onClick={onClickHyperLocal}
            />
          )}
        </div>
      </div>
    );
  }
);

export default SearchInput;
