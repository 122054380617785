import { useCallback, useEffect, useRef, useState } from 'react';
import { positionValues, Scrollbars } from 'react-custom-scrollbars';
import {
  commentMediaFile,
  getVideoComments,
  likeComment
} from '../../../core/api';
import { CommentViewModel } from '../../../core/backend/models';
import appToast from '../../../core/toast';
import { DEFAULT_ERROR_MESSAGE } from '../../../core/validators';
import { useStores } from '../../../hooks';
import Button from '../../Button/Button';
import CommentForm, { CommentInput } from '../../CommentForm/CommentForm';
import CommentList from '../../Comments/CommentList/CommentList';
import Loader from '../../Loader/Loader';
import Modal, { IModalProps } from '../Modal';
import { ReactComponent as CrossIcon } from '../../../icons/cross.svg';
import './CommentsModal.scss';
import { observer } from 'mobx-react-lite';
import { CommentMediaFileRequestModel } from '../../../core/backend/interfaces';
import { COMMENTS_PER_PAGE } from '../../../core/consts';

export interface IProps extends IModalProps {
  isProcessing?: boolean;
  mediaFileId: number;
}

const CommentsModal = ({ isOpened, mediaFileId, ...restProps }: IProps) => {
  const { userStore, commentStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [isCommenting, setIsCommenting] = useState(false);
  const scroller = useRef<Scrollbars | null>(null);
  const [skip, setSkip] = useState(0);
  const [comment, setComment] = useState<CommentInput>({
    text: '',
    plainText: '',
    mentions: []
  });

  useEffect(() => {
    if (!isOpened) {
      setIsLoading(false);
      setLoadMore(false);
      setIsCommenting(false);
      setSkip(0);
      return;
    }

    setSkip(commentStore.comments.length);
    setLoadMore(commentStore.comments.length > COMMENTS_PER_PAGE);
    scroller?.current?.scrollToBottom();
  }, [isOpened, mediaFileId]);

  useEffect(() => {
    if (isCommenting) return;

    scroller?.current?.scrollToBottom();
  }, [isCommenting]);

  const commentVideo = async (commentData: CommentMediaFileRequestModel) => {
    try {
      const _commment = await commentMediaFile(commentData);
      return _commment;
    } catch (e: any) {
      const error = e.response?.data?.description || DEFAULT_ERROR_MESSAGE;
      appToast.showError(error);
    }
  };

  const likeUnlikeComment = (comment: CommentViewModel) => async () => {
    if (!userStore.user) return;

    try {
      //commentStore.removeAddLikeToComment(comment);
      await likeComment(comment.id);
      await refreshComments();
    } catch (e: any) {
      const error = e.response?.data?.description || DEFAULT_ERROR_MESSAGE;
      appToast.showError(error);
      //commentStore.removeAddLikeToComment(comment);
    }
  };

  const getComments = useCallback(async () => {
    setIsLoading(true);

    const commentsResponse = await getVideoComments(
      mediaFileId,
      skip,
      COMMENTS_PER_PAGE
    );

    if (commentsResponse.length === 0) {
      setIsLoading(false);
      setLoadMore(false);
      return;
    }

    const sortedComments = commentsResponse.sort(
      (a, b) => a.timeStamp - b.timeStamp
    );

    commentStore.prependComments(sortedComments);

    setSkip((prevState) => prevState + sortedComments.length);
    setLoadMore(true);
    setIsLoading(false);
  }, [skip, mediaFileId]);

  const refreshComments = useCallback(async () => {
    const commentsResponse = await getVideoComments(mediaFileId, 0, skip);
    const sortedComments = commentsResponse.sort(
      (a, b) => a.timeStamp - b.timeStamp
    );

    commentStore.saveToStore(sortedComments);
  }, [mediaFileId, commentStore.comments, skip]);

  const handleSendComment = async () => {
    setIsCommenting(true);
    const commentData: CommentMediaFileRequestModel = {
      mediaFileId,
      comment: comment.plainText,
      taggedUserIds: comment.mentions
    };

    const _comment = await commentVideo(commentData);

    commentStore.addComment({ ..._comment, replyComments: [] });
    setComment({
      text: '',
      plainText: '',
      mentions: []
    });

    scroller?.current?.scrollToBottom();

    setSkip((prevState) => prevState + 1);
    setIsCommenting(false);
  };

  const handleReplyComment = useCallback(
    async (replyToCommentId: number, comment: CommentInput) => {
      const commentData: CommentMediaFileRequestModel = {
        mediaFileId,
        replyToCommentId,
        comment: comment.plainText,
        taggedUserIds: comment.mentions
      };

      await commentVideo(commentData);
      await refreshComments();
    },
    [mediaFileId, refreshComments]
  );

  const handleGetMoreComments = useCallback(
    (scrollValues: positionValues) => {
      if (isLoading || !loadMore) return;

      if (scrollValues.scrollTop < 10) {
        //We'll use this to get current position of comment
        const firstComment = document.getElementsByClassName(
          'CommentsModal__commentList'
        )[0].firstChild as HTMLDivElement;

        const commentId = firstComment?.id ?? '';

        getComments().then(() => {
          //scroll to the current position of the previous first comment
          scroller.current?.scrollTop(
            document.getElementById(commentId).offsetTop
          );
        });
      }
    },
    [isLoading, loadMore, getComments]
  );

  return (
    <Modal
      isOpened={isOpened}
      full={false}
      showCloseButton={false}
      {...restProps}
    >
      <div className='CommentsModal'>
        <div className='CommentsModal__title'>
          Comments
          <Button
            className='CommentsModal__close'
            variant='icon'
            onClick={restProps.close}
          >
            <CrossIcon className='CommentsModal__closeIcon' />
          </Button>
        </div>

        <div className='CommentsModal__content'>
          <Scrollbars
            ref={scroller}
            autoHeight
            autoHeightMin={100}
            autoHeightMax='100%'
            onScrollFrame={handleGetMoreComments}
          >
            {/* <Scrollbar
            noScrollX={true}
            thumbYProps={{ className: 'thumbX' }}
            trackYProps={{ className: 'trackX' }}
            height='100%'
            width='100%'
            createContext={true}
            onScrollStop={handleGetMoreComments}
          > */}
            <>
              {isLoading && (
                <div className='CommentsModal__loading'>
                  <Loader fixed={false} width='32px' />
                </div>
              )}

              <CommentList
                className='CommentsModal__commentList'
                comments={commentStore.comments.slice()}
                onLike={likeUnlikeComment}
                onReply={handleReplyComment}
              />

              {!isLoading && commentStore.comments.length === 0 && (
                <div className='CommentsModal__no-comments'>
                  No comments on this clip.
                </div>
              )}
            </>
            {/* </Scrollbar> */}
          </Scrollbars>
        </div>

        <div className='CommentsModal__actions'>
          {userStore.user && (
            <CommentForm
              className='CommentsModal__commentForm'
              comment={comment}
              onChange={setComment}
              onSend={handleSendComment}
              isProcessing={isCommenting}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default observer(CommentsModal);
