import { GoogleMap } from '@react-google-maps/api';
import { ICoordinates } from '../../../core/types';

interface IProps {
  children?: React.ReactNode;
  center: ICoordinates;
  zoom?: number;
  onLoad?: (map: google.maps.Map) => void;
}

const Map = ({ center, children, onLoad, zoom }: IProps) => {
  return (
    <GoogleMap
      mapContainerStyle={{ height: '100%', width: '100%' }}
      zoom={zoom ?? 15}
      center={center}
      onLoad={onLoad}
      options={{
        fullscreenControl: false,
        zoomControl: false,
        disableDefaultUI: true
      }}
    >
      {children}
    </GoogleMap>
  );
};

export default Map;
