export enum LocationType {
  NUMBER_0 = 0,
  NUMBER_1 = 1,
  NUMBER_2 = 2,
  NUMBER_3 = 3
}

export enum HostingType {
  Virtual,
  InPerson,
  ToBeAnnounced
}

export enum PricingModel {
  Hourly = 0,
  Fixed = 1
}

export enum VideoProcessingStatus {
  Error = 0,
  IsProcessing = 1,
  Success = 2,
  VideoNotLoaded = 3
}

export enum GigVisibility {
  Hidden = 0,
  Draft = 1,
  Public = 2
}

export enum GigPurpose {
  NUMBER_1 = 1,
  NUMBER_2 = 2,
  NUMBER_3 = 3
}

export enum MediaFileType {
  NUMBER_1 = 1,
  NUMBER_2 = 2,
  NUMBER_3 = 3
}

export enum ContentType {
  Default = 0,
  ClipVideo = 1,
  GigVideo = 2,
  BannerMediaFile = 3,
  CategoryImage = 4,
  ReviewVideo = 5,
  GifImage = 6,
  StoryImage = 7,
  ReviewImage = 8
}

export enum VideoStatusType {
  NUMBER_0 = 0,
  NUMBER_1 = 1,
  NUMBER_2 = 2,
  NUMBER_3 = 3
}

export enum VideoRejectReasonType {
  NUMBER_0 = 0,
  NUMBER_1 = 1,
  NUMBER_2 = 2,
  NUMBER_3 = 3
}

export enum Gender {
  NUMBER_0 = 0,
  NUMBER_1 = 1,
  NUMBER_2 = 2
}

export enum UserPolicyClaims {
  NUMBER_0 = 0,
  NUMBER_1 = 1,
  NUMBER_2 = 2,
  NUMBER_3 = 3
}

export enum DayOfWeek {
  NUMBER_0 = 0,
  NUMBER_1 = 1,
  NUMBER_2 = 2,
  NUMBER_3 = 3,
  NUMBER_4 = 4,
  NUMBER_5 = 5,
  NUMBER_6 = 6
}

export enum BookingStatus {
  VendorConfirmationNeeded,
  VendorDeclined,
  VendorConfirmed,
  ClientCanceled,
  ClientConfimationNeeded,
  Finished,
  CustomerLeftFeedback,
  AutoRejected
}

export enum CustomerReportReason {
  NUMBER_0 = 0,
  NUMBER_1 = 1,
  NUMBER_2 = 2,
  NUMBER_3 = 3,
  NUMBER_4 = 4,
  NUMBER_5 = 5,
  NUMBER_6 = 6,
  NUMBER_7 = 7,
  NUMBER_8 = 8,
  NUMBER_9 = 9,
  NUMBER_10 = 10
}

export enum ProviderReportReason {
  NUMBER_0 = 0,
  NUMBER_1 = 1,
  NUMBER_2 = 2,
  NUMBER_3 = 3,
  NUMBER_4 = 4,
  NUMBER_5 = 5,
  NUMBER_6 = 6,
  NUMBER_7 = 7,
  NUMBER_8 = 8,
  NUMBER_9 = 9,
  NUMBER_10 = 10,
  NUMBER_11 = 11,
  NUMBER_12 = 12,
  NUMBER_13 = 13
}

export enum BookingResolution {
  NUMBER_0 = 0,
  NUMBER_1 = 1,
  NUMBER_2 = 2
}

export enum CustomerCancelReason {
  NUMBER_0 = 0,
  NUMBER_1 = 1,
  NUMBER_2 = 2,
  NUMBER_3 = 3,
  NUMBER_4 = 4,
  NUMBER_5 = 5,
  NUMBER_6 = 6
}

export enum ProviderCancelReason {
  NUMBER_0 = 0,
  NUMBER_1 = 1,
  NUMBER_2 = 2,
  NUMBER_3 = 3,
  NUMBER_4 = 4,
  NUMBER_5 = 5,
  NUMBER_6 = 6
}

export enum ChatType {
  NUMBER_1 = 1,
  NUMBER_2 = 2,
  NUMBER_3 = 3
}

export enum MessageStatuses {
  NUMBER_0 = 0,
  NUMBER_1 = 1,
  NUMBER_2 = 2
}

export enum GigsSortOrder {
  NoSort,
  MostPopular,
  MostExpensive,
  Cheapest,
  Newest,
  MostViews
}

export enum DiscoveryFilterSortBy {
  NoSort,
  Newest,
  MostPopular,
  MostViews
}

export enum EventTimeLapse {
  None,
  AnyTime,
  Today,
  Tomorrow,
  ThisWeek,
  Weekend,
  SpecificDate
}

export enum TicketAttendeeStatus {
  Registered,
  CheckedIn,
  Cancelled
}

export enum RegisteredListStatus {
  Accepted = 0,
  Pending = 1,
  Declined = 2
}

export enum AttendeeListStatus {
  Accepted = 0,
  Pending = 1,
  Rejected = 2
}

export enum InviteStatus {
  // Accepted = 1,
  // Pending = 0,
  // Rejected = 2
  Sent, // Default status, when the invite is created.
  Accepted, // Provider accepts the customer invitation by confirming the booking request, created by the customer himself.
  RejectedByProvider, // Provider rejects the invitation, booking will remain unchanged for the purpose of being able to rescue it in the future if required.
  CancelledByCustomer, // If a customer cancels a booking, it cannot be rescued by provider.
  CancelledByProvider // Unused by the moment.
}

export enum TicketTypeOption {
  ticketThroughHoopla,
  externalLink
}

export enum EventRepeatPeriodType {
  Day,
  Week,
  Month,
  Custom
}

export enum EventRecurrenceEndType {
  Never,
  OnDate,
  AfterRecurrences
}

export enum DateIntervalOption {
  First = 1,
  Second = 2,
  Third = 3,
  Fourth = 4,
  Fifth = 5,
  Last = 6
}

export enum WeekDay {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday
}

export enum VirtualMeetingType {
  Zoom,
  GoogleMeet
}

export enum SwitchDayBehavior {
  SwitchToNearestNextDay,
  SwitchToNearestPreviousDay
}
export enum InvitationMethod {
  ShareUrl,
  InvitationCode
}

export enum GigFlowType {
  Create,
  Edit,
  Duplicate
}

export enum GigFlowStep {
  Details,
  Media,
  Location,
  Recurrence,
  Tickets,
  AdditionalInfo
}

export enum ProductAttributeType {
  Unknown,
  TextInput,
  Radio,
  CheckBox,
  DropDown
}
