import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { BrowserRouter } from 'react-router-dom';
import { Loader as GoogleApiLoader } from '@googlemaps/js-api-loader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SEO from './components/SEO/SEO';
import { Suspense, useEffect } from 'react';
import Loader from './components/Loader/Loader';
import GoogleTagManager from './core/services/GoogleTagManager';
import TikTokPixel from './core/services/TikTokPixel';
import MetaPixel from './core/services/MetaPixel';
import AppRouter from './core/AppRouter';

//Ads Services
GoogleTagManager.init(process.env.REACT_APP_GTM_TRACKING_ID);
TikTokPixel.init(process.env.REACT_APP_TIKTOK_PIXEL_ID);
MetaPixel.init(process.env.REACT_APP_META_PIXEL_ID);

dayjs.extend(utc);
dayjs.extend(timezone);

function App() {
  useEffect(() => {
    /**
     * Load Google Map API script to be used on
     * Event Location and Place Suggestions
     */
    const loader = new GoogleApiLoader({
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
      version: 'weekly',
      libraries: ['places']
    });

    loader.load().then((google) => {
      (window as any).google = google;
    });
  }, []);

  return (
    <div id='App'>
      <SEO />
      <Suspense fallback={<Loader />}>
        <ToastContainer />
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
