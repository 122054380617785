import {
  HubConnectionBuilder,
  HubConnectionState,
  HubConnection,
  LogLevel
} from '@microsoft/signalr';
import getGlobal from '../../globals';

class SignalSocket<T> {
  protected _connection: HubConnection;

  constructor() {
    this._connection = new HubConnectionBuilder()
      .withUrl(`${getGlobal('api')}/messenger`, {
        withCredentials: true
      })
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    this._connection.onclose((error) => {
      if (error) console.log(`[SignalR]: Connection Error {${error ?? ''}}`);
      else console.log('[SignalR]: Connection closed.');
    });
  }

  //Getters
  get isConnected() {
    return this._connection.state === HubConnectionState.Connected;
  }

  //Methods
  init() {
    return this._connection.start();
  }

  stop() {
    return this._connection.stop();
  }

  //Handlers
  on(event: string, callback: (data: T) => void) {
    this._connection.on(event, callback);
  }

  async call(method: string, args: any) {
    return await this._connection.invoke(method, args);
  }
}

export default SignalSocket;
