import { getMediaFile } from '../../core/api';
import { AttendeeViewModel } from '../../core/backend/models';
import { SizeType } from '../../core/types';
import Avatar from '../Avatar/Avatar';
import './Attendees.scss';

interface IProps {
  className?: string;
  displayCount?: number;
  size?: SizeType;
  attendees: AttendeeViewModel[];
  onViewAllClick?: () => void;
}

const Attendees = ({
  className = '',
  displayCount = 4,
  attendees = [],
  size = 'medium',
  onViewAllClick
}: IProps) => {
  return (
    <div
      className={`Attendees ${className}`}
      onClick={attendees.length <= displayCount ? onViewAllClick : null}
    >
      {attendees.slice(0, displayCount).map((attendee) => (
        <Avatar
          key={attendee.id}
          className='Attendees__attendee'
          size={size}
          showBorder={false}
          image={
            attendee.image && (
              <img
                key={attendee.id}
                title={attendee.userName}
                alt={attendee.userName}
                src={getMediaFile(attendee.image)}
              />
            )
          }
          symbol={attendee.userName[0]}
        />
      ))}
      {attendees.length > displayCount && (
        <div
          key='attendees-plus'
          className='Attendees__plus'
          onClick={onViewAllClick ?? null}
        >
          +{attendees.length - displayCount}
        </div>
      )}
    </div>
  );
};

export default Attendees;
