import { Placement } from './Tooltip';

interface Rect {
  right: number;
  top: number;
  left: number;
  bottom: number;
}

export const computePlacement = (tooltipRect: Rect): Placement => {
  if (
    tooltipRect.right >
    (window.innerWidth || document.documentElement.clientWidth)
  ) {
    return 'left';
  } else if (tooltipRect.left < 0) {
    return 'right';
  } else if (tooltipRect.top < 0) {
    return 'bottom';
  } else if (
    tooltipRect.bottom >
    (window.innerHeight || document.documentElement.clientHeight)
  ) {
    return 'top';
  }
};

export const isPlacementFits = (tooltipRect: Rect) => {
  return (
    tooltipRect.bottom <
      (window.innerHeight || document.documentElement.clientHeight) &&
    tooltipRect.left > 0 &&
    tooltipRect.right <
      (window.innerWidth || document.documentElement.clientWidth) &&
    tooltipRect.top > 0
  );
};
