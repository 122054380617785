import React from 'react';
import { TrackingEvents } from '../../core/enums';
import MixpanelTracking from '../../core/services/MixpanelTracking';
import { ReactComponent as AppStoreIcon } from '../../icons/appstore_badge.svg';
import './DownloadWidget.scss';
import { downloadApp, platformLinks } from './DownloadWidget.helper';
import GooglePlayIcon from '../GooglePlayIcon/GooglePlayIcon';

interface Props {
  section?: string;
  className?: string;
}

const DownloadWidget = ({ section, className = '' }: Props) => {
  const handleDownloadApp =
    (platform: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();

      MixpanelTracking.instance().track(
        platform === 'ios'
          ? TrackingEvents.DownloadiOSApp
          : TrackingEvents.DownloadAndroidApp,
        {
          'App Type': platform,
          'Web Section': section
        }
      );

      downloadApp(platform);
    };

  return (
    <div className={`d-flex ${className}`}>
      <a
        rel='noopener noreferrer'
        className='me-1'
        onClick={handleDownloadApp('ios')}
        href={platformLinks['ios']}
      >
        <AppStoreIcon className='DownloadWidget__appStoreBadge' />
      </a>

      <a
        rel='noopener noreferrer'
        onClick={handleDownloadApp('android')}
        href={platformLinks['android']}
      >
        <GooglePlayIcon className='DownloadWidget__playStoreBadge' />
      </a>
    </div>
  );
};

export default DownloadWidget;
