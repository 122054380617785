import { observer } from 'mobx-react-lite';
import { GigViewModel } from '../../../core/backend/models';
import './VideoDetails.scss';
import { useEffect, useState } from 'react';
import { getPopularExperiences } from '../../../core/api';
import Loader from '../../Loader/Loader';
import GigCard from '../../Cards/GigCard/GigCard';
import ExperienceModel from '../../../core/models/ExperienceModel';

interface Props {
  onClick?: (index: number, experiences: ExperienceModel[]) => void;
}

const VideoDetails = ({ onClick }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [experiences, setExperiences] = useState<ExperienceModel[]>([]);
  const [skip, setSkip] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    getExpereinces();
  }, []);

  const getExpereinces = async () => {
    try {
      const _experiences = await getPopularExperiences(skip, 12);
      const experiences = _experiences.map(
        (experience) => new ExperienceModel(experience)
      );
      setExperiences(experiences);

      setSkip(skip + experiences.length); //Implement load more later
    } catch (e: any) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleExperienceClick = (index: number) => () => {
    onClick(index, experiences);
  };

  return (
    <div className='VideoDetails'>
      <div className='VideoDetails__header mb-1'>Great Experiences</div>
      <div className='VideoDetails__content'>
        {isLoading ? (
          <Loader fixed={false} showLogo={false} width='42px' />
        ) : experiences.length > 0 ? (
          experiences.map((experience, idx) => (
            <GigCard
              key={experience.id}
              colClass=''
              gig={experience}
              onPress={handleExperienceClick(idx)}
            />
          ))
        ) : (
          <div className='VideoDetails__noContent'>No related content</div>
        )}
      </div>
    </div>
  );
};

export default observer(VideoDetails);
