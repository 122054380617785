import { makeAutoObservable } from 'mobx';
import {
  FileResponseViewModel,
  LikeViewModel,
  UserViewModel
} from '../core/backend/models';

class LikesStore {
  likedMedia: LikeViewModel[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  saveToStore = (likedMedia: LikeViewModel[] | undefined) => {
    this.likedMedia = likedMedia;
  };

  add = (
    mediaFile: FileResponseViewModel,
    contentOwner: UserViewModel,
    user: UserViewModel
  ) => {
    this.saveToStore([
      ...this.likedMedia,
      {
        mediaFile,
        user,
        contentOwner,
        timeStamp: new Date().getTime()
      }
    ]);
  };

  remove = (mediaId: number) => {
    const _likes = this.likedMedia.filter(
      (liked) => liked.mediaFile.id !== mediaId
    );
    this.saveToStore(_likes);
  };

  isMediaLiked = (mediaId: number) => {
    const found = this.likedMedia.find(
      (liked) => liked.mediaFile.id === mediaId
    );

    return !!found;
  };

  getMediaLikesCount = (mediaId: number) => {
    return this.likedMedia.filter((liked) => liked.mediaFile.id === mediaId)
      .length;
  };
}

export default LikesStore;
