import { toast, ToastOptions } from 'react-toastify';
import { DEFAULT_ERROR_MESSAGE } from '../core/validators';

interface PromiseToastMessages {
  pending?: string;
  success?: string;
  error?: string;
}

const appToastSettings: ToastOptions = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored'
};

const appToast = {
  showError(message: string) {
    toast.error(message, appToastSettings);
  },
  showSuccess(message: string) {
    toast.success(message, appToastSettings);
  },
  showWarning(message: string) {
    toast.warning(message, appToastSettings);
  },
  showInfo(message: string) {
    toast.info(message, appToastSettings);
  },
  async showPromise<T>(
    promise: Promise<T>,
    onError: () => void,
    messages?: PromiseToastMessages
  ) {
    return toast.promise(
      promise,
      {
        pending: {
          render() {
            return messages.pending;
          },
          icon: true,
          theme: 'dark'
        },
        success: {
          render({ data }) {
            return messages.success;
          },
          icon: '🟢',
          autoClose: 1
        },
        error: {
          render({ data }) {
            const error = data.response?.data?.description || messages.error;
            onError();
            return error;
          },
          autoClose: 1
        }
      },
      { ...appToastSettings, isLoading: true }
    );
  }
};

export default appToast;
