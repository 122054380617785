import classNames from 'classnames';
import dayjs from 'dayjs';
import { getMediaFile } from '../../core/api';
import { MessageViewModel, UserViewModel } from '../../core/backend/models';
import { useStores } from '../../hooks';
import Avatar from '../Avatar/Avatar';
import './Message.scss';

interface IProps {
  sender: UserViewModel;
  message: MessageViewModel;
  className?: string;
}

const Message = ({ sender, message, className }: IProps) => {
  const { userStore } = useStores();
  const date = new Date(message.sendOn);
  const letter =
    sender.id === userStore.user.id
      ? userStore.user.userName[0]
      : sender.userName[0];

  return (
    <div
      className={classNames(`Message ${className}`, {
        'Message--own': sender.id === userStore.user.id
      })}
    >
      <Avatar
        className='Message__userImage'
        size='small'
        image={
          sender.image && (
            <img src={getMediaFile(sender.image)} alt={sender.fullName} />
          )
        }
        symbol={letter}
      />
      <div className='Message__bubble'>
        <div className='Message__content'>{message.body}</div>
      </div>
      <div className='Message__time'>{dayjs(date).format('h:mma')}</div>
    </div>
  );
};

export default Message;
