import classNames from 'classnames';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { getMediaFile } from '../../../core/api';
import { CommentViewModelV21 } from '../../../core/backend/models';
import { useStores } from '../../../hooks';
import Avatar from '../../Avatar/Avatar';
import Button from '../../Button/Button';
import CommentForm, { CommentInput } from '../../CommentForm/CommentForm';
import LikeButton from '../../LikeButton/LikeButton';
import Mentions from '../../Mentions/Mentions';
import CommentList from '../CommentList/CommentList';
import './Comment.scss';

interface IProps {
  comment: CommentViewModelV21;
  className?: string;
  onLike?: (comment: CommentViewModelV21) => React.MouseEventHandler;
  onReply?: (replyToCommentId: number, comment: CommentInput) => Promise<any>;
}

const Comment = ({ comment, onLike, onReply, className = '' }: IProps) => {
  const [isReplyVisible, setIsReplyVisible] = useState(false);
  const [isCommenting, setIsCommenting] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [commentString, setCommentString] = useState<CommentInput>({
    text: '',
    plainText: '',
    mentions: []
  });
  const { userStore } = useStores();

  const toggleShowReply = () => {
    setCommentString({
      text:
        !isReplyVisible && !comment.user.userDeleted
          ? `@[${comment.user.userName}](${comment.user.id}) `
          : '',
      plainText: '',
      mentions: []
    });

    setIsReplyVisible(!isReplyVisible);
  };

  const handleSendReply = async () => {
    setIsCommenting(true);
    await onReply(comment.id, commentString);
    setCommentString({
      text: '',
      plainText: '',
      mentions: []
    });
    setIsCommenting(false);
  };

  const handleShowAllReplies = () => {
    setShowAll(true);
  };

  const generateUserProfileLink = (
    url: string,
    child: React.ReactNode,
    childClassName: string = ''
  ) => {
    return comment.user.userDeleted ? (
      <div className={childClassName}>{child}</div>
    ) : (
      <Link className={childClassName} to={url}>
        {child}
      </Link>
    );
  };

  const replyComments = comment.replyComments
    ?.slice()
    .sort((a, b) => a.timeStamp - b.timeStamp);

  const userIsDeleted = comment.user?.userDeleted;
  let userName = comment.user.userName;

  if (userIsDeleted && userName.includes('_')) {
    const splittedUserName = userName.split('_');
    splittedUserName.pop();

    if (splittedUserName.length > 0) userName = splittedUserName.join('_');
  }

  return (
    <div
      id={`comment-${comment.id}`}
      className={classNames(`Comment ${className}`, {
        'Comment--withReplies': replyComments?.length > 0
      })}
    >
      {generateUserProfileLink(
        `/u/${comment.user.userName}`,
        <Avatar
          className='Comment__avatar'
          size='small'
          showShadow
          symbol={userName[0]}
          image={
            comment.user.image && (
              <img
                src={getMediaFile(comment.user.image)}
                alt={comment.user.fullName}
              />
            )
          }
          showProBadge={comment.user?.isProviderPro}
        />,
        'Comment__avatarLink'
      )}

      <div className='Comment__body'>
        {generateUserProfileLink(
          `/u/${comment.user.userName}`,
          `@${userName}`,
          'Comment__author'
        )}

        <p className='Comment__text'>
          <Mentions content={comment.text} taggedUsers={comment.taggedUsers} />
        </p>
        <div className='Comment__actions'>
          <LikeButton
            liked={comment.isLikedByMe}
            count={comment.likesCount}
            onClick={onLike(comment)}
          />
          {userStore.user && (
            <>
              <div className='Comment__separator'>·</div>
              <button onClick={toggleShowReply} className='Comment__reply'>
                Reply
              </button>
            </>
          )}
        </div>

        {replyComments?.length > 0 && (
          <>
            {replyComments.length > 1 && !showAll && (
              <Button
                className='Comment__showAllReplies'
                variant='text'
                color='primary'
                onClick={handleShowAllReplies}
              >
                Show all replies ({replyComments.length - 1})
              </Button>
            )}
            <CommentList
              comments={showAll ? replyComments : replyComments.slice(-1)}
              onLike={onLike}
              onReply={onReply}
            />
          </>
        )}

        {isReplyVisible && (
          <CommentForm
            comment={commentString}
            onChange={setCommentString}
            onSend={handleSendReply}
            isProcessing={isCommenting}
          />
        )}
      </div>
    </div>
  );
};

export default Comment;
