import Button from '../../Button/Button';
import { Scrollbars } from 'react-custom-scrollbars';
import Loader from '../../Loader/Loader';
import Modal, { IModalProps } from '../Modal';
import { ReactComponent as CrossIcon } from '../../../icons/cross.svg';
import './ReportMediaModal.scss';
import { useState } from 'react';
import { ReportReasons } from '../../../core/enums';
import { DEFAULT_ERROR_MESSAGE } from '../../../core/validators';
import appToast from '../../../core/toast';
import { reportMediaFile } from '../../../core/api';
import { useStores } from '../../../hooks';
import classNames from 'classnames';

export interface IProps extends IModalProps {
  mediaFileId?: number;
  title?: string;
  afterReporting?: (mediaFileId: number) => void;
}

const ReportMediaModal = ({
  isOpened,
  title,
  mediaFileId,
  close,
  afterReporting,
  ...restProps
}: IProps) => {
  const { userStore } = useStores();
  const [isProcessing, setIsProcessing] = useState(false);
  const [reportReason, setReportReason] = useState<ReportReasons>(null);

  const handleSelectReason = (reason: ReportReasons) => () => {
    setReportReason(reason);
  };

  const handleOnReportContent = async () => {
    try {
      setIsProcessing(true);
      await reportMediaFile(mediaFileId, reportReason, !!userStore.user);
      appToast.showSuccess('This content has been reported.');
      afterReporting && afterReporting(mediaFileId);
      close();
      setReportReason(null);
    } catch (e: any) {
      const error = e.response?.data?.description || DEFAULT_ERROR_MESSAGE;
      appToast.showError(error);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Modal
      isOpened={isOpened}
      full={false}
      showCloseButton={false}
      {...restProps}
    >
      <div className='ReportMediaModal'>
        <div className='ReportMediaModal__title'>
          {title ?? 'Report Content'}
          <Button
            className='ReportMediaModal__close'
            variant='icon'
            onClick={close}
          >
            <CrossIcon className='ReportMediaModal__closeIcon' />
          </Button>
        </div>
        <div className='ReportMediaModal__content'>
          <Scrollbars autoHeight autoHeightMin={100} autoHeightMax='100%'>
            <div className='ReportMediaModal__description'>
              Your report will be filed anonymously, except in the case of
              property infringement. <br /> If someone is in immediate danger,
              please contact local emergency service.
            </div>

            <div className='ReportMediaModal__reasons'>
              <button
                className={classNames('ReportMediaModal__reasonButton', {
                  'ReportMediaModal__reasonButton--selected':
                    reportReason === ReportReasons.HarmfulDesinformation
                })}
                onClick={handleSelectReason(
                  ReportReasons.HarmfulDesinformation
                )}
              >
                Harmful misinformation
              </button>

              <button
                className={classNames('ReportMediaModal__reasonButton', {
                  'ReportMediaModal__reasonButton--selected':
                    reportReason === ReportReasons.HateSpeech
                })}
                onClick={handleSelectReason(ReportReasons.HateSpeech)}
              >
                Hate speech
              </button>

              <button
                className={classNames('ReportMediaModal__reasonButton', {
                  'ReportMediaModal__reasonButton--selected':
                    reportReason === ReportReasons.ImpersonatingMe
                })}
                onClick={handleSelectReason(ReportReasons.ImpersonatingMe)}
              >
                Impersonating me
              </button>

              <button
                className={classNames('ReportMediaModal__reasonButton', {
                  'ReportMediaModal__reasonButton--selected':
                    reportReason === ReportReasons.NudityOrPornography
                })}
                onClick={handleSelectReason(ReportReasons.NudityOrPornography)}
              >
                Nudity or pornography
              </button>
              <button
                className={classNames('ReportMediaModal__reasonButton', {
                  'ReportMediaModal__reasonButton--selected':
                    reportReason === ReportReasons.PrivateInformation
                })}
                onClick={handleSelectReason(ReportReasons.PrivateInformation)}
              >
                Private information
              </button>
              <button
                className={classNames('ReportMediaModal__reasonButton', {
                  'ReportMediaModal__reasonButton--selected':
                    reportReason === ReportReasons.SaleOrPromotionOfDrugs
                })}
                onClick={handleSelectReason(
                  ReportReasons.SaleOrPromotionOfDrugs
                )}
              >
                Sale or promotion of Drugs
              </button>
              <button
                className={classNames('ReportMediaModal__reasonButton', {
                  'ReportMediaModal__reasonButton--selected':
                    reportReason === ReportReasons.SaleOrPromotionOfFirearms
                })}
                onClick={handleSelectReason(
                  ReportReasons.SaleOrPromotionOfFirearms
                )}
              >
                Sale or promotion of Firearms
              </button>
              <button
                className={classNames('ReportMediaModal__reasonButton', {
                  'ReportMediaModal__reasonButton--selected':
                    reportReason === ReportReasons.SelfHarm
                })}
                onClick={handleSelectReason(ReportReasons.SelfHarm)}
              >
                Self harm
              </button>
              <button
                className={classNames('ReportMediaModal__reasonButton', {
                  'ReportMediaModal__reasonButton--selected':
                    reportReason === ReportReasons.ViolenceOrPhysicalAbuseThreat
                })}
                onClick={handleSelectReason(
                  ReportReasons.ViolenceOrPhysicalAbuseThreat
                )}
              >
                Violence or Physical abuse threats
              </button>
              <button
                className={classNames('ReportMediaModal__reasonButton', {
                  'ReportMediaModal__reasonButton--selected':
                    reportReason === ReportReasons.Spam
                })}
                onClick={handleSelectReason(ReportReasons.Spam)}
              >
                Spam
              </button>
              <button
                className={classNames('ReportMediaModal__reasonButton', {
                  'ReportMediaModal__reasonButton--selected':
                    reportReason === ReportReasons.Other
                })}
                onClick={handleSelectReason(ReportReasons.Other)}
              >
                Other
              </button>
            </div>
          </Scrollbars>
        </div>

        <div className='ReportMediaModal__actions'>
          {isProcessing ? (
            <Loader fixed={false} showLogo={false} width='32px' />
          ) : (
            <Button
              color='danger'
              size='full-width'
              disabled={!reportReason}
              onClick={handleOnReportContent}
            >
              Report
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ReportMediaModal;
